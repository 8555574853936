//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import AppConfig from '@/config';
import moment from 'moment';
import ExcelJS from 'exceljs';
import Utils from '@/modules/utils';
import DxSelectCodeList from '@/components/DxSelectCodeList';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';
import saveAs from 'file-saver';
import { DxExport, DxSelection, DxDataGrid, DxColumn, DxEditing, DxScrolling, DxSummary, DxLookup, DxPager, DxPaging, DxTotalItem, DxColumnFixing //컬럼 고정때필요
} from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { formatMessage } from 'devextreme/localization'; // import DxTextBox from 'devextreme-vue/text-box'
// import DxSelectBox from 'devextreme-vue/select-box'

import FileUtils from '@/modules/file'; // import DataSource from 'devextreme/data/data_source'

import { exportDataGrid } from 'devextreme/excel_exporter';
import dayjs from 'dayjs';
export default {
  name: 'feeMainList',
  components: {
    DxSelectBox: DxSelectBox,
    DxExport: DxExport,
    DxSelection: DxSelection,
    CustomStore: CustomStore,
    DxDataGrid: DxDataGrid,
    DxColumn: DxColumn,
    DxEditing: DxEditing,
    DxScrolling: DxScrolling,
    DxSummary: DxSummary,
    DxLookup: DxLookup,
    DxPager: DxPager,
    DxPaging: DxPaging,
    DxTotalItem: DxTotalItem,
    DxColumnFixing: DxColumnFixing,
    DxSelectCodeList: DxSelectCodeList,
    DxTextBox: DxTextBox
  },
  data: function data() {
    return {
      userStatCd: formatMessage('userStatCd'),
      userTypeCd: formatMessage('userTypeCd'),
      startEditAction: 'click',
      startVue: 'userMange',
      targetIdList: [],
      searchKeyWord: [{
        cd: 'memNm',
        nm: '사용자명'
      }, {
        cd: 'memId',
        nm: '아이디'
      }],
      pageSizes: [20, 50, 100],
      displayMode: "full",
      searchData: {
        searchType: "",
        searchWord: "",
        userTypeCd: "",
        userStatCd: "",
        userId: ""
      },
      datasource: new CustomStore({
        key: 'sersNum',
        load: function load(loadOptions) {
          var url;
          loadOptions.searchData = this.searchData;
          url = '/user/getUserManagementList';

          if (loadOptions.isLoadingAll) {
            url = '/user/getUserManagementAllList';
          }

          return Vue.httpClient.post(url, loadOptions).then(function (response) {
            if (response.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
              return {
                data: response.data.result.items,
                totalCount: response.data.result.totalCount
              };
            }
          });
        }
      })
    };
  },
  computed: {
    gridInstance: function gridInstance() {
      return this.$refs.gridContainer['instance'];
    }
  },
  methods: {
    formatMessage: formatMessage,
    search: function search() {
      this.datasource.searchData = this.searchData;
      this.$refs['grid'].instance.refresh();
    },
    onSelectionChanged: function onSelectionChanged(_ref) {
      var selectedRowsData = _ref.selectedRowsData;
      console.log(selectedRowsData);
      this.targetIdList.push(selectedRowsData[selectedRowsData.length - 1].userId);
    },
    //엑셀다운로드
    onDownload: function onDownload() {
      var gridInstance = this.$refs['grid'].instance;
      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet('userManagementList');
      exportDataGrid({
        component: gridInstance,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {
            type: 'application/octet-stream'
          }), "\uC0AC\uC6A9\uC790\uBAA9\uB85D_".concat(moment(new Date()).format('YYYYMMDD'), ".xlsx"));
        });
      });
    },
    handleValueChangedBySearchType: function handleValueChangedBySearchType(e) {
      this.searchData.searchWord = null;
    },
    detail: function detail(e) {
      // 상세 정보 조회 이동
      if (e.rowIndex !== undefined) {
        this.$router.push({
          path: '/vue/delCert/userInfo?userId=' + e.data.userId
        });
      }
    },
    reset: function reset() {
      this.searchData.searchType = "";
      this.searchData.searchWord = "";
      this.searchData.userTypeCd = "";
      this.searchData.userStatCd = ""; //그리드 초기화

      this.datasource.searchData = this.searchData;
      this.$refs['grid'].instance.refresh();
    },
    loginLockReleassClick: function loginLockReleassClick() {
      var _this = this;

      var chkDataList = this.$refs['grid'].instance.getSelectedRowsData();
      var url = '/user/loginLockReleass';

      if (chkDataList.length == 0) {
        window.$Alert.alert(formatMessage('app_cmns_001'));
        return;
      }

      var cnt = 0;

      for (var i in chkDataList) {
        if (chkDataList[i].userStatCd == "007") {
          cnt++;
        }
      }

      if (cnt == 0) {
        window.$Alert.alert(formatMessage('app_cmns_009', "계정잠금인 계정"));
        return;
      }

      window.$Alert.confirm(formatMessage('loginLockReleaseCfm')).then(function (result) {
        if (!result) {
          return;
        }

        Vue.httpClient.post(url, chkDataList).then(function (result) {
          if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
            // this.$emit('popup-complete', {startVue:this.startVue})
            // this.closed()
            // this.$modal.hide('userManagePopup')
            window.$Alert.alert(formatMessage('loginLockReleaseMsg'));
            _this.datasource.searchData = _this.searchData;

            _this.$refs['grid'].instance.refresh();
          }
        });
      });
    },
    regUserConfirmClick: function regUserConfirmClick() {
      var _this2 = this;

      var chkDataList = this.$refs['grid'].instance.getSelectedRowsData();
      var url = '/user/regUserConfirm';

      if (chkDataList.length == 0) {
        window.$Alert.alert(formatMessage('app_cmns_001'));
        return;
      }

      var cnt = 0;

      for (var i in chkDataList) {
        if (chkDataList[i].userStatCd == "001") {
          cnt++;
        }
      }

      if (cnt == 0) {
        window.$Alert.alert(formatMessage('app_cmns_009', "등록요청인 계정"));
        return;
      }

      window.$Alert.confirm(formatMessage('regUserConfirmCfm')).then(function (result) {
        if (!result) {
          return;
        }

        Vue.httpClient.post(url, chkDataList).then(function (result) {
          if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
            // this.$emit('popup-complete', {startVue:this.startVue})
            // this.closed()
            // this.$modal.hide('userManagePopup')
            window.$Alert.alert(formatMessage('regUserConfirmMsg'));
            _this2.datasource.searchData = _this2.searchData;

            _this2.$refs['grid'].instance.refresh();
          }
        });
      });
    },
    stopUserClick: function stopUserClick() {
      var _this3 = this;

      var chkDataList = this.$refs['grid'].instance.getSelectedRowsData();
      var url = '/user/stopUser';

      if (chkDataList.length == 0) {
        window.$Alert.alert(formatMessage('app_cmns_001'));
        return;
      }

      window.$Alert.confirm(formatMessage('stopUserCfm')).then(function (result) {
        if (!result) {
          return;
        }

        Vue.httpClient.post(url, chkDataList).then(function (result) {
          if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
            // this.$emit('popup-complete', {startVue:this.startVue})
            // this.closed()
            // this.$modal.hide('userManagePopup')
            window.$Alert.alert(formatMessage('stopUserMsg'));
            _this3.datasource.searchData = _this3.searchData;

            _this3.$refs['grid'].instance.refresh();
          }
        });
      });
    },
    usableUserClick: function usableUserClick() {
      var _this4 = this;

      var chkDataList = this.$refs['grid'].instance.getSelectedRowsData();
      var url = '/user/usableUser';

      if (chkDataList.length == 0) {
        window.$Alert.alert(formatMessage('app_cmns_001'));
        return;
      }

      var cnt = 0;

      for (var i in chkDataList) {
        if (chkDataList[i].userStatCd == "999") {
          cnt++;
        }
      }

      if (cnt == 0) {
        window.$Alert.alert(formatMessage('app_cmns_009', "사용중지인 계정"));
        return;
      }

      window.$Alert.confirm(formatMessage('usableUserCfm')).then(function (result) {
        if (!result) {
          return;
        }

        Vue.httpClient.post(url, chkDataList).then(function (result) {
          if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
            // this.$emit('popup-complete', {startVue:this.startVue})
            // this.closed()
            // this.$modal.hide('userManagePopup')
            window.$Alert.alert(formatMessage('usableUserMsg'));
            _this4.datasource.searchData = _this4.searchData;

            _this4.$refs['grid'].instance.refresh();
          }
        });
      });
    }
  },
  beforeMount: function beforeMount() {
    var userTypeCd = Vue.$sessionStorage.get('userTypeCd');

    if (userTypeCd == 'userTypeCd') {
      this.adminYn = true;
    } else {
      this.adminYn = false;
    }

    this.searchData.adminYn = this.adminYn;
    this.searchData.userId = window.$store.getters['Login/getUserId'];
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.datasource.searchData = this.searchData;
    });
  }
};