'use strict';

import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
var state = {
  latestMenuList: [],
  cachedComponentList: [],
  current: 0
};
var getters = {
  getLatestMenuList: function getLatestMenuList(state) {
    return state.latestMenuList.slice().reverse();
  },
  getCurrent: function getCurrent(state) {
    //return (state.latestMenuList.length - 1) - state.current
    return state.current;
  },
  getCachedComponentList: function getCachedComponentList(state) {
    return state.cachedComponentList;
  }
};
var mutations = {
  setLatestMenuList: function setLatestMenuList(state, _ref) {
    var latestMenuList = _ref.latestMenuList;
    state.latestMenuList = latestMenuList;
  },
  setCurrent: function setCurrent(state, _ref2) {
    var current = _ref2.current;
    state.current = current;
  },
  setCachedComponentList: function setCachedComponentList(state, _ref3) {
    var cachedComponentList = _ref3.cachedComponentList;
    state.cachedComponentList = cachedComponentList;
  },
  addCachedComponentList: function addCachedComponentList(state, _ref4) {
    var cachedComponent = _ref4.cachedComponent;
    state.cachedComponentList.push(cachedComponent);
  }
};
var actions = {
  insertLatestMenu: function insertLatestMenu(_ref5, params) {
    var commit = _ref5.commit,
        state = _ref5.state;
    return new Promise(function (resolve, reject) {
      var latestMenu = {
        name: params.name,
        desc: params.desc
      };

      var exist = _.findIndex(state.latestMenuList, function (o) {
        return o.name == params.name;
      });

      if (exist > -1) {
        state.current = state.latestMenuList.length - 1 - exist;
      } else {
        if (state.latestMenuList.length >= 5) {
          state.latestMenuList.splice(0, 1);
          state.cachedComponentList.splice(0, 1);
        }

        state.latestMenuList.push(latestMenu);
        state.cachedComponentList.push(latestMenu.name);
        state.current = 0;
      }

      resolve();
    });
  },
  deleteLatestMenu: function deleteLatestMenu(_ref6, params) {
    var commit = _ref6.commit,
        state = _ref6.state;
    return new Promise(function (resolve, reject) {
      var exist = _.findIndex(state.latestMenuList, function (o) {
        return o.name == params.name;
      });

      if (exist > -1) {
        // alert(JSON.stringify(state.latestMenuList));
        for (var i in state.latestMenuList) {
          if (state.latestMenuList[i].name == params.name) {
            state.latestMenuList.splice(i, 1);
            state.cachedComponentList.splice(i, 1);
          }
        }

        if (exist > state.current) {
          state.current = state.current - 1 < 0 ? 0 : state.current - 1;
        }
      }

      resolve();
    });
  },
  moveLatestMenu: function moveLatestMenu(_ref7, params) {
    var commit = _ref7.commit,
        state = _ref7.state;
    return new Promise(function (resolve, reject) {
      var index = state.latestMenuList.length - 1 - state.current;

      if (params.type == 'left') {
        if (state.current > 0) {
          state.current--;
          var latestMenu = state.latestMenuList.splice(index, 1);
          state.latestMenuList.splice(index + 1, 0, latestMenu[0]);
          state.cachedComponentList.splice(index + 1, 0, latestMenu[0].name);
        }
      } else {
        if (state.latestMenuList.length - 1 > state.current) {
          state.current++;
          var latestMenu = state.latestMenuList.splice(index, 1);
          state.latestMenuList.splice(index - 1, 0, latestMenu[0]);
          state.cachedComponentList.splice(index - 1, 0, latestMenu[0].name);
        }
      }

      resolve();
    });
  },
  clearLatestMenu: function clearLatestMenu(_ref8) {
    var commit = _ref8.commit,
        state = _ref8.state;
    return new Promise(function (resolve, reject) {
      state.latestMenuList = [];
      state.cachedComponentList = [];
      state.current = 0;
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};