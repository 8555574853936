var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dx-tree-view", {
    ref: "treeViewRef",
    attrs: {
      id: "sideMenu",
      items: _vm.items,
      "display-expr": "menuNm",
      "key-expr": "path",
      "parent-id-expr": "upMenuId",
      "selection-mode": "single",
      "focus-state-enabled": false,
      "expand-event": "click",
      width: "100%",
      "no-data-text": "",
    },
    on: {
      "item-click": _vm.handleItemClick,
      "item-expanded": _vm.onItemExpanded,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }