//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  methods: {
    confirm: function confirm() {
      this.closed();
      this.isShowModal = false;

      if (typeof this.callback == 'function') {
        this.callback(true);
      }
    },
    cancel: function cancel() {
      this.closed();
      this.isShowModal = false;

      if (typeof this.callback == 'function') {
        this.callback(false);
      }
    },
    selectA: function selectA() {
      this.isShowModal = false;

      if (typeof this.callback == 'function') {
        this.callback('A');
      }
    },
    selectB: function selectB() {
      this.isShowModal = false;

      if (typeof this.callback == 'function') {
        this.callback('B');
      }
    },
    close: function close() {
      this.closed();
      this.isShowModal = false;

      if (typeof this.callback == 'function') {
        this.callback('close');
      }
    },
    open: function open() {
      var popLeft = ($(window).width() - $('#alertPop').outerWidth()) / 2;
      var popTop = ($(window).height() - $('#alertPop').outerHeight()) / 2;
      if (popLeft < 0) popLeft = 0;
      if (popTop < 0) popTop = 0;
      $('#alertMask').fadeIn();
      $('#alertPop').css({
        "left": popLeft,
        "top": popTop
      }).fadeIn().addClass('on');
    },
    closed: function closed() {
      $('#alertPop').fadeOut().removeClass('on');
      $('#alertMask').fadeOut();
    }
  },
  data: function data() {
    return {
      isShowModal: false,
      isConfirm: false,
      isSelect: false,
      title: '알림',
      message: ''
    };
  }
};