//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import AppConfig from '@/config';
import moment from 'moment';
import ExcelJS from 'exceljs';
import Utils from '@/modules/utils';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';
import saveAs from 'file-saver';
import { DxExport, DxSelection, DxDataGrid, DxColumn, DxEditing, DxScrolling, DxSummary, DxLookup, DxPager, DxPaging, DxTotalItem, DxColumnFixing //컬럼 고정때필요
} from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { formatMessage } from 'devextreme/localization'; // import DxTextBox from 'devextreme-vue/text-box'
// import DxSelectBox from 'devextreme-vue/select-box'

import FileUtils from '@/modules/file'; // import DxSelectCodeList from '@/components/DxSelectCodeList'
// import DataSource from 'devextreme/data/data_source'

import { exportDataGrid } from 'devextreme/excel_exporter';
import dayjs from 'dayjs';
export default {
  name: 'feeMainList',
  components: {
    DxSelectBox: DxSelectBox,
    DxExport: DxExport,
    DxSelection: DxSelection,
    CustomStore: CustomStore,
    DxDataGrid: DxDataGrid,
    DxColumn: DxColumn,
    DxEditing: DxEditing,
    DxScrolling: DxScrolling,
    DxSummary: DxSummary,
    DxLookup: DxLookup,
    DxPager: DxPager,
    DxPaging: DxPaging,
    DxTotalItem: DxTotalItem,
    DxColumnFixing: DxColumnFixing,
    DxTextBox: DxTextBox
  },
  data: function data() {
    return {
      searchKeyWord: [{
        cd: 'memNm',
        nm: '사용자명'
      }, {
        cd: 'memId',
        nm: '아이디'
      }],
      pageSizes: [20, 50, 100],
      displayMode: "full",
      searchData: {
        searchType: "",
        searchWord: ""
      },
      datasource: new CustomStore({
        key: 'sersNum',
        load: function load(loadOptions) {
          var url;
          loadOptions.searchData = this.searchData;
          url = '/fee/getFeeStatementMainList';

          if (loadOptions.isLoadingAll) {
            url = '/fee/getFeeStatementAllMainList';
          }

          return Vue.httpClient.post(url, loadOptions).then(function (response) {
            if (response.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
              return {
                data: response.data.result.items,
                totalCount: response.data.result.totalCount
              };
            }
          });
        }
      })
    };
  },
  computed: {
    gridInstance: function gridInstance() {
      return this.$refs.gridContainer['instance'];
    }
  },
  methods: {
    formatMessage: formatMessage,
    search: function search() {
      this.datasource.searchData = this.searchData;
      this.$refs['grid'].instance.refresh();
    },
    //엑셀다운로드
    onDownload: function onDownload() {
      var gridInstance = this.$refs['grid'].instance;
      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet('FeeList');
      exportDataGrid({
        component: gridInstance,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {
            type: 'application/octet-stream'
          }), "\uC694\uAE08\uB0B4\uC5ED\uBAA9\uB85D(\uAD00\uB9AC\uC790)_".concat(moment(new Date()).format('YYYYMMDD'), ".xlsx"));
        });
      });
    },
    handleValueChangedBySearchType: function handleValueChangedBySearchType(e) {
      this.searchData.searchWord = null;
    },
    reset: function reset() {
      this.searchData.searchType = "";
      this.searchData.searchWord = ""; //그리드 초기화

      this.datasource.searchData = this.searchData;
      this.$refs['grid'].instance.refresh();
    },
    detail: function detail(e) {
      // 상세 정보 조회 이동
      console.log("e=={}", e.data.memId);

      if (e.rowIndex !== undefined) {
        this.$router.push({
          path: '/vue/delCert/feeList?memId=' + e.data.memId
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    var userTypeCd = Vue.$sessionStorage.get('userTypeCd');

    if (userTypeCd == '05') {
      this.adminYn = true;
    } else {
      this.adminYn = false;
    }

    this.searchData.adminYn = this.adminYn;
    this.searchData.userId = window.$store.getters['Login/getUserId'];
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.datasource.searchData = this.searchData;
    });
  }
};