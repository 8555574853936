//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import AppConfig from '@/config';
import router from '@/router'; //import Utils from '@/modules/utils'

import DxTextBox from 'devextreme-vue/text-box';
import DxSelectCodeList from '@/components/DxSelectCodeList'; //import {locale, loadMessages, formatMessage } from 'devextreme/localization'

import { formatMessage } from 'devextreme/localization';
import DxTagBox from 'devextreme-vue/tag-box';
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxSelectBox from 'devextreme-vue/select-box';
import DataSource from 'devextreme/data/data_source';
import DxDateBox from "devextreme-vue/date-box";
import { DxNumberBox } from 'devextreme-vue/number-box';
import moment from "moment";
import MemIdPopup from '../../popup/MemIdPopup.vue';
import { DxValidator, DxRequiredRule, DxCompareRule, DxEmailRule, DxPatternRule, DxStringLengthRule, DxRangeRule } from 'devextreme-vue/validator';
export default {
  name: 'feeRegist',
  components: {
    DxSelectBox: DxSelectBox,
    DxValidationGroup: DxValidationGroup,
    DxValidator: DxValidator,
    DxRequiredRule: DxRequiredRule,
    DxCompareRule: DxCompareRule,
    DxEmailRule: DxEmailRule,
    DxPatternRule: DxPatternRule,
    DxStringLengthRule: DxStringLengthRule,
    DxRangeRule: DxRangeRule,
    DxTagBox: DxTagBox,
    DxTextBox: DxTextBox,
    DxDateBox: DxDateBox,
    DxSelectCodeList: DxSelectCodeList,
    DxNumberBox: DxNumberBox,
    MemIdPopup: MemIdPopup
  },
  data: function data() {
    return {
      rules: {
        'X': /0/,
        'Y': /1/,
        'Z': /0/,
        'A': '*'
      },
      idCheck: false,
      adminYn: "",
      nextDay: "",
      save: formatMessage('save'),
      update: formatMessage('update'),
      close: formatMessage('close'),
      memId: formatMessage('memId'),
      os: formatMessage('os'),
      feeAmt: formatMessage('feeAmt'),
      applyStartDt: formatMessage('applyStartDt'),
      applyEndDt: formatMessage('applyEndDt'),
      feeRegist: formatMessage('feeRegist'),
      memSearch: formatMessage('memSearch'),
      startVue: 'info',
      isUpdate: false,
      isSelf: false,
      maskYn: true,
      AppConfig: AppConfig,
      authList: [],
      orgData: [],
      feeInfo: {
        memId: '',
        os: '',
        feeAmt: 0,
        applyStartDt: '',
        applyEndDt: ''
      },
      osList: [{
        cd: 'android',
        nm: 'Android '
      }, {
        cd: 'ios',
        nm: 'iOS'
      }],
      modelObj: {
        memId: ''
      }
    };
  },
  mounted: function mounted() {
    var today = new Date();
    var nextDay = "";
    nextDay = moment(moment(today.setDate(today.getDate() + 1)).format('YYYYMMDD')).format('YYYYMMDD');
    this.nextDay = nextDay;
  },
  methods: {
    formatMessage: formatMessage,
    feeRegistFunc: function feeRegistFunc() {
      var _this = this;

      if (!this.feeInfo.memId) {
        window.$Alert.alert(formatMessage('memIdCheckMsg'));
        return;
      }

      if (!this.feeInfo.os) {
        window.$Alert.alert(formatMessage('osCheckMsg'));
        return;
      }

      if (!this.feeInfo.feeAmt) {
        window.$Alert.alert(formatMessage('feeAmtCheckMsg'));
        return;
      }

      if (!this.feeInfo.applyStartDt) {
        window.$Alert.alert(formatMessage('applyStartDtCheckMsg'));
        return;
      }

      var url = '/fee/insertFee';

      if (this.$refs['validationGroup'].instance.validate().isValid) {
        // 요금 등록
        window.$Alert.confirm(formatMessage('feeRegistMsg')).then(function (result) {
          if (!result) {
            return;
          }

          Vue.httpClient.post(url, _this.feeInfo).then(function (result) {
            if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
              window.$Alert.alert(formatMessage('feeRegistMsg'));
              _this.feeInfo = result.data.result;
            }
          });
        });
      } else {
        var brokenRules = this.$refs['validationGroup'].instance.validate().brokenRules;
        window.$Alert.alert(brokenRules[0].message);
      }
    },
    onOrgValueChanged: function onOrgValueChanged(e) {
      this.userInfo.orgSersNum = e;
    },
    onValueChanged: function onValueChanged(e) {
      this.userInfo.authList = e.value;
    },
    passwordComparison: function passwordComparison() {
      return this.userInfo.pwd;
    },
    handleHistoryBack: function handleHistoryBack(e) {
      this.$router.go(-1);
    },
    chgApplyStartDate: function chgApplyStartDate(e) {
      if (e.value != null) {
        this.feeInfo.applyStartDt = moment(moment(e.value).format('YYYYMMDD')).format('YYYYMMDD');
      } else {
        this.feeInfo.applyStartDt = null;
      }
    },
    memIdPopupOpen: function memIdPopupOpen() {
      this.modelObj.memId = this.feeInfo.memId;
      this.$modal.show('memIdPopup', {});
    },
    onSelectMemIdComplete: function onSelectMemIdComplete(result) {
      if (result) {
        this.feeInfo.memId = result;
      }
    }
  }
};