'use strict';

import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
import AppConfig from '@/config';
import { EventBus } from '@/modules/event-bus';
import { formatMessage } from 'devextreme/localization';
var state = {
  token: null,
  userId: null,
  userNm: null,
  userStatus: null,
  userTypeCd: null,
  btnAuth: null,
  tkchLogiCntrCd: null,
  authList: []
};
var getters = {
  getToken: function getToken(state) {
    return state.token;
  },
  getUserId: function getUserId(state) {
    return state.userId;
  },
  getUserNm: function getUserNm(state) {
    return state.userNm;
  },
  getUserStatus: function getUserStatus(state) {
    return state.userStatus;
  },
  getUserTypeCd: function getUserTypeCd(state) {
    return state.userTypeCd;
  },
  getTkchLogiCntrCd: function getTkchLogiCntrCd(state) {
    return state.tkchLogiCntrCd;
  },
  getBtnAuth: function getBtnAuth(state) {
    return state.btnAuth;
  },
  getAuthList: function getAuthList(state) {
    return state.authList;
  }
};
var mutations = {
  setToken: function setToken(state, _ref) {
    var token = _ref.token;
    state.token = token;
  },
  setUserId: function setUserId(state, _ref2) {
    var userId = _ref2.userId;
    state.userId = userId;
  },
  setUserNm: function setUserNm(state, _ref3) {
    var userNm = _ref3.userNm;
    state.userNm = userNm;
  },
  setUserStatus: function setUserStatus(state, _ref4) {
    var userStatus = _ref4.userStatus;
    state.userStatus = userStatus;
  },
  setUserTypeCd: function setUserTypeCd(state, _ref5) {
    var userTypeCd = _ref5.userTypeCd;
    state.userTypeCd = userTypeCd;
  },
  setTkchLogiCntrCd: function setTkchLogiCntrCd(state, _ref6) {
    var tkchLogiCntrCd = _ref6.tkchLogiCntrCd;
    state.tkchLogiCntrCd = tkchLogiCntrCd;
  },
  setBtnAuth: function setBtnAuth(state, _ref7) {
    var btnAuth = _ref7.btnAuth;
    state.btnAuth = btnAuth;
  },
  setAuthList: function setAuthList(state, _ref8) {
    var authList = _ref8.authList;
    state.authList = authList;
  }
};
var actions = {
  login: function login(_ref9, params) {
    var commit = _ref9.commit,
        state = _ref9.state;
    return new Promise(function (resolve, reject) {
      Vue.httpClient.post('/user/login', params).then(function (response) {
        if (response.data.result.userInfo.resultCd == "noId") {
          window.$Alert.alert(formatMessage('noIdMsg'));
        } else if (response.data.result.userInfo.resultCd == "invaildPw") {
          window.$Alert.alert(formatMessage('loginFailCountMsg', response.data.result.userInfo.lgnFailNmts));
        } else if (response.data.result.userInfo.resultCd == "logFailMax") {
          window.$Alert.alert(formatMessage('loginLockMsg'));
        } else {
          if (response.data.result.userInfo.resultCd == "002") {
            //정상일때
            commit('setUserId', {
              userId: params.userId
            });
            commit('setUserNm', {
              userNm: response.data.result.userInfo.userNm
            }); //Vue.$sessionStorage.set('xAuthToken', response.headers['x-auth-token'])

            Vue.$sessionStorage.set('userId', params.userId);
            Vue.$sessionStorage.set('userPw', params.pwd);
            Vue.$sessionStorage.set('userTypeCd', response.data.result.userInfo.userTypeCd);
            resolve(response.data.result);
          } else {
            window.$Alert.alert(formatMessage('checkIdStatus', response.data.result.userInfo.cmnsCdNm));
          }
        } //resolve(response.data.result)

      }).catch(function (e) {
        var bfTarget,
            target,
            msg = '';
        console.log(e);
        target = e;

        while (msg == '') {
          if ('result' in target) target = target.result;else if ('data' in target) target = target.data;else if ('msg' in target) msg = target.msg;else if ('MSG' in target) msg = target.MSG;else {
            window.$Alert.alert("오류가 발생했습니다.<br/>관리자에게 문의해주세요.");
            return;
          }
        }

        if (msg) window.$Alert.alert(msg);
      });
    });
  },
  logout: function logout(_ref10) {
    var commit = _ref10.commit,
        state = _ref10.state;
    return new Promise(function (resolve, reject) {
      Vue.httpClient.post('/user/logout', {}).then(function (response) {
        if (response.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
          commit('setToken', {
            token: null
          });
          commit('setUserId', {
            userId: null
          });
          commit('setUserNm', {
            userNm: null
          });
          commit('setUserStatus', {
            userStatus: null
          });
          commit('setUserTypeCd', {
            userTypeCd: null
          });
          commit('setTkchLogiCntrCd', {
            tkchLogiCntrCd: null
          });
          commit('setAuthList', {
            authList: []
          });
          Vue.httpClient.defaults.headers.common['x-auth-token'] = null;
          Vue.$sessionStorage.clear();
          window.$store.dispatch('LatestMenu/clearLatestMenu');
          EventBus.$emit('event-logout');
        }

        resolve();
      }).catch(function (e) {
        return reject(e);
      });
    });
  },
  isLogin: function isLogin(_ref11) {
    var commit = _ref11.commit,
        state = _ref11.state;
    return new Promise(function (resolve, reject) {
      if (state.userId) {
        resolve();
      } else {
        Vue.httpClient.post('/user/login/check', {}).then(function (response) {
          if (response.data.result && response.data.result.isLogin) {
            commit('setUserId', {
              userId: response.data.result.userId
            });
            resolve();
          } else {
            reject();
          }
        }).catch(function (e) {
          return reject(e);
        });
      }
    }).then(function () {
      return true;
    }).catch(function () {
      return false;
    });
  },
  checkAuth: function checkAuth(_ref12, params) {
    var commit = _ref12.commit,
        state = _ref12.state;
    return new Promise(function (resolve, reject) {
      /*Vue.httpClient
        .post('/user/checkAuth', params)
        .then(response => {
          if ( parseInt(response.data.status) === 200 ) {
            resolve()
          } else {
            reject()
          }
        })
        .catch(e => {
          reject(e)
        })*/
      resolve();
    }).then(function () {
      return true;
    }).catch(function () {
      return false;
    });
  },
  btnAuth: function btnAuth(_ref13, params) {
    var commit = _ref13.commit,
        state = _ref13.state;
    return new Promise(function (resolve, reject) {
      Vue.httpClient.get('/app/login/getBtnAuth', {
        params: {
          urlPath: params.path
        }
      }).then(function (response) {
        if (response.data.result) {
          commit('setBtnAuth', {
            btnAuth: response.data.result
          });
        }

        resolve();
      }).catch(function (e) {
        return reject(e);
      });
    });
  },
  refreshLogin: function refreshLogin(_ref14) {
    var commit = _ref14.commit,
        state = _ref14.state;
    return new Promise(function (resolve, reject) {
      Vue.httpClient.post('/user/login/check', {}).then(function (response) {
        if (response.data.result && response.data.result.isLogin) {
          commit('setUserId', {
            userId: response.data.result.userId
          });
          resolve();
        } else {
          reject();
        }
      }).catch(function (e) {
        return reject(e);
      });
    }).then(function () {
      return true;
    }).catch(function () {
      return false;
    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};