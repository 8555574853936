// 모듈약어_패키지_화면약어_코드3자리
var dictionary = {
  en: {
    //  거래처 관리 시작
    testNm: '거래처구분코드',
    //  거래처 관리 끝

    /* 모델 관리 시작 */
    modl_modlNum: 'e모델일련번호',
    modl_modlNm: 'e모델명',
    modl_ptnmNm: 'e펫네임',
    modl_pgrpCd: 'e제품군',
    modl_brndNm: 'e브랜드',
    modl_stkQty: 'e재고수량',
    modl_gnrtInfo: 'e세대',
    modl_tlcmCd: 'e통신사',
    modl_modlCpct: 'e용량',
    modl_osPrts: 'OS',
    modl_sreSize: 'e화면사이즈',
    modl_rsnlVal: 'e해상도',
    modl_pcCaseNm: 'e케이스',
    modl_strgRackNum: 'e보관랙번호',
    modl_skuCdNm: 'SKU CODE',
    modl_lnchPrc: 'e출시가격',
    modl_lnchYymm: 'e출시년월',
    modl_modlSpnt: 'e특이사항',
    modl_mnfcModlNm: 'e제조사모델명',
    modl_sortSqnc: 'sort',
    modl_rgsrNm: 'e등록자',
    modl_itemNum: 'ITEM',
    modl_modlProdNum: 'PRO_NUMBER',
    modl_prchTrgtYn: 'e매입대상',
    modl_useYn: 'e사용여부',
    modl_flphDvsnCd: 'e폴더블폰구분',
    modl_cablTypeCd: 'e케이블타입',
    modl_modlHrwrSize: 'e모델사이즈',
    modl_devcSize: 'e디바이스사이즈',
    modl_lcdSize: 'LCD SIZE',
    modl_volmUpButnPstnVal: 'e볼륨업버튼위치값',
    modl_volmDownButnPstnVal: 'e볼륨다운버튼위치값',
    modl_homeButnPstnVal: 'e홈버튼위치값',
    modl_gbckButnPstnVal: 'e뒤로가기버튼위치값',
    modl_menuButnPstnVal: 'e메뉴버튼위치값',
    modl_powrButnPstnVal: 'e전원버튼위치값',
    modl_frftCmraPstnVal: 'e전면카메라위치값',
    modl_bksdCmraPstnVal: 'e후면카메라위치값',
    modl_clupSnsrPstnVal: 'e근접센서위치값',
    modl_vbrtButnPstnVal: 'e진동버튼위치값',
    modl_search_useYn: 'e사용',
    excel_modlManage: 'e모델관리',
    modl_btn_savePrchTrgt: 'e매입대상등록',
    modl_btn_selectPrchTrgt: 'e매입대상조회',
    modl_btn_prchCategory: 'e매입카테고리관리',
    modl_btn_modlManage: 'e모델관리',
    modl_savePrchTrgt_msg: '(EN))1개 이상의 매입대상을<br>선택 해 주세요!<br>(기 매입대상건 제외)',

    /* 모델 관리 끝 */
    invaildPwMsg: '로그인에 실패하였습니다.<br/>아이디,비밀번호를 확인하여 주십시오.',

    /* 모델 카테고리 관리 시작 */
    modl_cate_category: 'e카테고리',
    modl_cate_cateNum: 'e카테고리번호',
    modl_cate_cate01: 'e제품군',
    modl_cate_cate02: 'e중분류',
    modl_cate_cate03: 'e소분류',
    modl_cate_cate04: 'e세분류',
    modl_cate_select: 'e선택',
    modl_cate_cateKey: 'Code',
    modl_cate_cateImage: 'e대표이미지',
    modl_cate_pgrpCd: 'e상품군코드',
    modl_cate_sortSqnc: 'e정렬',
    modl_cate_useYn: 'e사용여부',

    /* 모델 카테고리 관리 끝 */

    /* 공통 메세지 시작 */
    app_cmns_001: '(EN)1건 이상 선택해주세요.',
    app_cmns_002: '(EN){0}건을 저장하시겠습니까?',
    app_cmns_003: '(EN){0}건이 저장되었습니다.',
    app_cmns_004: '(EN)저장에 실패하였습니다. 잠시후 다시 시도해주세요.',
    app_cmns_005: '(EN)지정 된 룰에 의해 패스워드가 초기화 됩니다. 진행 하시겠습니까?',
    app_cmns_006: '(EN)패스워드 초기화 완료 되었습니다.',
    app_cmns_007: '(EN)패스워드 초기화 실패하였습니다. 잠시후 다시 시도해주세요.',
    app_cmns_008: '(EN)1개 이상의 거래처 유형을 선택 해 주세요!',
    app_cmns_009: '(EN)1개 이상의 {0}을(를) 선택 해 주세요!',
    app_cmns_010: '(EN){0} {1}건을 저장하시겠습니까?',
    app_cmns_011: '(EN){0} {1}건이 저장되었습니다.',
    app_cmns_012: '(EN){0}을(를) 저장하시겠습니까?',
    app_cmns_013: '(EN){0}이(가) 저장되었습니다.',
    app_cmns_014: 'e엑셀 업로드 완료',
    app_cmns_015: '(EN){0}건을 삭제하시겠습니까?',
    app_cmns_016: '(EN){0}건이 삭제되었습니다.',
    app_cmns_017: '(EN)삭제에 실패하였습니다. 잠시후 다시 시도해주세요.',

    /* 공통 메세지 끝 */
    exportExcel: 'e다운로드',
    /// Validation Message
    numReqMsg: 'e숫자만 입력 가능 합니다!',
    bsncCdReqMsg: 'e거래처코드 를 입력 해 주세요!',
    bsncNmReqMsg: 'e거래처명 를 입력 해 주세요!',
    bsncDvsnCdReqMsg: 'e거래처구분코드 를 입력 해 주세요!',
    useYnReqMsg: 'e사용여부 를 입력 해 주세요!',
    prchChnlUserIdReqMsg: 'e제휴사ID 를 입력 해 주세요!',
    chnlTypeCdReqMsg: 'e채널구분 를 입력 해 주세요!',
    bizDvsnCdReqMsg: 'e사업구분 를 입력 해 주세요!',
    psicEmlAddrReqMsg: 'e이메일이 유효하지 않습니다!',
    bsncStringLen: 'e{0}자 이하 입력 가능합니다!',
    bsnpRegNumPattern: 'e사업자등록번호가 유효하지 않습니다!',
    corpRegNumPattern: 'e법인등록번호가 유효하지 않습니다!',
    psicTelNumPattern: 'e휴대폰번호가 유효하지 않습니다!',
    addrPlaceholder: 'e주소검색 팝업창으로 입력 가능합니다!',
    hrnkAfliReqMsg: 'e상위제휴사를 입력 해 주세요!',
    afliNmReqMsg: 'e상위제휴사를 입력 해 주세요!',
    reqMsg: 'e{0} 을(를) 입력 하세요!',
    bsnpRegNum: '사업자등록번호',
    bsnpRegCertificate: '사업자등록증사본',
    required_check: 'e{0} 은(는) 필수 입력 항목입니다.',
    duplicate_check: 'e중복된 {0}({1}) 입니다.',
    passwordInit: '비밀번호 초기화',

    /* AlimTemplate - TODO Start*/
    sersNum: 'Serieal Num',
    tmptId: 'Template ID',
    tmptNm: 'Template Name',
    ntftChnlId: 'Plus Friend Id',
    tmptCtts: 'Template Content',
    alimtalkCtts: 'AlimTalk Content',
    button: 'Button',
    buttonType: 'Button Type',
    buttonNm: 'Button Name',
    buttonUrlAddr: 'Button Link',
    ntftTmptId: 'Template ID',
    ntftMesgTypeDvsn: 'Message Type Division',
    failChovYn: 'FailOver Check',
    sndrId: 'Sender Number',
    rcvrClphNum: 'Recipient Number',
    fwdiMesgCtts: 'Sender Message',
    mesgFwdiDt: 'Message Send Time',
    rqstSrvcNm: 'Request Service Name',
    rpnsMesgId: 'Response Message Id',
    rpnsStatVal: 'Response Status Code',
    rpnsCtts: 'Responese Status Contents',
    rsltTypeDvsn: 'Report Type Division',
    erorCtts: 'Report Error Contents',
    rsltFwdiDt: 'Report Forward Time',
    newTemplate: 'New Template',
    tempExcelUpload: 'Template Total Upload',
    alimTalkTempDetail: 'AlimTalk Template Details',
    alimTalkTempRegist: 'AlimTalk Template Regist',
    alimTalkDetailInput: 'Input Detail',
    alimTalkButtonList: 'Button Content',
    tmptRegButton: 'Register Template',
    inputTemplateId: 'Please enter the Template ID',
    inputTemplateName: 'Please enter the Template Name',
    inputTemplateCtts: 'Please enter the Template.',
    selectTemplateUse: 'Please choose whether to use it or not.',
    messageSuccessTemplate: 'The Template Registration is complete.',
    messageUpdateTemplae: 'The Template Update is complete.',

    /* AlimTemplate - TODO End*/

    /* ApiAuthManage*/
    apiUrlAddr: 'URL',
    apiId: 'API ID',
    apiNm: 'API Name',
    apiList: 'API LIST',
    personalInfoYn: 'Personal Information Included',

    /* ApiAuthManage*/

    /* Batch */
    PrgmId: 'Batch Job Name',
    wrkwDvsn: 'Work Division',
    srvcDvsnCd: 'Service Division Code',
    PrgmNm: 'Batch Job Description',
    exeCyclDvsn: 'Execution Cycle Description',
    batExeCycleCd: 'Execution Division',
    strtTime: 'Start Date',
    jobName: 'Batch Job Name',
    createTime: 'Batch Job Create Time',
    startTime: 'Batch Job Start Time',
    endTime: 'Batch Job End Time',
    status: 'Batch Job Status',
    batchPeriod: 'Batch Job Execute Period',
    batchStatus: 'Batch Job Execute Status',
    exitCode: 'Exit Code',
    exitMessage: 'Exit Message',
    commitCount: 'Commit Count',
    readCount: 'Read Count',
    filterCount: 'Filter Count',
    writeCount: 'Write Count',
    readSkipCount: 'Read Skip Count',
    writeSkipCount: 'Write Skip Count',
    processSkipCount: 'Process Skip Count',
    rollbackCount: 'Rollback Count',
    noSelectedBatchJob: 'JOB to execute is not selected.',
    notUseBatchJob: 'The JOB cannot be used.',
    launchManualBatchJob: 'Do you want to run it manually?',
    lastBatchJob: 'Last Time',
    resultBatchJob: 'Result',
    manualLaunch: 'manualLaunch',

    /* Batch */

    /* 알림 */
    notcWrkwCd: 'NotcWrkwCd',
    notcWrkwNm: 'notcWrkwNm',
    wrkwId: 'WrkwId',
    wrkwUrlAddr: 'WrkwUrlAddr',
    rqstPrmrVal: 'RqstPrmrVal',
    notcTrgtId: 'NotcTrgtId',
    notcStatCd: 'NotcStatCd',

    /* 알림 */

    /* 파일관리 */
    flGrpId: 'File Group ID',
    flNm: 'File Name',
    svFlNm: 'Server File Name',
    svFlPath: 'Server File Path',
    flTypeDvs: 'File Type',
    flSize: 'File Size',

    /* 파일관리 */

    /* 상품관리(자동수집) 시작 */
    cltn_pgrpCd: 'Product Group',
    cltn_searchNm: 'Brand/Pet Name/Model Name',
    cltn_insertGnrtInfo: 'Insert Gnrt Info',
    cltn_saveModl: 'Save Model',
    cltn_ntbkCltnInfoNum: 'Ntbk Cltn Info Num',
    cltn_tbltCltnInfoNum: 'Tblt Cltn Info Num',
    cltn_useYn: 'Use',
    cltn_brndNm: 'Brand',
    cltn_ptnmNm: 'Pet Name',
    cltn_modlNm: 'Model Name',
    cltn_grpNm: 'Group Name',
    cltn_gnrtInfo: 'Gnrt Info',
    cltn_cpuMnfcNm: 'CPU Manufacturer Name',
    cltn_cpuClckVal: 'CPU Clock',
    cltn_cpuPtnmNm: 'CPU Pet Name',
    cltn_cpuCdNm: 'CPU Code Name',
    cltn_ramModlNm: 'RAM',
    cltn_ssdModlNm: 'SSD',
    cltn_hddModlNm: 'HDD',
    cltn_grphCardNm: 'Graphic Card Name',
    cltn_cablTypeCd: 'Cable Type',
    cltn_osPrts: 'OS',
    cltn_wbcmNm: 'WebCam',
    cltn_modlWght: 'Model Weight',
    cltn_sreSize: 'Screen Size',
    cltn_rslnVal: 'Resolution',
    cltn_modlSpnt: 'Special ',
    cltn_clrInfo: 'Color',
    cltn_lnchPrc: 'Lnch Price',
    cltn_regYymm: 'Lnch Year Month',
    cltn_regDt: 'Reg Date',
    cltn_updtDt: 'Updt Date',
    cltn_itemNum: 'ITEM',
    cltn_cltnManage: 'e상품관리(자동수집)',
    cltn_save_model: 'e총 {0}건 등록 완료 되었습니다.<br/>(중복 {1}건 제외)<br/>상세 내용은 `모델관리` 화면에서 수정해 주세요.',
    activationMsg: 'eng-{0}님</br>장기 미접속으로 인해 계정이 비활성화 되었습니다.</br>관리자에게 계정 활성화를 요청하시겠습니까?',
    authMsg: 'e인증번호를 전송하였습니다.<br/>인증번호 입력 후 로그인을 시도해 주십시오.',
    notExistPhoneMsg: 'e등록된 휴대전화번호가 없습니다.<br/>휴대전화번호를 입력하여 주십시오.',
    loginFailMsg: 'e인증번호 전송에 실패하였습니다.<br/>아이디,비밀번호를 확인하여 주십시오.',
    loginFailCountMsg: 'e로그인에 실패하였습니다.({0}회)<br/><span style="color:#FF2424;font-weight:bold;">5회</span> 실패시 계정이 잠김니다.<br/>아이디,비밀번호를 확인하여 주십시오.',
    noIdMsg: 'e등록되지 않은 로그인ID입니다.',
    loginLockMsg: 'e5회 이상 로그인에 실패하여<br/><span style="color:#FF2424;font-weight:bold;">계정상태</span>가 <span style="color:#FF2424;font-weight:bold;">잠금</span> 상태로 변경되었습니다.<br/>하단 비밀번호 초기화 기능을 이용하시거나<br/>관리자에게 문의 하십시오.',
    authNumberFail: 'e인증번호 인증에 실패하였습니다.',
    pwdOverChngMsg: 'e비밀번호 변경일이 지났습니다. </br>하단에 초기화 기능을 이용해주세요.',
    pwdChangCountMsg: 'e비밀번호 변경일이 <span style="color:#FF2424;font-weight:bold;">{0}</span>일 남았습니다. </br>비밀번호를 변경하시겠습니까?',
    pwdSameMsg: 'e현재 비밀번호와 변경 비빌번호가 같습니다.',
    pwdChangMsg: 'e비밀번호 변경에 성공하였습니다.',
    inputIdCheck: 'e먼저 아이디를 입력하십시오.',

    /* 유저관리 */
    idDupCheckMsg: 'eID중복여부를 확인해주세요.',
    orgDupCheckMsg: 'e조직을 선택해주세요.',
    userInsertMsg: 'e사용자 정보를 등록하시겠습니까?',
    idDupMsg: 'eLogin ID가 중복됩니다.',
    userUpdateMsg: 'e사용자 정보를 수정하시겠습니까?',
    idCheckMsg: 'e사용가능한 ID 입니다.',
    idCheckDupMsg: 'e이미 사용중인 ID 입니다.',
    loginLockReleaseMsg: '잠금 해제 되었습니다.',
    regUserConfirmMsg: '사용자 등록 승인되었습니다.',
    stopUserMsg: '사용중지 되었습니다.',
    usableUserMsg: '사용중지 해제 되었습니다.',
    loginLockRelease: '잠금해제',
    regUserConfirm: '등록승인',
    stopUser: '사용중지',
    usableUser: '사용중지해제',
    loginLockReleaseCfm: '로그인 잠금해제 하시곘습니까?',
    regUserConfirmCfm: '등록 승인하시겠습니까?',
    stopUserCfm: '사용중지 하시겠습니까?',
    usableUserCfm: '사용중지 해제하시겠습니까?'
    /* 상품관리(자동수집) 끝 */

  }
};
export default {
  getDictionary: function getDictionary() {
    return dictionary;
  }
};