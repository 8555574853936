import DelHistoryList from '@/views/vue/delCert/delHistoryList';
import FeeList from '@/views/vue/delCert/feeList';
import FeeMainList from '@/views/vue/delCert/feeMainList';
import UserInfo from '@/views/vue/delCert/userInfo';
import UserManagePopup from '@/views/vue/delCert/userManagePopup';
import FeeStatement from '@/views/vue/delCert/feeStatement';
import UserManagementList from '@/views/vue/delCert/userManagementList';
import FeeManagementList from '@/views/vue/delCert/feeManagementList';
import ImgInfoPopup from '@/views/vue/delCert/imgInfoPopup';
import QRcodePopup from '@/views/vue/delCert/QRcodePopup';
import FeeRegistPopup from '@/views/vue/delCert/feeRegistPopup';
import FeeRegist from '@/views/vue/delCert/feeRegist';
import FeeDetail from '@/views/vue/delCert/feeDetail';
export default [{
  path: '/vue/delCert/delHistoryList',
  name: 'DelHistoryList',
  component: DelHistoryList,
  meta: {
    authRequired: true,
    desc: '삭제이력'
  }
}, {
  path: '/vue/delCert/feeMainList',
  name: 'FeeMainList',
  component: FeeMainList,
  meta: {
    authRequired: true,
    desc: '요금내역(관리자)'
  }
}, {
  path: '/vue/delCert/feeList',
  name: 'FeeList',
  component: FeeList,
  meta: {
    authRequired: true,
    desc: '요금내역'
  }
}, {
  path: '/vue/delCert/userInfo',
  name: 'UserInfo',
  component: UserInfo,
  meta: {
    authRequired: true,
    desc: '내정보'
  }
}, {
  path: '/vue/delCert/userManagePopup',
  name: 'UserManagePopup',
  component: UserManagePopup,
  meta: {
    authRequired: true,
    desc: '회원가입'
  }
}, {
  path: '/vue/delCert/feeStatement',
  name: 'FeeStatement',
  component: FeeStatement,
  meta: {
    authRequired: false,
    desc: '삭제 APP 다운로드'
  }
}, {
  path: '/vue/delCert/userManagementList',
  name: 'UserManagementList',
  component: UserManagementList,
  meta: {
    authRequired: true,
    desc: '회원관리'
  }
}, {
  path: '/vue/delCert/feeManagementList',
  name: 'FeeManagementList',
  component: FeeManagementList,
  meta: {
    authRequired: true,
    desc: '요금관리'
  }
}, {
  path: '/vue/delCert/imgInfoPopup',
  name: 'ImgInfoPopup',
  component: ImgInfoPopup,
  meta: {
    authRequired: true,
    desc: '이미지조회'
  }
}, {
  path: '/vue/delCert/QRcodePopup',
  name: 'QRcodePopup',
  component: QRcodePopup,
  meta: {
    authRequired: true,
    desc: 'QR조회'
  }
}, {
  path: '/vue/delCert/feeRegist',
  name: 'FeeRegist',
  component: FeeRegist,
  meta: {
    authRequired: true,
    desc: '요금등록'
  }
}, {
  path: '/vue/delCert/feeDetail',
  name: 'FeeDetail',
  component: FeeDetail,
  meta: {
    authRequired: true,
    desc: '요금상세'
  }
}];