import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import AppConfig from '@/config';
import router from '@/router'; //import Utils from '@/modules/utils'

import DxTextBox from 'devextreme-vue/text-box';
import DxSelectCodeList from '@/components/DxSelectCodeList'; //import {locale, loadMessages, formatMessage } from 'devextreme/localization'

import { formatMessage } from 'devextreme/localization';
import DxTagBox from 'devextreme-vue/tag-box';
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxSelectBox from 'devextreme-vue/select-box';
import DataSource from 'devextreme/data/data_source';
import imgInfoPopup from './imgInfoPopup.vue';
import { DxValidator, DxRequiredRule, DxCompareRule, DxEmailRule, DxPatternRule, DxStringLengthRule, DxRangeRule } from 'devextreme-vue/validator';
export default {
  name: 'userInfo',
  components: {
    DxSelectBox: DxSelectBox,
    DxValidationGroup: DxValidationGroup,
    DxValidator: DxValidator,
    DxRequiredRule: DxRequiredRule,
    DxCompareRule: DxCompareRule,
    DxEmailRule: DxEmailRule,
    DxPatternRule: DxPatternRule,
    DxStringLengthRule: DxStringLengthRule,
    DxRangeRule: DxRangeRule,
    DxTagBox: DxTagBox,
    DxTextBox: DxTextBox,
    DxSelectCodeList: DxSelectCodeList,
    imgInfoPopup: imgInfoPopup
  },
  props: {
    value: ''
  },
  data: function data() {
    return {
      rules: {
        'X': /0/,
        'Y': /1/,
        'Z': /0/,
        'A': '*'
      },
      idCheck: false,
      adminYn: "",
      save: formatMessage('save'),
      update: formatMessage('update'),
      close: formatMessage('close'),
      userId: formatMessage('userId'),
      userNm: formatMessage('userNm'),
      userStatCd: formatMessage('userStatCd'),
      userTypeCd: formatMessage('userTypeCd'),
      password: formatMessage('password'),
      passwordInit: formatMessage('passwordInit'),
      pwdMsg: formatMessage('pwdMsg'),
      confirmationPassword: formatMessage('confirmationPassword'),
      rePwdMsg: formatMessage('rePwdMsg'),
      checkRePwdMsg: formatMessage('checkRePwdMsg'),
      userTypeMsg: formatMessage('userTypeMsg'),
      organization: formatMessage('organization'),
      employeeNumber: formatMessage('employeeNumber'),
      empNumMsg: formatMessage('empNumMsg'),
      email: formatMessage('email'),
      emailMsg: formatMessage('emailMsg'),
      emailRuleMsg: formatMessage('emailRuleMsg'),
      ipBand: formatMessage('ipBand'),
      pwdChngCycl: formatMessage('pwdChngCycl'),
      bsnpRegNum: formatMessage('bsnpRegNum'),
      placeholderTag: formatMessage('placeholderTag'),
      duplicateCheck: formatMessage('duplicateCheck'),
      telNum: formatMessage('telNum'),
      comNm: formatMessage('comNm'),
      psicTelNum: formatMessage('psicTelNum'),
      bizReg: formatMessage('bizReg'),
      rstvNmTelNum: formatMessage('rstvNmTelNum'),
      rstvNm: formatMessage('rstvNm'),
      clphNum: formatMessage('clphNum'),
      psicNm: formatMessage('psicNm'),
      auth: formatMessage('auth'),
      startVue: 'info',
      isUpdate: false,
      isSelf: false,
      maskYn: true,
      AppConfig: AppConfig,
      authList: [],
      orgData: [],
      userInfo: {
        userTypeCd: '',
        //회원구분 
        userId: '',
        //아이디
        pwd: '',
        //비밀번호 : pwd
        comNm: '',
        //회사명 : comNm
        rstvNm: '',
        //대표자명 : rstvNm
        rstvNmTelNum: '',
        //회사대표번호 : rstvNmTelNum
        bsnpRegNum: '',
        //사업자 등록번호 : bsnpRegNum
        userNm: '',
        //담당자명 :userNm
        psicTelNum: '',
        //담당자번호 : psicTelNum
        emlAddr: '',
        //이메일 : emlAddr
        authList: [],
        fileUrl: '',
        fileName: ''
      },
      pwdPattern: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&]).*$/,
      pwdPattern2: /^.*(?=^.*)(?!.*(\w)\1\1).*$/
    };
  },
  mounted: function mounted() {
    var _this = this;

    var userId = "";

    if (this.$route.query.userId != undefined && this.$route.query.userId != "") {
      userId = this.$route.query.userId;
    } else {
      userId = Vue.$sessionStorage.get('userId');
    }

    var searchData = {
      userId: userId
    };
    Vue.httpClient.post('/user/searchUserInfo', searchData, {
      data: {
        isLoadingHide: 'Y'
      }
    }).then(function (result) {
      if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
        _this.userInfo = result.data.result;

        if (_this.userInfo.fileUrl != undefined && _this.userInfo.fileUrl != "") {
          var fileUrlArray = _this.userInfo.fileUrl.split('/');

          if (fileUrlArray.length > 1) {
            _this.userInfo.fileName = fileUrlArray[1];
          } else {
            _this.userInfo.fileName = fileUrlArray[0];
          }
        }
      }
    });
  },
  beforeMount: function beforeMount() {
    var userTypeCd = Vue.$sessionStorage.get('userTypeCd');

    if (userTypeCd == '05') {
      this.adminYn = true;
    } else {
      this.adminYn = false;
    }
  },
  methods: {
    formatMessage: formatMessage,
    userUpdate: function userUpdate() {
      var _this2 = this;

      var url = '/user/update';

      if (this.$refs['validationGroup'].instance.validate().isValid) {
        // 사용자 계정 등록
        window.$Alert.confirm(formatMessage('userUpdateMsg')).then(function (result) {
          if (!result) {
            return;
          }

          Vue.httpClient.post(url, _this2.userInfo).then(function (result) {
            if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
              window.$Alert.alert(formatMessage('userUpdateMsg2'));
              _this2.userInfo = result.data.result;
            }
          });
        });
      } else {
        var brokenRules = this.$refs['validationGroup'].instance.validate().brokenRules;
        window.$Alert.alert(brokenRules[0].message);
      }
    },
    onOrgValueChanged: function onOrgValueChanged(e) {
      this.userInfo.orgSersNum = e;
    },
    onValueChanged: function onValueChanged(e) {
      this.userInfo.authList = e.value;
    },
    passwordComparison: function passwordComparison() {
      return this.userInfo.pwd;
    },
    handleHistoryBack: function handleHistoryBack(e) {
      this.$router.go(-1);
    },
    resetPw: function resetPw() {
      var _this3 = this;

      var url = '/user/pwSearch';

      if (this.$refs['validationGroup'].instance.validate().isValid) {
        // pw 조회
        window.$Alert.confirm(formatMessage('userPwResetMsg')).then(function (result) {
          if (!result) {
            return;
          }

          Vue.httpClient.post(url, _this3.userInfo).then(function (result) {
            if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
              if (result.data.result.sendFlag == false) {
                window.$Alert.alert("비밀번호 초기화에 실패했습니다");
              } else {
                window.$Alert.alert("회원님의 비밀번호를 초기화하였습니다.<br>담당자 이메일로 발송된 비밀번호를 확인해주세요.");
                window.$store.dispatch('Login/logout').then(function () {
                  router.push('/');
                });
              }
            }
          });
        });
      } else {
        var brokenRules = this.$refs['validationGroup'].instance.validate().brokenRules;
        window.$Alert.alert(brokenRules[0].message);
      }
    },
    viewBizReg: function viewBizReg() {
      //undefined  빈값 리턴
      if (this.userInfo.fileUrl === undefined || this.userInfo.fileUrl === "") {
        window.$Alert.alert("등록된 사업자등록증 사본이 존재하지 않습니다.");
        return;
      }

      this.$modal.show('imgInfoPopup', {
        fileUrl: this.userInfo.fileUrl,
        isUpdate: false,
        startVue: 'login'
      });
    }
  }
};