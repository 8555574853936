var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DxSelectBox", {
    class: _vm.inputClass,
    attrs: {
      "data-source": _vm.codeList,
      value: "",
      "read-only": _vm.readOnly,
      disabled: _vm.disabled,
      "display-expr": "cmnsCdNm",
      "value-expr": "cmnsCd",
    },
    on: { "value-changed": _vm.onChange },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }