import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//

/* eslint-disable */

/** 
 * AUIGrid-Vue.js v0.9
 * AUIGrid v3.0.10.20210201 for Vue.js
 * Copyright © 2014-2021 AUISoft Co., Ltd. 
 * www.auisoft.net
 */
// 프로젝트 경로에 맞게 바꾸세요
import '../AUIGrid/AUIGrid.js';
import '../AUIGrid/AUIGridLicense.js';
import '../AUIGrid/AUIGrid_style.css';
import '../AUIGrid/renderers/AUIGrid.SearchButtonRenderer.js'; // 이 아래 소스는 절대 수정하지 마세요.

var _self;

export default {
  props: {
    name: {
      type: String,
      default: null
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    resizeDelayTime: {
      type: Number,
      default: 300
    }
  },
  data: function data() {
    return {
      timerId: null
    };
  },
  created: function created() {
    _self = window.AUIGrid;
    this.id = "aui-grid-wrap-" + (this.name === null ? this._uid : this.name);
    this.pid = '#' + this.id;
  },
  beforeDestroy: function beforeDestroy() {
    this.__resetGlobalReisze();

    if (_self.isCreated(this.pid)) _self.destroy(this.pid);
  },
  methods: {
    create: function create(columnLayout, props) {
      _self.create(this.pid, columnLayout, props);

      this.__setupEvents();

      this.__setupGlobalReisze();

      return this.pid;
    },
    __setupEvents: function __setupEvents() {
      var n;
      var that = this;
      var events = ["addColumn", "addRow", "addRowFinish", "addTreeColumn", "cellClick", "cellDoubleClick", "cellEditCancel", "cellEditEnd", "cellLongTap", "columnStateChange", "copyEnd", "dropCancel", "dropEnd", "filtering", "footerClick", "footerDoubleClick", "grouping", "hScrollChange", "notFound", "pageChange", "pageRowCountChange", "pasteEnd", "removeColumn", "removeRow", "removeSoftRows", "rowAllChkClick", "rowCheckClick", "selectionChange", "ready", "sorting", "treeLazyRequest", "treeOpenChange", "undoRedoChange", "updateRow", "updateRows", "vScrollChange"];
      var invokers = ["beforeInsertRow", "beforeRemoveColumn", "beforeRemoveRow", "beforeRemoveSoftRows", "cellEditBegin", "cellEditEndBefore", "contextMenu", "copyBegin", "dragBegin", "dropEndBefore", "headerClick", "keyDown", "pasteBegin", "rowNumCellClick", "rowStateCellClick", "selectionConstraint"];

      for (n in events) {
        if (that._events[events[n]]) {
          _self.bind(this.pid, events[n], function (e) {
            that.$emit(e.type, e);
          });
        }
      }

      for (n in invokers) {
        if (that._events[invokers[n]]) {
          _self.bind(this.pid, invokers[n], function (e) {
            return that.__invoke(e.type, e);
          });
        }
      }
    },
    __invoke: function __invoke(n, e) {
      var that = this;

      if (that._events[n] && typeof that._events[n][0] == "function") {
        return that._events[n][0](e);
      }
    },
    __setupGlobalReisze: function __setupGlobalReisze() {
      if (!this.autoResize) return;

      if (typeof window.orientation !== "undefined") {
        window.addEventListener("orientationchange", this.__globalResizeHandler);
      } else {
        window.addEventListener("resize", this.__globalResizeHandler);
      }
    },
    __resetGlobalReisze: function __resetGlobalReisze() {
      if (!this.autoResize) return;

      if (typeof window.orientation !== "undefined") {
        window.removeEventListener("orientationchange", this.__globalResizeHandler);
      } else {
        window.removeEventListener("resize", this.__globalResizeHandler);
      }
    },
    __globalResizeHandler: function __globalResizeHandler() {
      var that = this;

      if (that.timerId !== null) {
        clearTimeout(that.timerId);
      }

      that.timerId = setTimeout(function () {
        /**
         * ysJo 2021-10-01
         * document.querySelector(that.pid) 조건 추가
         * 캐싱된 화면에서도 resize 이벤트가 발생되기 때문에 방어코드 추가
         */
        if (_self.isCreated(that.pid) && document.querySelector(that.pid)) {
          _self.resize(that.pid);
        }
      }, that.resizeDelayTime);
    },
    addCheckedRowsByIds: function addCheckedRowsByIds(ids) {
      return _self.addCheckedRowsByIds.call(_self, this.pid, arguments[0]);
    },
    addCheckedRowsByValue: function addCheckedRowsByValue(dataField, value) {
      return _self.addCheckedRowsByValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    addColumn: function addColumn(cItems, position) {
      return _self.addColumn.call(_self, this.pid, arguments[0], arguments[1]);
    },
    addRow: function addRow(items, rowIndex) {
      return _self.addRow.call(_self, this.pid, arguments[0], arguments[1]);
    },
    addTreeColumn: function addTreeColumn(cItems, parentDataField, position) {
      return _self.addTreeColumn.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    addTreeRow: function addTreeRow(item, parentRowId, position) {
      return _self.addTreeRow.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    addTreeRowByIndex: function addTreeRowByIndex(items, rowIndex) {
      _self.addTreeRowByIndex.call(_self, this.pid, arguments[0], arguments[1]);
    },
    addUncheckedRowsByIds: function addUncheckedRowsByIds(ids) {
      return _self.addUncheckedRowsByIds.call(_self, this.pid, arguments[0]);
    },
    addUncheckedRowsByValue: function addUncheckedRowsByValue(dataField, value) {
      return _self.addUncheckedRowsByValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    appendData: function appendData(items) {
      return _self.appendData.call(_self, this.pid, arguments[0]);
    },
    bind: function bind(name, func) {
      return _self.bind.call(_self, this.pid, arguments[0], arguments[1]);
    },
    changeColumnLayout: function changeColumnLayout(newLayout) {
      return _self.changeColumnLayout.call(_self, this.pid, arguments[0]);
    },
    changeFooterLayout: function changeFooterLayout(newLayout) {
      return _self.changeFooterLayout.call(_self, this.pid, arguments[0]);
    },
    clearFilter: function clearFilter(dataField) {
      return _self.clearFilter.call(_self, this.pid, arguments[0]);
    },
    clearFilterAll: function clearFilterAll() {
      _self.clearFilterAll.call(_self, this.pid);
    },
    clearGridData: function clearGridData() {
      _self.clearGridData.call(_self, this.pid);
    },
    clearSelection: function clearSelection(mode) {
      _self.clearSelection.call(_self, this.pid, arguments[0]);
    },
    clearSortingAll: function clearSortingAll() {
      _self.clearSortingAll.call(_self, this.pid);
    },
    clearUndoRedoStack: function clearUndoRedoStack() {
      _self.clearUndoRedoStack.call(_self, this.pid);
    },
    closeFilterLayer: function closeFilterLayer() {
      _self.closeFilterLayer.call(_self, this.pid);
    },
    collapseAll: function collapseAll() {
      _self.collapseAll.call(_self, this.pid);
    },
    destroy: function destroy() {
      _self.destroy.call(_self, this.pid);
    },
    expandAll: function expandAll() {
      _self.expandAll.call(_self, this.pid);
    },
    expandItemByRowId: function expandItemByRowId(rowId, open, recursive) {
      return _self.expandItemByRowId.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    exportAsCsv: function exportAsCsv(props) {
      _self.exportAsCsv.call(_self, this.pid, arguments[0]);
    },
    exportAsJson: function exportAsJson(keyValueMode, props) {
      _self.exportAsJson.call(_self, this.pid, arguments[0], arguments[1]);
    },
    exportAsObject: function exportAsObject(keyValueMode) {
      return _self.exportAsObject.call(_self, this.pid, arguments[0]);
    },
    exportAsPdf: function exportAsPdf(props) {
      _self.exportAsPdf.call(_self, this.pid, arguments[0]);
    },
    exportAsTxt: function exportAsTxt(props) {
      _self.exportAsTxt.call(_self, this.pid, arguments[0]);
    },
    exportAsXlsx: function exportAsXlsx(exportWithStyle, props) {
      _self.exportAsXlsx.call(_self, this.pid, arguments[0], arguments[1]);
    },
    exportAsXml: function exportAsXml(props) {
      _self.exportAsXml.call(_self, this.pid, arguments[0]);
    },
    exportToCsv: function exportToCsv(props) {
      _self.exportToCsv.call(_self, this.pid, arguments[0]);
    },
    exportToJson: function exportToJson(keyValueMode, props) {
      _self.exportToJson.call(_self, this.pid, arguments[0], arguments[1]);
    },
    exportToObject: function exportToObject(keyValueMode) {
      return _self.exportToObject.call(_self, this.pid, arguments[0]);
    },
    exportToPdf: function exportToPdf(props) {
      _self.exportToPdf.call(_self, this.pid, arguments[0]);
    },
    exportToTxt: function exportToTxt(props) {
      _self.exportToTxt.call(_self, this.pid, arguments[0]);
    },
    exportToXlsx: function exportToXlsx(exportWithStyle, props) {
      _self.exportToXlsx.call(_self, this.pid, arguments[0], arguments[1]);
    },
    exportToXlsxMulti: function exportToXlsxMulti(subGridIds, props) {
      _self.exportToXlsxMulti.call(_self, this.pid, arguments[0], arguments[1]);
    },
    exportToXml: function exportToXml(props) {
      _self.exportToXml.call(_self, this.pid, arguments[0]);
    },
    forceEditingComplete: function forceEditingComplete(value, cancel) {
      return _self.forceEditingComplete.call(_self, this.pid, arguments[0], arguments[1]);
    },
    formatDate: function formatDate(value, formatString) {
      return _self.formatDate.call(_self, this.pid, arguments[0]);
    },
    formatNumber: function formatNumber(value, formatString, rounding) {
      return _self.formatNumber.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getActiveGrid: function getActiveGrid() {
      return _self.getActiveGrid.call(_self, this.pid);
    },
    getAddedColumnFields: function getAddedColumnFields() {
      return _self.getAddedColumnFields.call(_self, this.pid);
    },
    getAddedRowItems: function getAddedRowItems() {
      return _self.getAddedRowItems.call(_self, this.pid);
    },
    getAscendantsByRowId: function getAscendantsByRowId(rowId) {
      return _self.getAscendantsByRowId.call(_self, this.pid, arguments[0]);
    },
    getCellElementByIndex: function getCellElementByIndex(rowIndex, columnIndex) {
      return _self.getCellElementByIndex.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getCellFormatValue: function getCellFormatValue(rowIndex, dataField) {
      return _self.getCellFormatValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getCellFormattedValue: function getCellFormattedValue(rowIndex, dataField) {
      return _self.getCellFormattedValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getCellValue: function getCellValue(rowIndex, dataField) {
      return _self.getCellValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getCheckedRowItems: function getCheckedRowItems() {
      return _self.getCheckedRowItems.call(_self, this.pid);
    },
    getCheckedRowItemsAll: function getCheckedRowItemsAll() {
      return _self.getCheckedRowItemsAll.call(_self, this.pid);
    },
    getColumnCount: function getColumnCount() {
      return _self.getColumnCount.call(_self, this.pid);
    },
    getColumnDistinctValues: function getColumnDistinctValues(dataField, total) {
      return _self.getColumnDistinctValues.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getColumnIndexByDataField: function getColumnIndexByDataField(dataField) {
      return _self.getColumnIndexByDataField.call(_self, this.pid, arguments[0]);
    },
    getColumnInfoList: function getColumnInfoList() {
      return _self.getColumnInfoList.call(_self, this.pid);
    },
    getColumnItemByDataField: function getColumnItemByDataField(dataField) {
      return _self.getColumnItemByDataField.call(_self, this.pid, arguments[0]);
    },
    getColumnLayout: function getColumnLayout() {
      return _self.getColumnLayout.call(_self, this.pid);
    },
    getColumnValues: function getColumnValues(dataField, total) {
      return _self.getColumnValues.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getCreatedGridAll: function getCreatedGridAll() {
      return _self.getCreatedGridAll.call(_self, this.pid);
    },
    getDataFieldByColumnIndex: function getDataFieldByColumnIndex(idx) {
      return _self.getDataFieldByColumnIndex.call(_self, this.pid, arguments[0]);
    },
    getDepthByRowId: function getDepthByRowId(rowId) {
      return _self.getDepthByRowId.call(_self, this.pid, arguments[0]);
    },
    getDescendantsByRowId: function getDescendantsByRowId(rowId) {
      return _self.getDescendantsByRowId.call(_self, this.pid, arguments[0]);
    },
    getEditedRowColumnItems: function getEditedRowColumnItems() {
      return _self.getEditedRowColumnItems.call(_self, this.pid);
    },
    getEditedRowItems: function getEditedRowItems() {
      return _self.getEditedRowItems.call(_self, this.pid);
    },
    getFitColumnSizeList: function getFitColumnSizeList(fitToGrid) {
      return _self.getFitColumnSizeList.call(_self, this.pid, arguments[0]);
    },
    getFooterData: function getFooterData() {
      return _self.getFooterData.call(_self, this.pid);
    },
    getFooterFormatValueByDataField: function getFooterFormatValueByDataField(posField) {
      return _self.getFooterFormatValueByDataField.call(_self, this.pid, arguments[0]);
    },
    getFooterLayout: function getFooterLayout() {
      return _self.getFooterLayout.call(_self, this.pid);
    },
    getFooterValueByDataField: function getFooterValueByDataField(posField) {
      return _self.getFooterValueByDataField.call(_self, this.pid, arguments[0]);
    },
    getGridData: function getGridData() {
      return _self.getGridData.call(_self, this.pid);
    },
    getHiddenColumnDataFields: function getHiddenColumnDataFields() {
      return _self.getHiddenColumnDataFields.call(_self, this.pid);
    },
    getHiddenColumnIndexes: function getHiddenColumnIndexes() {
      return _self.getHiddenColumnIndexes.call(_self, this.pid);
    },
    getIndexesByEvent: function getIndexesByEvent(event) {
      return _self.getIndexesByEvent.call(_self, this.pid, arguments[0]);
    },
    getInitCellValue: function getInitCellValue(rowId, dataField) {
      return _self.getInitCellValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getInitValueItem: function getInitValueItem(rowId) {
      return _self.getInitValueItem.call(_self, this.pid, arguments[0]);
    },
    getItemByRowId: function getItemByRowId(rowIdValue) {
      return _self.getItemByRowId.call(_self, this.pid, arguments[0]);
    },
    getItemByRowIndex: function getItemByRowIndex(rowIndex) {
      return _self.getItemByRowIndex.call(_self, this.pid, arguments[0]);
    },
    getItemsByValue: function getItemsByValue(dataField, value) {
      return _self.getItemsByValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getMergeEndRowIndex: function getMergeEndRowIndex(rowIndex, columnIndex) {
      return _self.getMergeEndRowIndex.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getMergeItems: function getMergeItems(rowIndex, columnIndex) {
      return _self.getMergeItems.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getMergeStartRowIndex: function getMergeStartRowIndex(rowIndex, columnIndex) {
      return _self.getMergeStartRowIndex.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getOrgCellValue: function getOrgCellValue(uid, dataField) {
      return _self.getOrgCellValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getOrgGridData: function getOrgGridData() {
      return _self.getOrgGridData.call(_self, this.pid);
    },
    getOrgValueItem: function getOrgValueItem(uids) {
      return _self.getOrgValueItem.call(_self, this.pid, arguments[0]);
    },
    getParentColumnByDataField: function getParentColumnByDataField(dataField) {
      return _self.getParentColumnByDataField.call(_self, this.pid, arguments[0]);
    },
    getParentItemByRowId: function getParentItemByRowId(rowId) {
      return _self.getParentItemByRowId.call(_self, this.pid, arguments[0]);
    },
    getProp: function getProp(name) {
      return _self.getProp.call(_self, this.pid, arguments[0]);
    },
    getProperty: function getProperty(name) {
      return _self.getProperty.call(_self, this.pid, arguments[0]);
    },
    getRemovedColumnFields: function getRemovedColumnFields() {
      return _self.getRemovedColumnFields.call(_self, this.pid);
    },
    getRemovedItems: function getRemovedItems(includeAdded) {
      return _self.getRemovedItems.call(_self, this.pid, arguments[0]);
    },
    getRemovedNewItems: function getRemovedNewItems() {
      return _self.getRemovedNewItems.call(_self, this.pid);
    },
    getRowCount: function getRowCount() {
      return _self.getRowCount.call(_self, this.pid);
    },
    getRowIndexesByValue: function getRowIndexesByValue(dataField, values) {
      return _self.getRowIndexesByValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getRowPosition: function getRowPosition() {
      return _self.getRowPosition.call(_self, this.pid);
    },
    getRowsByValue: function getRowsByValue(dataField, values) {
      return _self.getRowsByValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    getScaleFactor: function getScaleFactor() {
      return _self.getScaleFactor.call(_self, this.pid);
    },
    getSelectedColIndexes: function getSelectedColIndexes() {
      return _self.getSelectedColIndexes.call(_self, this.pid);
    },
    getSelectedIndex: function getSelectedIndex() {
      return _self.getSelectedIndex.call(_self, this.pid);
    },
    getSelectedItems: function getSelectedItems() {
      return _self.getSelectedItems.call(_self, this.pid);
    },
    getSelectedRows: function getSelectedRows() {
      return _self.getSelectedRows.call(_self, this.pid);
    },
    getSelectedText: function getSelectedText(exceptHidden) {
      return _self.getSelectedText.call(_self, this.pid, arguments[0]);
    },
    getSortingFields: function getSortingFields() {
      return _self.getSortingFields.call(_self, this.pid);
    },
    getStateCache: function getStateCache() {
      return _self.getStateCache.call(_self, this.pid);
    },
    getTreeFlatData: function getTreeFlatData() {
      return _self.getTreeFlatData.call(_self, this.pid);
    },
    getTreeGridData: function getTreeGridData() {
      return _self.getTreeGridData.call(_self, this.pid);
    },
    getTreeTotalDepth: function getTreeTotalDepth() {
      return _self.getTreeTotalDepth.call(_self, this.pid);
    },
    hideColumnByDataField: function hideColumnByDataField(dataField) {
      return _self.hideColumnByDataField.call(_self, this.pid, arguments[0]);
    },
    hideColumnGroup: function hideColumnGroup(dataField) {
      return _self.hideColumnGroup.call(_self, this.pid, arguments[0]);
    },
    indentTreeDepth: function indentTreeDepth() {
      _self.indentTreeDepth.call(_self, this.pid);
    },
    indexToRowId: function indexToRowId(rowIndex) {
      return _self.indexToRowId.call(_self, this.pid, arguments[0]);
    },
    isAddedById: function isAddedById(uid) {
      return _self.isAddedById.call(_self, this.pid, arguments[0]);
    },
    isAvailabePdf: function isAvailabePdf() {
      return _self.isAvailabePdf.call(_self, this.pid);
    },
    isAvailableLocalDownload: function isAvailableLocalDownload() {
      return _self.isAvailableLocalDownload.call(_self, this.pid);
    },
    isCheckedRowById: function isCheckedRowById(id) {
      return _self.isCheckedRowById.call(_self, this.pid, arguments[0]);
    },
    isCreated: function isCreated() {
      return _self.isCreated.call(_self, this.pid);
    },
    isEditedById: function isEditedById(uid) {
      return _self.isEditedById.call(_self, this.pid, arguments[0]);
    },
    isEditedCell: function isEditedCell(uid, dataField) {
      return _self.isEditedCell.call(_self, this.pid, arguments[0], arguments[1]);
    },
    isFilteredGrid: function isFilteredGrid() {
      return _self.isFilteredGrid.call(_self, this.pid);
    },
    isItemBranchByRowId: function isItemBranchByRowId(rowId) {
      return _self.isItemBranchByRowId.call(_self, this.pid, arguments[0]);
    },
    isItemOpenByRowId: function isItemOpenByRowId(rowId) {
      return _self.isItemOpenByRowId.call(_self, this.pid, arguments[0]);
    },
    isItemVisibleByRowId: function isItemVisibleByRowId(rowId) {
      return _self.isItemVisibleByRowId.call(_self, this.pid, arguments[0]);
    },
    isMergedCell: function isMergedCell(rowIndex, columnIndex) {
      return _self.isMergedCell.call(_self, this.pid, arguments[0], arguments[1]);
    },
    isOpenFilterLayer: function isOpenFilterLayer() {
      return _self.isOpenFilterLayer.call(_self, this.pid);
    },
    isRemovedById: function isRemovedById(uid) {
      return _self.isRemovedById.call(_self, this.pid, arguments[0]);
    },
    isSortedGrid: function isSortedGrid() {
      return _self.isSortedGrid.call(_self, this.pid);
    },
    isTreeGrid: function isTreeGrid() {
      return _self.isTreeGrid.call(_self, this.pid);
    },
    isUniqueValue: function isUniqueValue(dataField, value) {
      return _self.isUniqueValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    movePageTo: function movePageTo(pageNum, maintainVScroll, maintainHScroll) {
      return _self.movePageTo.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    moveRows2Down: function moveRows2Down() {
      return _self.moveRows2Down.call(_self, this.pid);
    },
    moveRows2Up: function moveRows2Up() {
      return _self.moveRows2Up.call(_self, this.pid);
    },
    moveRowsToDown: function moveRowsToDown() {
      return _self.moveRowsToDown.call(_self, this.pid);
    },
    moveRowsToUp: function moveRowsToUp() {
      return _self.moveRowsToUp.call(_self, this.pid);
    },
    openFilterLayer: function openFilterLayer(dataField) {
      return _self.openFilterLayer.call(_self, this.pid, arguments[0]);
    },
    openInputer: function openInputer() {
      return _self.openInputer.call(_self, this.pid);
    },
    outdentTreeDepth: function outdentTreeDepth() {
      _self.outdentTreeDepth.call(_self, this.pid);
    },
    redo: function redo() {
      _self.redo.call(_self, this.pid);
    },
    redoable: function redoable() {
      return _self.redoable.call(_self, this.pid);
    },
    refresh: function refresh() {
      _self.refresh.call(_self, this.pid);
    },
    refreshRows: function refreshRows(items, style, flashTime) {
      return _self.refreshRows.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    removeAjaxLoader: function removeAjaxLoader() {
      _self.removeAjaxLoader.call(_self, this.pid);
    },
    removeCheckedRows: function removeCheckedRows() {
      return _self.removeCheckedRows.call(_self, this.pid);
    },
    removeColumn: function removeColumn(columnIndex) {
      _self.removeColumn.call(_self, this.pid, arguments[0]);
    },
    removeInfoMessage: function removeInfoMessage() {
      _self.removeInfoMessage.call(_self, this.pid);
    },
    removeRow: function removeRow(rowIndex) {
      return _self.removeRow.call(_self, this.pid, arguments[0]);
    },
    removeRowByRowId: function removeRowByRowId(rowIds) {
      return _self.removeRowByRowId.call(_self, this.pid, arguments[0]);
    },
    removeSoftRows: function removeSoftRows(ids) {
      return _self.removeSoftRows.call(_self, this.pid, arguments[0]);
    },
    removeToastMessage: function removeToastMessage() {
      _self.removeToastMessage.call(_self, this.pid);
    },
    resetUpdatedColumns: function resetUpdatedColumns(opts) {
      _self.resetUpdatedColumns.call(_self, this.pid, arguments[0]);
    },
    resetUpdatedItemById: function resetUpdatedItemById(rowId, flag) {
      _self.resetUpdatedItemById.call(_self, this.pid, arguments[0], arguments[1]);
    },
    resetUpdatedItems: function resetUpdatedItems(flag) {
      _self.resetUpdatedItems.call(_self, this.pid, arguments[0]);
    },
    resize: function resize(w, h) {
      _self.resize.call(_self, this.pid, arguments[0], arguments[1]);
    },
    restoreEditedCells: function restoreEditedCells(cells) {
      _self.restoreEditedCells.call(_self, this.pid, arguments[0]);
    },
    restoreEditedRows: function restoreEditedRows(rowIndex) {
      _self.restoreEditedRows.call(_self, this.pid, arguments[0]);
    },
    restoreSoftRows: function restoreSoftRows(flag) {
      return _self.restoreSoftRows.call(_self, this.pid, arguments[0]);
    },
    rowIdToIndex: function rowIdToIndex(rowId) {
      return _self.rowIdToIndex.call(_self, this.pid, arguments[0]);
    },
    search: function search(dataField, term, opts) {
      return _self.search.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    searchAll: function searchAll(term, opts) {
      return _self.searchAll.call(_self, this.pid, arguments[0], arguments[1]);
    },
    selectRowsByRowId: function selectRowsByRowId(rowIds) {
      return _self.selectRowsByRowId.call(_self, this.pid, arguments[0]);
    },
    setAllCheckedRows: function setAllCheckedRows(check) {
      _self.setAllCheckedRows.call(_self, this.pid, arguments[0]);
    },
    setCellMerge: function setCellMerge(flag) {
      _self.setCellMerge.call(_self, this.pid, arguments[0]);
    },
    setCellValue: function setCellValue(rowIndex, dataField, value) {
      return _self.setCellValue.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    setCheckedRowsByIds: function setCheckedRowsByIds(ids) {
      return _self.setCheckedRowsByIds.call(_self, this.pid, arguments[0]);
    },
    setCheckedRowsByValue: function setCheckedRowsByValue(dataField, value) {
      return _self.setCheckedRowsByValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setColumnChildOrder: function setColumnChildOrder(dataFieldOrders) {
      _self.setColumnChildOrder.call(_self, this.pid, arguments[0]);
    },
    setColumnOrder: function setColumnOrder(dataFieldOrders) {
      _self.setColumnOrder.call(_self, this.pid, arguments[0]);
    },
    setColumnProp: function setColumnProp(columnIndex, obj) {
      _self.setColumnProp.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setColumnPropByDataField: function setColumnPropByDataField(dataField, obj) {
      _self.setColumnPropByDataField.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setColumnSizeList: function setColumnSizeList(value) {
      return _self.setColumnSizeList.call(_self, this.pid, arguments[0]);
    },
    setCsvGridData: function setCsvGridData(gridData, isSimple) {
      return _self.setCsvGridData.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setEditingInputValue: function setEditingInputValue(value) {
      return _self.setEditingInputValue.call(_self, this.pid, arguments[0]);
    },
    setFilter: function setFilter(dataField, func) {
      return _self.setFilter.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setFilterByValues: function setFilterByValues(dataField, values) {
      return _self.setFilterByValues.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setFixedColumnCount: function setFixedColumnCount(value) {
      _self.setFixedColumnCount.call(_self, this.pid, arguments[0]);
    },
    setFixedRowCount: function setFixedRowCount(value) {
      _self.setFixedRowCount.call(_self, this.pid, arguments[0]);
    },
    setFocus: function setFocus() {
      _self.setFocus.call(_self, this.pid);
    },
    setFooter: function setFooter(footerData) {
      return _self.setFooter.call(_self, this.pid, arguments[0]);
    },
    setGridData: function setGridData(gridData) {
      return _self.setGridData.call(_self, this.pid, arguments[0]);
    },
    setGroupBy: function setGroupBy(groupingFields, summaryProps) {
      return _self.setGroupBy.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setHScrollPosition: function setHScrollPosition(columnIndex) {
      return _self.setHScrollPosition.call(_self, this.pid, arguments[0]);
    },
    setHScrollPositionByPx: function setHScrollPositionByPx(px) {
      return _self.setHScrollPositionByPx.call(_self, this.pid, arguments[0]);
    },
    setHeaderRendererProp: function setHeaderRendererProp(columnIndex, obj) {
      _self.setHeaderRendererProp.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setInfoMessage: function setInfoMessage(msgHTML) {
      _self.setInfoMessage.call(_self, this.pid, arguments[0]);
    },
    setPageRowCount: function setPageRowCount(count) {
      _self.setPageRowCount.call(_self, this.pid, arguments[0]);
    },
    setProp: function setProp(obj, value) {
      _self.setProp.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setProperty: function setProperty(obj, value) {
      return _self.setProperty.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setRendererProp: function setRendererProp(columnIndex, obj) {
      _self.setRendererProp.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setRowPosition: function setRowPosition(rowPosition) {
      return _self.setRowPosition.call(_self, this.pid, arguments[0]);
    },
    setScaleFactor: function setScaleFactor(value) {
      _self.setScaleFactor.call(_self, this.pid, arguments[0]);
    },
    setSelectionAll: function setSelectionAll() {
      _self.setSelectionAll.call(_self, this.pid);
    },
    setSelectionBlock: function setSelectionBlock(sr, er, sc, ec) {
      _self.setSelectionBlock.call(_self, this.pid, arguments[0], arguments[1], arguments[2], arguments[3]);
    },
    setSelectionByIndex: function setSelectionByIndex(rowIndex, columnIndex) {
      return _self.setSelectionByIndex.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setSelectionColumn: function setSelectionColumn(startColIdx, endColIdx) {
      return _self.setSelectionColumn.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setSelectionMode: function setSelectionMode(mode) {
      return _self.setSelectionMode.call(_self, this.pid, arguments[0]);
    },
    setSorting: function setSorting(sortingInfoArr, onlyLastDepthSorting) {
      return _self.setSorting.call(_self, this.pid, arguments[0], arguments[1]);
    },
    setXmlGridData: function setXmlGridData(gridData, selector) {
      return _self.setXmlGridData.call(_self, this.pid, arguments[0], arguments[1]);
    },
    showAjaxLoader: function showAjaxLoader() {
      _self.showAjaxLoader.call(_self, this.pid);
    },
    showAllColumns: function showAllColumns() {
      _self.showAllColumns.call(_self, this.pid);
    },
    showColumnByDataField: function showColumnByDataField(dataField) {
      return _self.showColumnByDataField.call(_self, this.pid, arguments[0]);
    },
    showColumnGroup: function showColumnGroup(dataField) {
      return _self.showColumnGroup.call(_self, this.pid, arguments[0]);
    },
    showInfoMessage: function showInfoMessage(msgHTML) {
      _self.showInfoMessage.call(_self, this.pid, arguments[0]);
    },
    showItemsOnDepth: function showItemsOnDepth(depth) {
      return _self.showItemsOnDepth.call(_self, this.pid, arguments[0]);
    },
    showToastMessage: function showToastMessage(rowIndex, columnIndex, msg) {
      return _self.showToastMessage.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    syncGridData: function syncGridData(gridData, stateCache) {
      _self.syncGridData.call(_self, this.pid, arguments[0], arguments[1]);
    },
    unbind: function unbind(name, func) {
      return _self.unbind.call(_self, this.pid, arguments[0], arguments[1]);
    },
    undo: function undo() {
      _self.undo.call(_self, this.pid);
    },
    undoable: function undoable() {
      return _self.undoable.call(_self, this.pid);
    },
    update: function update() {
      _self.update.call(_self, this.pid);
    },
    updateAllToValue: function updateAllToValue(dataField, value) {
      return _self.updateAllToValue.call(_self, this.pid, arguments[0], arguments[1]);
    },
    updateGrouping: function updateGrouping() {
      _self.updateGrouping.call(_self, this.pid);
    },
    updateRow: function updateRow(item, rowIndex, isMarkEdited) {
      return _self.updateRow.call(_self, this.pid, arguments[0], arguments[1], arguments[2]);
    },
    updateRowBlockToValue: function updateRowBlockToValue(start, end, dataField, value) {
      _self.updateRowBlockToValue.call(_self, this.pid, arguments[0], arguments[1], arguments[2], arguments[3]);
    },
    updateRowsById: function updateRowsById(items, isMarkEdited) {
      return _self.updateRowsById.call(_self, this.pid, arguments[0], arguments[1]);
    },
    validateChangedGridData: function validateChangedGridData(dataFields, msg) {
      return _self.validateChangedGridData.call(_self, this.pid, arguments[0], arguments[1]);
    },
    validateGridData: function validateGridData(fields, msg) {
      return _self.validateGridData.call(_self, this.pid, arguments[0], arguments[1]);
    }
  }
};