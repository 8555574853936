import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import DxTextBox from 'devextreme-vue/text-box';
import CustomStore from 'devextreme/data/custom_store';
import { formatMessage } from 'devextreme/localization';
import DxSelectCodeList from '@/components/DxSelectCodeList';
import DxSelectBox from 'devextreme-vue/select-box'; // import locale from '@/locale/bos-locale'

import AppConfig from '@/config';
import { DxExport, DxSelection, DxDataGrid, DxColumn, DxEditing, DxScrolling, DxSummary, DxLookup, DxPager, DxPaging, DxTotalItem, DxColumnFixing //컬럼 고정때필요
} from 'devextreme-vue/data-grid';
var $ = window.$;
export default {
  name: 'memIdPopup',
  components: {
    DxPager: DxPager,
    DxPaging: DxPaging,
    DxSelectBox: DxSelectBox,
    DxDataGrid: DxDataGrid,
    DxColumn: DxColumn,
    DxSelectCodeList: DxSelectCodeList,
    DxTextBox: DxTextBox
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: formatMessage('popupModlChangeSearch')
    }
  },
  data: function data() {
    return {
      startVue: 'userMange',
      userStatCd: formatMessage('userStatCd'),
      userTypeCd: formatMessage('userTypeCd'),
      userId: formatMessage('userId'),
      startEditAction: 'click',
      displayMode: "full",
      pageSizes: [20, 50, 100],
      searchKeyWord: [{
        cd: '',
        nm: 'select...'
      }, {
        cd: 'memNm',
        nm: '회사명'
      }, {
        cd: 'memId',
        nm: '아이디'
      }],
      gridId: 'memIdPopup_' + new Date().getTime(),
      columnLayout: [{
        dataField: 'modlNum',
        headerText: formatMessage('select'),
        editable: false,
        width: 100,
        renderer: {
          type: 'ButtonRenderer',
          labelText: formatMessage('select'),
          onClick: this.onDoubleclick
        }
      }, {
        dataField: 'brndNm',
        headerText: formatMessage('brndNm'),
        editable: false
      }, {
        dataField: 'modlNm',
        headerText: formatMessage('modlNm'),
        editable: false,
        width: 200
      }, {
        dataField: 'ptnmNm',
        headerText: formatMessage('ptnmNm'),
        editable: false,
        width: 200
      }, {
        dataField: 'tlcmCd',
        headerText: formatMessage('tlcmCd'),
        editable: false,
        visible: false
      }, {
        dataField: 'skuCd',
        headerText: formatMessage('skuCd'),
        editable: false,
        visible: false
      }, {
        dataField: 'tlcmCdNm',
        headerText: formatMessage('tlcmNm'),
        editable: false
      }, {
        dataField: 'osPrts',
        headerText: formatMessage('osPrts'),
        editable: false
      }, {
        dataField: 'modlCpct',
        headerText: formatMessage('modlCpct'),
        editable: false
      }],
      AppConfig: AppConfig,
      searchData: {
        searchType: "",
        searchWord: "",
        userTypeCd: "",
        userStatCd: "",
        userId: "",
        memId: ""
      },
      datasource: new CustomStore({
        key: 'userId',
        load: function load(loadOptions) {
          var url;
          loadOptions.searchData = this.searchData;
          url = '/user/getUserManagementAllList';
          return Vue.httpClient.post(url, loadOptions).then(function (response) {
            if (response.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
              return {
                data: response.data.result.items,
                totalCount: response.data.result.totalCount
              };
            }
          });
        }
      })
    };
  },
  computed: {},
  methods: {
    formatMessage: formatMessage,
    handleValueChangedBySearchType: function handleValueChangedBySearchType(e) {
      this.searchData.searchWord = null;
    },
    beforeOpen: function beforeOpen(e) {
      this.searchData.searchType = "memId";
      this.searchData.searchWord = this.item.memId;
      this.datasource.searchData = this.searchData;
    },
    opened: function opened(e) {
      // this.productgrid = this.$refs.productGrid
      // this.productgrid.create(this.columnLayout, this.auigridProps)
      //this.setGridData([])
      var popLeft = ($(window).width() - $('#memIdPopup').outerWidth()) / 2;
      var popTop = ($(window).height() - $('#memIdPopup').outerHeight()) / 2;
      if (popLeft < 0) popLeft = 0;
      if (popTop < 0) popTop = 0; // $('#popupMask').fadeIn()

      $('#memIdPopup').css({
        'left': popLeft,
        'top': popTop
      }).fadeIn().addClass('on'); // this.productgrid.bind('cellDoubleClick', this.onDoubleclick)
      // this.productgrid.bind('ready', this.handleGridResize) // ready 이벤트 핸들러 매핑

      this.search();
    },
    closed: function closed() {
      $('#memIdPopup').fadeOut().removeClass('on');
    },
    search: function search() {
      this.datasource.searchData = this.searchData;
      this.$refs['grid'].instance.refresh();
    },
    onDoubleclick: function onDoubleclick(e) {
      this.$emit('select-model-complete', e.key);
      this.close();
    },
    close: function close() {
      this.closed();
      this.$modal.hide('memIdPopup');
    }
  }
};