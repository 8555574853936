'use strict';

export default {
  /* API */
  HTTP_CONFIG: {
    baseURL: process.env.SERVER_BASE_URL,
    timeout: 10000,
    crossDomain: true,
    withCredentials: false,
    debug: process.env.NODE_ENV === 'development'
  },

  /* public URL */
  PUBLIC_URL: process.env.PUBLIC_URL,

  /* 외부 URL사용 정보. */
  EXTERNAL_URL: {
    taxinvoiceURL: process.env.TAXINVOICE_URL,
    paymentreqURL: process.env.PAYMENT_REQ_URL,
    paymentlayerURL: process.env.PAYMENT_LAYER_URL,
    billingcardregURL: process.env.BILLINGCARD_REG_URL,
    jusoSrchConfirmKey: process.env.JUSO_SRCH_CONFIRM_KEY // kakaoPostCodeURL: process.env.KAKAO_POSTCODE_URL,
    // kakaoMapsURL: process.env.KAKAO_MAPS_URL

  },

  /* 사용자 권한 */
  USER_AUTH: {
    SYSTEM_ADMIN: '00'
  },

  /* API 결과 코드 */
  HTTP_RESPONSE_SUCCESS: '200',
  // 성공
  DUP_PK: '601',
  // pk 중복
  DUP_REQ_PK: '602',
  // 등록 요청 목록의 pk 중복
  NOT_VALID_AGUMENT: '701',
  // validation 오류
  NOT_LOGIN: '801',
  // 로그인 정보 없음
  LOGIN_FAIL: '802',
  // 로그인 실패
  UNAUTHORIZATION: '803',
  // 권한 오류
  AUTH_NUMBER_NULL: '814',
  // 인증 번호 미입력
  AUTH_NUMBER_FAIL: '804',
  // 인증 번호 인증 실패
  NO_ID: '805',
  // 존재하지 않는 ID
  NOT_EXIST_PHONE: '806',
  // 휴대폰 번호 없음
  EXIST_PHONE: '807',
  // 휴대폰 번호 있음
  ACCOUNT_LOCKOUT: '808',
  // 계정잠금
  FILE_UPLOAD_FAIL: '901',
  // 파일 업로드 실패
  FILE_DOWNLOAD_FAIL: '902',
  // 파일 다운로드 실패
  ERROR: '599',
  // 오류

  /* 로그인 페이지 공사중 표시 */
  LOGIN_SHOW: true,

  /* auigrid resize delay time */
  TIME_TO_DELAY: 300
};