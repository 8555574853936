'use strict';

import "core-js/modules/es.object.to-string.js";
import HttpWrapper from '@/modules/general/http';
import Exception from '@/modules/general/exception';
import router from '@/router';
import { EventBus } from "@/modules/event-bus";
import ToastTemplate from './template/ToastTemplate.vue';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import localedata from "../locale/bos-locale";
import bosdictko from "../locale/bos-dict-ko";
import bosdicten from "../locale/bos-dict-en";
var HttpModule = {
  formatMessage: formatMessage,
  install: function install(Vue, options) {
    locale(localedata.getLocale());
    loadMessages(bosdictko.getDictionary());
    loadMessages(bosdicten.getDictionary());
    var http = HttpWrapper.create(options.HTTP_CONFIG);
    var ToastConstructor = Vue.extend(ToastTemplate);
    var toastInstance = null;
    toastInstance = new ToastConstructor({
      el: document.createElement('div'),
      data: function data() {
        return {
          params: {
            isVisible: false,
            message: '',
            type: 'info'
          }
        };
      }
    });
    var isToast;
    var alMessage = formatMessage('processingSuccess'); // client action 확인

    http.interceptors.request.use(function (config) {
      isToast = 'N';
      $('#loading').show(); // loading images off

      if (config.data != undefined && config.data.isLoadingHide != undefined && config.data.isLoadingHide != null && config.data.isLoadingHide == 'Y') {
        $('#loading').hide();
      }

      if (config.data != undefined && config.data.isToast != undefined && config.data.isToast != null && config.data.isToast == 'Y') {
        isToast = config.data.isToast;
      } // if ( config.data != undefined && config.data.alMessage != undefined && config.data.alMessage != null) {
      //   alMessage = config.data.alMessage
      // }else{
      //   alMessage = ''
      // }


      if (config.url != null && config.url.indexOf("/login") < 0 && config.url != '/authnumber' && config.url != '/logout') {
        EventBus.$emit("event-reaction");
      }

      return config;
    }, function (error) {
      $("#loading").hide();
    });
    http.interceptors.response.use(function (response) {
      $("#loading").hide();

      if (isToast != undefined && isToast != null && isToast == 'Y') {
        isToast = 'N';
        var toastData = {
          message: ''
        };
        toastData.message = alMessage;

        toastInstance.open = function (e) {
          $('#toastMintit').fadeIn(1000, function () {
            $(this).fadeOut(2000);
          });
          this.params.message = e.message;
        };

        document.body.appendChild(toastInstance.$el);
        toastInstance.open(toastData);
      }

      var requestUrl = response.request.responseURL;
      var httpStatus = response.status;
      var data = response.data;
      var result = null;
      var isSuccess = true;

      if (!data) {
        isSuccess = false;
      }

      if (response.data) {
        result = response.data;

        if (result.status != options.HTTP_RESPONSE_SUCCESS && result.status != options.DUP_PK && result.status != options.DUP_REQ_PK) {
          isSuccess = false;
        }
      }

      if (response.request.responseType === 'arraybuffer') {
        isSuccess = true;
      }

      if (isSuccess === false) {
        return Promise.reject(new Exception.ApiResonseError('[HTTP] Response Data is empty.', requestUrl, httpStatus, result));
      }

      return response;
    }, function (error) {
      $("#loading").hide();
      $("div[name=loading]").hide();

      if (error.response) {
        if (error.response.status === 500) {
          // 로그인 오류
          if (error.response.data.status == options.NOT_LOGIN) {
            window.$Alert.alert('로그인 인증이 만료되었습니다.<br/>다시 로그인하여 주십시오.').then(function () {
              window.$store.dispatch('Login/logout').then(function () {
                router.push('/');
              });
            }); // 권한 오류     
          } else if (error.response.data.status == options.UNAUTHORIZATION) {
            window.$Alert.alert('권한이 없습니다.'); // api validation 오류  
          } else if (error.response.data.status == options.NOT_VALID_AGUMENT) {
            window.$Alert.alert('validation 오류 : <br/>' + error.response.data.result.message); // 파일 업로드 실패  
          } else if (error.response.data.status == options.FILE_UPLOAD_FAIL) {
            window.$Alert.alert('파일업로드 중 오류가 발생하였습니다.'); // 파일 다운로드 실패 
          } else if (error.response.data.status == options.FILE_DOWNLOAD_FAIL) {
            window.$Alert.alert('파일다운로드 중 오류가 발생하였습니다.');
          } else {
            if (error.response.data.result != undefined && (error.response.data.result.message != '' || error.response.data.result.message != undefined)) {
              window.$Alert.alert(error.response.data.result.message);
            } else {
              window.$Alert.alert('실행 중 오류가 발생하였습니다.<br/>잠시 후 다시 실행 해 주십시오.');
            }
          }
        }
      } else {
        window.$Alert.alert(error);
      }

      return Promise.reject(error.response);
    });
    window.$http = http;
    Vue.httpClient = http;
    Vue.prototype.$http = http;
  }
};
export default HttpModule;