'use strict';

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import _ from 'lodash';
export default {
  /**
   * 엑셀 파일 다운로드
   **/
  getExcelFile: function getExcelFile(response, fileName) {
    var result = document.createElement('a');
    var blob = new Blob([response.data], {
      type: response.headers['content-type']
    }); // 파일이름이 없을 경우 기본 파일이름을 셋팅한다
    // 기본 파일이름 : export_yyyymmdd.xlsx

    fileName = _.isEmpty(fileName) ? 'export' : fileName;
    fileName = fileName + '_' + _.now() + '.xlsx';
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.target = '_self';
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link); //메모리 누수 방지
  },
  getExcelFileWithName: function getExcelFileWithName(response, fileName) {
    var url = window.URL.createObjectURL(new Blob([response.data], {
      type: response.headers['content-type']
    }));
    fileName = _.isEmpty(fileName) ? 'export_' + _.now() : fileName;
    fileName += '.xlsx';
    var link = document.createElement('a');
    link.href = url;
    link.target = '_self';
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link); //메모리 누수 방지
  },

  /**
   * 첨부 파일 다운로드
   **/
  getFile: function getFile(response, fileName) {
    var result = document.createElement('a');
    var blob = new Blob([response.data], {
      type: response.headers['content-type']
    }); // 파일이름이 없을 경우 기본 파일이름을 셋팅한다
    // 기본 파일이름 : export_yyyymmdd.xlsx

    fileName = _.isEmpty(fileName) ? 'export' : fileName;
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.target = '_self';
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link); //메모리 누수 방지
  }
};