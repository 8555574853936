var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "imgInfoPopup",
            width: 950,
            height: 800,
            clickToClose: false,
          },
          on: { "before-open": _vm.beforeOpen, opened: _vm.opened },
        },
        [
          _c("div", { staticClass: "popup", attrs: { id: "pop_up" } }, [
            _c("div", { staticClass: "pop_tit" }, [
              _c("span", [_vm._v("사진업로드 이미지")]),
              _c(
                "a",
                {
                  staticClass: "pop_close",
                  attrs: { href: "javascript:void(0);", title: "close" },
                  on: { click: _vm.closePopup },
                },
                [_vm._v("close")]
              ),
            ]),
            _c("div", { staticClass: "pop_con_wrap" }, [
              _c("div", {
                staticClass: "pop_con mscroll",
                staticStyle: {
                  overflow: "auto",
                  width: "930px",
                  height: "600px",
                },
                attrs: { id: "img" },
              }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }