//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import AppConfig from '@/config';
import Utils from '@/modules/utils';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectCodeList from '@/components/DxSelectCodeList';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import DxTagBox from 'devextreme-vue/tag-box';
import DxValidationGroup from 'devextreme-vue/validation-group';
import DxSelectBox from 'devextreme-vue/select-box';
import DataSource from 'devextreme/data/data_source';
import { DxValidator, DxRequiredRule, DxCompareRule, DxEmailRule, DxPatternRule, DxStringLengthRule, DxRangeRule } from 'devextreme-vue/validator';
export default {
  name: 'pwFindPopup',
  components: {
    DxSelectBox: DxSelectBox,
    DxValidationGroup: DxValidationGroup,
    DxValidator: DxValidator,
    DxRequiredRule: DxRequiredRule,
    DxCompareRule: DxCompareRule,
    DxEmailRule: DxEmailRule,
    DxPatternRule: DxPatternRule,
    DxStringLengthRule: DxStringLengthRule,
    DxRangeRule: DxRangeRule,
    DxTagBox: DxTagBox,
    DxTextBox: DxTextBox,
    DxSelectCodeList: DxSelectCodeList
  },
  props: {
    value: ''
  },
  data: function data() {
    return {
      rules: {
        'X': /0/,
        'Y': /1/,
        'Z': /0/,
        'A': '*'
      },
      idCheck: false,
      save: formatMessage('save'),
      confirm: formatMessage('confirm'),
      update: formatMessage('update'),
      close: formatMessage('close'),
      userId: formatMessage('userId'),
      userNm: formatMessage('userNm'),
      userStatCd: formatMessage('userStatCd'),
      userTypeCd: formatMessage('userTypeCd'),
      password: formatMessage('password'),
      pwdMsg: formatMessage('pwdMsg'),
      confirmationPassword: formatMessage('confirmationPassword'),
      rePwdMsg: formatMessage('rePwdMsg'),
      checkRePwdMsg: formatMessage('checkRePwdMsg'),
      userTypeMsg: formatMessage('userTypeMsg'),
      organization: formatMessage('organization'),
      employeeNumber: formatMessage('employeeNumber'),
      empNumMsg: formatMessage('empNumMsg'),
      email: formatMessage('email'),
      emailMsg: formatMessage('emailMsg'),
      emailRuleMsg: formatMessage('emailRuleMsg'),
      ipBand: formatMessage('ipBand'),
      pwdChngCycl: formatMessage('pwdChngCycl'),
      bsnpRegNum: formatMessage('bsnpRegNum'),
      placeholderTag: formatMessage('placeholderTag'),
      duplicateCheck: formatMessage('duplicateCheck'),
      telNum: formatMessage('telNum'),
      comNm: formatMessage('comNm'),
      psicTelNum: formatMessage('psicTelNum'),
      rstvNmTelNum: formatMessage('rstvNmTelNum'),
      rstvNm: formatMessage('rstvNm'),
      clphNum: formatMessage('clphNum'),
      psicNm: formatMessage('psicNm'),
      auth: formatMessage('auth'),
      startVue: 'manage',
      isUpdate: false,
      isSelf: false,
      maskYn: true,
      AppConfig: AppConfig,
      authList: [],
      orgData: [],
      userInfo: {
        userId: '',
        //id
        pwd: '',
        //비번
        repwd: '',
        userNm: '',
        //이름
        userTypeCd: '',
        //유형
        bsnpRegNum: '',
        //사업자번호
        orgSersNum: 0,
        //소속
        empNum: '',
        //사번
        telNum: '',
        //전화번호
        clphNum: '',
        //휴대폰번호
        comNm: '',
        //회사명
        emlAddr: '',
        //이메일
        alwdIpBand: '',
        //ip대역
        pwdChngCycl: '30',
        //변경주기
        userStatCd: '',
        //상태
        authList: []
      },
      pwdPattern: /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&]).*$/,
      pwdPattern2: /^.*(?=^.*)(?!.*(\w)\1\1).*$/
    };
  },
  mounted: function mounted() {
    var _this = this;

    // Close modal with 'esc' key
    document.addEventListener("keydown", function (e) {
      if (e.keyCode == 27) {
        _this.closePopup();
      }
    });
  },
  methods: {
    formatMessage: formatMessage,
    beforeOpen: function beforeOpen(e) {
      this.isUpdate = false;
      this.startVue = 'manage';
      this.isSelf = false;
      this.userInfo.pwd = ''; //비번

      this.userInfo.repwd = '';
      this.userInfo.userNm = ''; //이름

      this.userInfo.userTypeCd = ''; //유형

      this.userInfo.orgSersNum = 0; //소속

      this.userInfo.empNum = '', //사번
      this.userInfo.telNum = ''; //전화번호

      this.userInfo.clphNum = ''; //휴대폰번호

      this.userInfo.comNm = ''; //회사명

      this.userInfo.bsnpRegNum = '';
      this.userInfo.emlAddr = ''; //이메일

      this.userInfo.alwdIpBand = ''; //ip대역

      this.userInfo.pwdChngCycl = '30'; //변경주기

      this.userInfo.userStatCd = '';
      this.userInfo.rgsrId = '';
      this.isUpdate = e.params.isUpdate;

      if (!this.isUpdate) {
        this.userInfo.userStatCd = '001'; //상태
      }

      this.userInfo.authList = [];
      this.userInfo.userId = e.params.userId;
      this.startVue = e.params.startVue;
      this.isSelf = e.params.isSelf; // this.maskYn = e.params.maskYn
    },
    opened: function opened(e) {
      var popLeft = ($(window).width() - $('#pop_up').outerWidth()) / 2;
      var popTop = ($(window).height() - $('#pop_up').outerHeight()) / 2;
      if (popLeft < 0) popLeft = 0;
      if (popTop < 0) popTop = 0;
      $('#pop_up').css({
        "left": popLeft,
        "top": popTop
      }).fadeIn().addClass('on');
      $(".mscroll").mCustomScrollbar({
        theme: "dark-thick",
        scrollInertia: 600
      }); // var searchData= {
      //   orgNm: "",
      //   useYn: true,
      //   orgTypeCd: ""
      // }
      // Vue.httpClient
      // .post('/api/appmgmt/system/org/public/list', searchData, {data : {isLoadingHide : 'Y'}})
      // .then((response) => {
      //   if (response.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
      //       // this.orgData = new DataSource({
      //       //   store: response.data.result,
      //       //   key: 'orgNm',
      //       //   group: 'hrnkOrgNm'
      //       // })
      //       this.orgData = response.data.result
      //   }
      // })
    },
    closed: function closed() {
      $('#pop_up').fadeOut().removeClass('on');
    },
    search: function search() {
      var _this2 = this;

      var url = '/user/pwSearch';

      if (this.$refs['validationGroup'].instance.validate().isValid) {
        // pw 조회
        window.$Alert.confirm(formatMessage('userPwSearchMsg')).then(function (result) {
          if (!result) {
            return;
          }

          Vue.httpClient.post(url, _this2.userInfo).then(function (result) {
            if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
              if (result.data.result.sendFlag == false) {
                window.$Alert.alert("회원정보를 확인하세요.");
              } else {
                _this2.$emit('popup-complete', {
                  startVue: _this2.startVue
                });

                _this2.closed();

                _this2.$modal.hide('pwFindPopup');

                window.$Alert.alert("회원님의 비밀번호를 초기화하였습니다.<br>담당자 이메일로 발송된 비밀번호를 확인해주세요.");
              }
            }
          });
        });
      } else {
        var brokenRules = this.$refs['validationGroup'].instance.validate().brokenRules;
        window.$Alert.alert(brokenRules[0].message);
      }
    },
    onOrgValueChanged: function onOrgValueChanged(e) {
      this.userInfo.orgSersNum = e;
    },
    closePopup: function closePopup() {
      this.closed();
      this.$modal.hide('pwFindPopup');
    },
    onValueChanged: function onValueChanged(e) {
      this.userInfo.authList = e.value;
    },
    checkId: function checkId() {
      var _this3 = this;

      var searchData = {
        userId: this.userInfo.userId
      };
      Vue.httpClient.post('/user/checkId', searchData, {
        data: {
          isLoadingHide: 'Y'
        }
      }).then(function (result) {
        if (result.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
          window.$Alert.alert(result.data.result.result);

          if (result.data.result.result == 0) {
            window.$Alert.alert(formatMessage('idCheckMsg'));
            _this3.idCheck = true;
          } else {
            window.$Alert.alert(formatMessage('idCheckDupMsg'));
            _this3.idCheck = false;
          }
        } else if (result.data.result.status == AppConfig.DUP_PK) {
          window.$Alert.alert(formatMessage('idCheckDupMsg'));
          _this3.idCheck = false;
        }
      });
    },
    passwordComparison: function passwordComparison() {
      return this.userInfo.pwd;
    }
  }
};