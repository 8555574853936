import _defineProperty from "C:/del-cert/del-cert/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _en;

var dictionary = {
  en: (_en = {
    systemId: 'MINTIT',
    prodListTitle: 'Product List',
    prodId: 'Product ID',
    prodName: 'Product Name',
    spec: 'Specification',
    prodPrice: 'Price',
    regDate: 'Registration Date',
    commCodeListTitle: 'Common Code List',
    placeholder: 'Please enter',
    selectText: 'Select',
    userId: 'UserId',
    userNm: 'UserNm',
    userTypeCd: 'UserTypeCd',
    orgId: 'OrgId',
    orgNm: 'OrgName',
    empNum: 'EmpNum',
    telNum: 'TelNum',
    clphNum: 'MobilePhoneNumber',
    emlAddr: 'EmlAddr',
    useYn: 'UseYn',
    menuIdcYn: 'MenuIdcYn',
    moblMenuYn: 'MoblMenuYn',
    userStatCd: 'UserStatCd',
    bfUserStatCd: 'BfUserStatCd',
    chngUserStatCd: 'ChngUserStatCd',
    userHistory: 'UserHistory',
    lgnFailNmts: 'LgnFailNmts',
    rcntLgnDt: 'RcntLgnDt',
    initPwdChngYn: 'InitPwdChngYn',
    initPwdChngDt: 'InitPwdChngDt',
    chngDt: 'ChngDt',
    rgsrId: 'RgsrId',
    regDt: 'RegDt',
    upsrId: 'UpsrId',
    updtDt: 'UpdtDt',
    receptionChannel: 'Reception Channel',
    paymentType: 'Payment Type',
    calculate: 'Calculate',
    state: 'State',
    deliveryNum: 'Delivery Number',
    QRRecognition: 'QR Recognition',
    QRRecognitionORG: 'QR Recognition ORG',
    deliveryDt: 'Delivery Date',
    name: 'Name',
    buyer: 'Buyer',
    prodGroup: 'Product Group',
    deliveryCount: 'Delivery Count',
    storageBoxNum: 'Storage Box Number',
    modelId: 'Model ID',
    modelName: 'Model Name',
    controlModelName: 'Control Model Name',
    petName: 'Pet Name',
    volume: 'Volumn',
    serialNum: 'Serial Number',
    imei: 'IMEI',
    imeiCnt: 'IMEI Count',
    previousSalesNum: 'Previous Sales Number',
    color: 'Color',
    mobileCarrier: 'Mobile Carrier',
    rank: 'Rank',
    deduction: 'Deduction',
    controlDeduction: 'Control Deduction',
    os: 'OS',
    batteryCapacity: 'Battery Capacity',
    batteryPerformance: 'Battery Performance',
    chargeCount: 'Charge Count',
    reaperPeriod: 'Reaper Period',
    dataDeletionDt: 'Data Deletion Date',
    loss: 'Loss',
    icloud: 'ICLOUD',
    controlFeatures: 'Control Features',
    operFeatures: 'Operation Features',
    evalCenterFeatures: 'Eval Center Features',
    barcodeFeatures: 'Barcode Features',
    deliveryCheckDt: 'Delivery Check Date',
    deliveryChecker: 'Delivery Checker',
    proposeDt: 'Propose Date',
    prod: 'Product',
    autoPrint: 'Auto Print',
    model: 'Model',
    search: 'Search',
    exportExcel: 'Export Excel',
    save: 'Save',
    update: 'Update',
    close: 'Close',
    change: 'Change',
    confirmReceive: 'Confirm Receive',
    viewLarger: 'View Larger',
    processingSuccess: 'processing Success',
    auth: 'Authority',
    authDtl: 'AuthDtl',
    authList: 'AuthList',
    authId: 'AuthId',
    authNm: 'AuthNm',
    authEngNm: 'AuthEngNm',
    authDsc: 'AuthDsc',
    authDvsnNm: 'AuthDvsnNm',
    authDvsnCd: 'AuthDvsnCd',
    butnNm: 'ButnNm',
    butnId: 'ButnId',
    menuManagement: 'MenuManagement',
    menuBtnManagement: 'MenuBtnManagement',
    menuNm: 'MenuNm',
    menuEngNm: 'menuEngNm',
    sortSqnc: 'sortSqnc',
    menuUrlAddr: 'menuUrlAddr',
    password: 'Password',
    nowPassword: 'NowPassword',
    pwdMsg: 'Please enter a password.',
    telNumMsg: 'Please enter a tel number.',
    confirmationPassword: 'ConfirmationPassword',
    rePwdMsg: 'Please enter your confirmation password.',
    checkRePwdMsg: 'Password and confirmation password are different.',
    userTypeMsg: 'User type is required.',
    organization: 'Organization',
    employeeNumber: 'Employee Number',
    empNumMsg: 'Employee Number is required.',
    email: 'Email',
    emailMsg: 'Email is required.',
    emailRuleMsg: 'not in Email format',
    ipBand: 'IpBand',
    pwdChngCycl: 'Password change deadline',
    placeholderTag: 'Choose Item',
    duplicateCheck: 'duplicate check',
    mobileNumMsg: 'Mobile Phone Number is required.',
    userNmMsg: 'User Name is required.',
    noUserSelected: 'No user selected',
    pwdPattern: 'Must contain uppercase and lowercase letters, numbers, and special characters.',
    stringLen: 'It must be between 8 and 20 characters.',
    inputCtts: 'Please enter is content',

    /* Email */
    sdngEmlAddr: 'Sender Mail Address',

    /* Email */
    viewImage: 'View Image',
    group: 'Group',
    cellPhone: 'Cell Phone',
    customerCourier: 'Customer Courier',
    customerInvoiceNum: 'Customer Invoice Number',
    appInspectionDt: 'App Inspection Date',
    appInspectionChecker: 'App Inspection Checker',
    inspectionDt: 'Inspection Date',
    inspectionChecker: 'Inspection Checker',
    printQR: 'Print QR Code',
    printBarcode: 'Print Barcode',
    inspectionComplete: 'Inspection Complete',
    cpuPetName: 'CPU Pet Name',
    screenSize: 'Screen Size',
    resolution: 'Resolution',
    adapters: 'Adapters',
    manageNum: 'Management Number',
    feature: 'Feature',
    remark: 'Remark',
    purchaseDt: 'Purchase Date',
    processing: 'Processing',
    date: 'Date',
    statement: 'Statement',
    existLocation: 'Existing Location',
    movingLocation: 'Moving Location',
    client: 'Client',
    actualPurchasePrice: 'Actual Purchase Price',
    actualSellingPrice: 'Actual Selling Price',
    materialCost: 'Material Cost',
    alterManageNum: 'Alternative Management Number',
    deliveryCompany: 'Delivery Company',
    invoiceNum: 'Invoice Number',
    reclDt: 'Recall Date',
    urlPath: 'URL PATH',
    exeDt: 'EXE DATE',
    rpnsTime: 'Res Time(sec)',
    rpnsCttsSize: 'Res Size',
    indvInfoUseYn: 'IndvInfo Use Yn',
    cnctIpAddr: 'Cnct Ip Addr',
    msknYn: 'mskgYn',
    tkchLogiCntrCd: 'LogisticsCntr',
    changeUserStatus: 'User Status Change',
    resetPassword: 'Reset Password',
    resetLoginFailCount: 'Reset Login FailCount',
    cmnsCdGrpId: 'cmnsCdGrpId',
    cmnsCdGrpNm: 'cmnsCdGrpNm',
    cmnsCdGrpEngNm: 'cmnsCdGrpEngNm',
    cmnsCdGrpDsc: '코cmnsCdGrpDsc',
    cmnsCd: 'cmnsCd',
    cmnsCdNm: 'cmnsCdNm',
    cmnsCdEngNm: 'cmnsCdEngNm'
  }, _defineProperty(_en, "sortSqnc", 'sortSqnc'), _defineProperty(_en, "cmnsCdDsc", 'cmnsCdDsc'), _defineProperty(_en, "rferGrpId", 'rferGrpId'), _defineProperty(_en, "rferGrpId1", 'rferGrpId1'), _defineProperty(_en, "rferGrpId2", 'rferGrpId2'), _defineProperty(_en, "rferGrpId3", 'rferGrpId3'), _defineProperty(_en, "rferGrpId4", 'rferGrpId4'), _defineProperty(_en, "rferGrpId5", 'rferGrpId5'), _defineProperty(_en, "rferGrpId6", 'rferGrpId6'), _defineProperty(_en, "rferGrpId7", 'rferGrpId7'), _defineProperty(_en, "rferGrpId8", 'rferGrpId8'), _en)
};
export default {
  getDictionary: function getDictionary() {
    return dictionary;
  }
};