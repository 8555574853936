import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import TopMenu from '@/components/TopMenu';
import SideMenu from '@/components/SideMenu';
import FooterView from '@/components/FooterView';
import { EventBus } from "@/modules/event-bus";
import localedata from "./locale/bos-locale";
import bosdictko from "./locale/bos-dict-ko";
import bosdicten from "./locale/bos-dict-en";
import appmgmtko from './locale/appmgmt/appmgmt-dict-ko';
import appmgmten from './locale/appmgmt/appmgmt-dict-en';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
export default {
  name: 'App',
  components: {
    TopMenu: TopMenu,
    FooterView: FooterView,
    SideMenu: SideMenu
  },
  data: function data() {
    return {
      title: 'mintit',
      isReaction: false,
      loginCheckTimer: null
    };
  },
  computed: {
    cachedComponentList: function cachedComponentList() {
      return window.$store.getters['LatestMenu/getCachedComponentList'];
    }
  },
  updated: function updated() {
    this.$nextTick(function () {
      var $gridArea = $('.grid_area > div:last-child'),
          $tabArea = $('.sub_content').find('.tab_menu_list').hasClass('tab_menu_list'),
          $shArea = $('.sub_content').find('.search_area').hasClass('search_area'),
          $auiArea = $('.aui-grid').parent('div');

      if ($tabArea === true) {
        $gridArea.removeClass('h500');
        $auiArea.removeClass('h500');
      } else if ($tabArea === false) {
        $gridArea.addClass('h500');
        $auiArea.addClass('h500');
      }

      if ($shArea === true) {
        $gridArea.removeClass('h600');
      } else if ($shArea === false) {
        $gridArea.addClass('h600');
      }

      if ($tabArea === false && $shArea === false) {
        $gridArea.removeClass('h500');
      }
    });
  },
  mounted: function mounted() {
    var _this = this;

    // title 처리
    setInterval(function () {
      return _this.title = 'mintit - ' + _this.$router.history.current.meta.desc;
    }, 1000);
  },
  created: function created() {
    var _this2 = this;

    this.initMessages();
    locale(localedata.getLocale()); // 사용자 action

    EventBus.$on("event-reaction", function () {
      _this2.isReaction = true;

      if (_this2.loginCheckTimer == null) {
        _this2.loginCheckTimer = setInterval(_this2.refreshLogin, 7200000); // 2시간
      }
    }); // 로그아웃

    EventBus.$on("event-logout", function () {
      _this2.isReaction = false;
      clearInterval(_this2.loginCheckTimer);
      _this2.loginCheckTimer = null;
    });
  },
  methods: {
    initMessages: function initMessages() {
      loadMessages(bosdictko.getDictionary());
      loadMessages(appmgmtko.getDictionary());
      loadMessages(appmgmten.getDictionary());
      loadMessages(bosdicten.getDictionary());
    },
    refreshLogin: function refreshLogin() {
      var _this3 = this;

      if (this.isReaction) {
        window.$store.dispatch('Login/refreshLogin').then(function (result) {
          _this3.isReaction = false;

          if (!result) {
            _this3.logout();
          }
        });
      } else {
        this.logout();
      }
    },
    logout: function logout() {
      var _this4 = this;

      window.$store.dispatch('Login/logout').then(function () {
        _this4.$router.push('/');
      });
    }
  }
};