import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import Login from '@/stores/modules/Login';
import TopMenu from '@/stores/modules/TopMenu';
import LatestMenu from '@/stores/modules/LatestMenu';
Vue.use(Vuex);
var vuexStorage = new VuexPersistence({
  storage: window.localStorage
});
var store = new Vuex.Store({
  modules: {
    Login: Login,
    TopMenu: TopMenu,
    LatestMenu: LatestMenu
  },
  plugins: [vuexStorage.plugin]
});
export default store;