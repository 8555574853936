import Vue from 'vue';
import Login from '@/views/Login';
import Home from '@/views/Home'; // import PurchaseMgmt from '@/views/vue/default/purchase/PurchaseMgmt'
//import SalesLogin from '@/views/vue/sales/sales/SalesLogin'
//import Test from '@/views/Test'

export default [{
  path: '/',
  name: 'login',
  component: Login,
  meta: {
    authRequired: false,
    desc: '로그인'
  }
} //{ path: '/home',                                                    name: 'home',                           component: Home,                            meta: { authRequired: true,		  desc: 'Home' } },

/*{ path: '/home',                                                    name: 'home',                           component: PurchaseMgmt,                            meta: { authRequired: true,		  desc: '매입관리' } },*/
// { path: '/sales/login',                                             name: 'salesLogin',                     component: SalesLogin,                      meta: { authRequired: false,    desc: '로그인'} },
];