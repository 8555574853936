var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "popup_mask", attrs: { id: "alertMask" } }),
    _c("div", { staticClass: "popup", attrs: { id: "alertPop" } }, [
      _c("div", { staticClass: "alert_popup" }, [
        _c("div", { staticClass: "alert_wrap" }, [
          _c("div", { staticClass: "message" }, [
            _vm._m(0),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }),
          ]),
          _c("div", { staticClass: "alert_btn" }, [
            _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.confirm } },
              [_vm._v("확인")]
            ),
            _vm.isConfirm
              ? _c(
                  "button",
                  {
                    staticClass: "cancel",
                    attrs: { type: "button" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("취소")]
                )
              : _vm._e(),
          ]),
          _c("button", { staticClass: "close", on: { click: _vm.cancel } }, [
            _c("i", { staticClass: "xi-close-min" }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("i", { staticClass: "xi-error" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }