var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sub_content" }, [
    _c("div", { staticClass: "search_area" }, [
      _c("table", { staticClass: "sh_table" }, [
        _c("caption", [_vm._v("조회영역")]),
        _vm._m(0),
        _c("tbody", [
          _c("tr", [
            _c("th", [_vm._v("삭제일")]),
            _c("td", [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("DxDateBox", {
                    staticClass: "date mar_l5",
                    attrs: {
                      value: _vm.searchData.startComDateByPeriod,
                      max: _vm.searchData.endComDateByPeriod,
                      "display-format": "yyyy-MM-dd",
                      "styling-mode": "outlined",
                      placeholder: "from",
                    },
                    on: { "value-Changed": _vm.onChangeSt },
                  }),
                  _c("DxDateBox", {
                    staticClass: "date mar_l5",
                    attrs: {
                      value: _vm.searchData.endComDateByPeriod,
                      min: _vm.searchData.startComDateByPeriod,
                      "display-format": "yyyy-MM-dd",
                      "styling-mode": "outlined",
                      placeholder: "to",
                    },
                    on: { "value-Changed": _vm.onChangeEn },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "td",
              { staticClass: "taR  pad_t5 pad_b5", attrs: { colspan: "6" } },
              [
                _c(
                  "button",
                  { staticClass: "btn bg_blue", on: { click: _vm.search } },
                  [_vm._v("조회")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn bg_green",
                    on: { click: _vm.onDownload },
                  },
                  [_vm._v(_vm._s(_vm.formatMessage("exportExcel")))]
                ),
                _c(
                  "button",
                  { staticClass: "btn bg_gray", on: { click: _vm.reset } },
                  [_vm._v("초기화")]
                ),
                _vm.adminYn
                  ? _c(
                      "button",
                      {
                        staticClass: "btn bg_gray",
                        on: { click: _vm.handleHistoryBack },
                      },
                      [_c("i", { staticClass: "xi-apps" })]
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "grid_wrap hauto" }, [
      _c(
        "div",
        { staticClass: "grid_area" },
        [
          _c(
            "DxDataGrid",
            {
              ref: "grid",
              attrs: {
                "data-source": _vm.datasource,
                "show-borders": true,
                "remote-operations": true,
                "select-text-on-edit-start": false,
                selection: { mode: "single" },
                "column-auto-width": true,
                "allow-column-resizing": true,
                "allow-column-reordering": true,
                height: 565,
              },
              on: { "toolbar-preparing": _vm.onToolbarPreparing },
            },
            [
              _c("DxColumn", {
                attrs: {
                  "data-field": "userNm",
                  "data-type": "string",
                  alignment: "center",
                  width: 160,
                  caption: _vm.formatMessage("userNm"),
                },
              }),
              _c("DxColumn", {
                attrs: {
                  "data-field": "finishDt",
                  "data-type": "datetime",
                  alignment: "center",
                  width: 160,
                  caption: _vm.formatMessage("delDt"),
                  format: "yyyy-MM-dd",
                },
              }),
              _c("DxColumn", {
                attrs: {
                  "data-field": "totalCnt",
                  format: "#,##0",
                  "data-type": "number",
                  alignment: "right",
                  width: 160,
                  caption: _vm.formatMessage("ttlCnt"),
                },
              }),
              _c("DxColumn", {
                attrs: {
                  "data-field": "androidCnt",
                  format: "#,##0",
                  "data-type": "number",
                  alignment: "right",
                  width: 160,
                  caption: _vm.formatMessage("android"),
                },
              }),
              _c("DxColumn", {
                attrs: {
                  "data-field": "iosCnt",
                  format: "#,##0",
                  "data-type": "number",
                  alignment: "right",
                  width: 160,
                  caption: _vm.formatMessage("ios"),
                },
              }),
              _c("DxColumn", {
                attrs: {
                  "data-field": "pcCnt",
                  format: "#,##0",
                  "data-type": "number",
                  alignment: "right",
                  width: 160,
                  caption: _vm.formatMessage("pc"),
                },
              }),
              _c("DxColumn", {
                attrs: {
                  "data-field": "feeAmt",
                  format: "#,##0",
                  "data-type": "number",
                  alignment: "right",
                  width: 160,
                  caption: _vm.formatMessage("billingCost"),
                  "allow-editing": false,
                },
              }),
              _c("DxPaging", { attrs: { "page-size": 20 } }),
              _c("DxPager", {
                attrs: {
                  visible: true,
                  "allowed-page-sizes": _vm.pageSizes,
                  "display-mode": _vm.displayMode,
                  "show-page-size-selector": true,
                  "show-info": true,
                  "show-navigation-buttons": true,
                },
              }),
              _c("DxEditing", {
                attrs: {
                  "allow-updating": false,
                  "allow-adding": false,
                  "allow-deleting": false,
                  mode: "batch",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticClass: "cellw6" }),
      _c("col"),
      _c("col", { staticClass: "cellw6" }),
      _c("col", { staticClass: "cellw6" }),
      _c("col", { staticClass: "cellw6" }),
      _c("col", { staticClass: "cellw6" }),
      _c("col", { staticClass: "cellw6" }),
      _c("col", { staticClass: "cellw6" }),
      _c("col", { staticClass: "cellw6" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }