import _defineProperty from "C:/del-cert/del-cert/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _ko;

var dictionary = {
  ko: (_ko = {
    systemId: '민팃',
    prodListTitle: '제품 목록',
    prodId: '제품 ID',
    prodName: '제품 이름',
    spec: '제품 사양',
    prodPrice: '제품 가격',
    regDate: '등록일',
    commCodeListTitle: '공통 코드 목록',
    placeholder: '입력하세요...',
    selectText: '조회',
    userId: '사용자ID',
    userNm: '사용자이름',
    userTypeCd: '사용자유형',
    orgId: '조직ID',
    orgNm: '조직명',
    empNum: '사번',
    telNum: '전화번호',
    clphNum: '휴대폰번호',
    emlAddr: '이메일',
    useYn: '사용여부',
    menuIdcYn: '메뉴여부',
    moblMenuYn: '모바일메뉴여부',
    userStatCd: '사용자상태',
    bfUserStatCd: '변경 전 상태',
    chngUserStatCd: '변경 후 상태',
    userHistory: '변경이력',
    lgnFailNmts: '로그인실패횟수',
    rcntLgnDt: '최근로그인',
    initPwdChngYn: '패스워드 변경여부',
    initPwdChngDt: '패스워드 변경일',
    chngDt: '변경일',
    rgsrId: '등록자',
    regDt: '등록일',
    upsrId: '수정자',
    updtDt: '수정일',
    receptionChannel: '접수채널',
    paymentType: '결제유형',
    calculate: '정산',
    state: '상태',
    deliveryNum: '입고번호',
    QRRecognition: 'QR인식',
    QRRecognitionORG: 'QR인식ORG',
    deliveryDt: '입고일자',
    name: '이름',
    buyer: '매입처',
    prodGroup: '제품군',
    deliveryCount: '입고수량',
    storageBoxNum: '보관박스번호',
    modelId: '모델ID',
    modelName: '모델명',
    controlModelName: '관제 모델명',
    petName: '펫네임',
    volume: '용량',
    serialNum: '일련번호',
    imei: 'IMEI',
    imeiCnt: 'IMEI Count',
    previousSalesNum: '이전판매 관리번호',
    color: '색상',
    mobileCarrier: '통신사',
    rank: '등급',
    deduction: '차감항목',
    controlDeduction: '관제 차감항목',
    os: 'OS',
    batteryCapacity: '배터리용량',
    batteryPerformance: '배터리성능',
    chargeCount: '충전횟수',
    reaperPeriod: '리퍼기간',
    dataDeletionDt: '데이터삭제일시',
    loss: '분실여부',
    icloud: 'ICLOUD',
    controlFeatures: '관제 특이사항',
    operFeatures: '운영 특이사항',
    evalCenterFeatures: '평가센터 특이사항',
    barcodeFeatures: '바코드 특이사항',
    deliveryCheckDt: '입고 확인일시',
    deliveryChecker: '입고 확인자',
    proposeDt: '신청일',
    prod: '제품',
    autoPrint: '자동 출력',
    model: '모델',
    search: '조회',
    exportExcel: '엑셀 출력',
    save: '저장',
    confirm: '확인',
    update: '수정',
    close: '닫기',
    change: '변경',
    confirmReceive: '입고 확인',
    viewLarger: '크게보기',
    processingSuccess: '요청하신 작업이 완료되었습니다.',
    auth: '권한',
    authDtl: '권한 상세',
    authList: '권한 목록',
    authId: '권한ID',
    authNm: '권한명',
    authEngNm: '권한영문명',
    authDsc: '권한설명',
    authDvsnNm: '권한구분',
    authDvsnCd: '권한구분코드',
    butnNm: '버튼명',
    butnId: '버튼ID',
    menuManagement: '메뉴(화면) 관리',
    menuBtnManagement: '화면 버튼 관리',
    menuNm: '메뉴명',
    menuEngNm: '메뉴영문명',
    sortSqnc: '메뉴순서',
    menuUrlAddr: '메뉴URL',
    password: '비밀번호',
    nowPassword: '현재 비밀번호',
    pwdMsg: '비밀번호를 입력해주세요',
    telNumMsg: '전화번호를 입력해주세요',
    confirmationPassword: '확인 비밀번호',
    rePwdMsg: '확인 비밀번호를 입력해주세요.',
    checkRePwdMsg: '비밀번호와 확인비밀번호가 다릅니다.',
    userTypeMsg: '사용자유형항목이 필요합니다.',
    bsnpRegCertificateMsg: '사업자등록사본이 필요합니다',
    organization: '조직',
    employeeNumber: '사번',
    empNumMsg: '사번항목이 필요합니다.',
    email: 'Email',
    emailMsg: 'Email항목이 필요합니다.',
    emailRuleMsg: 'Email형식이 아닙니다.',
    ipBand: '허용IP대역',
    pwdChngCycl: '비밀번호변경 기한',
    placeholderTag: '선택하세요',
    duplicateCheck: '중복체크',
    mobileNumMsg: '휴대폰번호 항목이 필요합니다.',
    userNmMsg: '이름항목이 필요합니다.',
    psicNmMsg: '담당자명항목이 필요합니다.',
    rstvNmMsg: '대표자명항목이 필요합니다.',
    comNmMsg: '회사명항목이 필요합니다.',
    noUserSelected: '선택된 사용자가 없습니다.',
    pwdPattern: '영문 대문자,소문자,숫자,특수기호를 모두 포함해야 합니다.',
    stringLen: '8글자 이상 20글자 이하여야 합니다.',
    inputCtts: '내용을 입력해주세요',

    /* Email */
    sdngEmlAddr: '발송자 메일 주소',

    /* Email */
    viewImage: '사진보기',
    group: '그룹',
    cellPhone: '휴대폰',
    customerCourier: '고객 택배사',
    customerInvoiceNum: '고객 운송장 번호',
    appInspectionDt: 'App 검수일시',
    appInspectionChecker: 'App 검수자',
    inspectionDt: '검수일시',
    inspectionChecker: '검수자',
    printQR: 'QR 코드출력',
    printBarcode: '바코드 출력',
    inspectionComplete: '검수 완료',
    cpuPetName: 'CPU 펫네임',
    screenSize: '화면 사이즈',
    resolution: '해상도',
    adapters: '아답터',
    manageNum: '관리번호',
    feature: '특이사항',
    remark: '비고',
    purchaseDt: '매입일자',
    processing: '처리',
    date: '일자',
    statement: '전표',
    existLocation: '기존위치',
    movingLocation: '이동위치',
    client: '거래처',
    actualPurchasePrice: '실매입가',
    actualSellingPrice: '실판매가',
    materialCost: '자재비용',
    alterManageNum: '대체관리번호',
    deliveryCompany: '택배사',
    invoiceNum: '운송장 번호',
    urlPath: 'URL PATH',
    exeDt: '실행일시',
    rpnsTime: '응답시간(초)',
    rpnsCttsSize: '응답전문크기',
    indvInfoUseYn: '개인정보처리여부',
    cnctIpAddr: '접속IP',
    msknYn: '마스킹여부',
    tkchLogiCntrCd: '소속물류센터',
    changeUserStatus: '사용자 상태를 변경하시겠습니까?',
    resetPassword: '비밀번호를 초기화 하시겠습니까?',
    resetLoginFailCount: '로그인 실패횟수를 초기화 하시겠습니까?',
    cmnsCdGrpId: '코드그룹ID',
    cmnsCdGrpNm: '코드그룹명',
    cmnsCdGrpEngNm: '코드그룹 영문명',
    cmnsCdGrpDsc: '코드그룹 설명',
    cmnsCd: '코드',
    cmnsCdNm: '코드명',
    cmnsCdEngNm: '코드영문명'
  }, _defineProperty(_ko, "sortSqnc", '순서'), _defineProperty(_ko, "cmnsCdDsc", '코드 설명'), _defineProperty(_ko, "rferGrpId", '참조자그룹아이디'), _defineProperty(_ko, "rferGrpId1", '참조자그룹아이디1'), _defineProperty(_ko, "rferGrpId2", '참조자그룹아이디2'), _defineProperty(_ko, "rferGrpId3", '참조자그룹아이디3'), _defineProperty(_ko, "rferGrpId4", '참조자그룹아이디4'), _defineProperty(_ko, "rferGrpId5", '참조자그룹아이디5'), _defineProperty(_ko, "rferGrpId6", '참조자그룹아이디6'), _defineProperty(_ko, "rferGrpId7", '참조자그룹아이디7'), _defineProperty(_ko, "rferGrpId8", '참조자그룹아이디8'), _defineProperty(_ko, "cmm_excel_download_001", '엑셀 다운로드 하시겠습니까?'), _defineProperty(_ko, "cmm_excel_download_002", '데이터 정제 중...'), _defineProperty(_ko, "cmm_excel_download_003", '데이터 조회 중... {0} %'), _defineProperty(_ko, "cmm_excel_download_004", '엑셀 생성 중...'), _ko)
};
export default {
  getDictionary: function getDictionary() {
    return dictionary;
  }
};