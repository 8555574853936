//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectCodeList from '@/components/DxSelectCodeList';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import DxTagBox from 'devextreme-vue/tag-box';
import DxSelectBox from 'devextreme-vue/select-box';
import AppConfig from '@/config';
import { DxDataGrid, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';
export default {
  name: 'diskListPopup',
  components: {
    DxDataGrid: DxDataGrid,
    DxColumn: DxColumn,
    DxScrolling: DxScrolling
  },
  data: function data() {
    return {
      searchData: {
        ordNumber: ""
      },
      diskList: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    // Close modal with 'esc' key
    document.addEventListener("keydown", function (e) {
      if (e.keyCode == 27) {
        _this.closePopup();
      }
    });
  },
  methods: {
    formatMessage: formatMessage,
    beforeOpen: function beforeOpen(e) {
      this.searchData.ordNumber = e.params.ordNumber;
      this.searchData.memId = e.params.memId;
    },
    opened: function opened(e) {
      var _this2 = this;

      var popLeft = ($(window).width() - $('#pop_up').outerWidth()) / 2;
      var popTop = ($(window).height() - $('#pop_up').outerHeight()) / 2;
      if (popLeft < 0) popLeft = 0;
      if (popTop < 0) popTop = 0;
      $('#pop_up').css({
        "left": popLeft,
        "top": popTop
      }).fadeIn().addClass('on');
      $(".mscroll").mCustomScrollbar({
        theme: "dark-thick",
        scrollInertia: 600
      });
      return Vue.httpClient.post('/erasure/getDiskList', this.searchData).then(function (response) {
        if (response.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
          _this2.diskList = response.data.result;
        }
      });
    },
    closePopup: function closePopup() {
      this.closed();
      this.$modal.hide('diskListPopup');
    },
    closed: function closed() {
      $('#pop_up').fadeOut().removeClass('on');
    }
  }
};