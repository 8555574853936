'use strict'; // object 초기화

import _typeof from "C:/del-cert/del-cert/frontend/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/web.dom-collections.for-each.js";

var initObject = function initObject(object, deep) {
  var self = this;
  self.obj = object;

  for (var key in object) {
    switch (_typeof(object[key])) {
      case 'number':
        // 정수
        self.obj[key] = 0;
        break;

      case 'boolean':
        // boolean
        self.obj[key] = false;
        break;

      case 'array':
        // array
        self.obj[key] = [];
        break;

      case 'array':
        // array
        self.obj[key] = [];
        break;

      case 'object':
        // object
        if (deep) {
          initObject(self.obj[key]);
        } else {
          self.obj[key] = {};
        }

        break;

      default:
        // 문자
        self.obj[key] = '';
    }
  }
}; // 숫자 3자리 콤마


var numberComma = function numberComma(value) {
  var len, point, str;

  if (value) {
    var isMinus = value < 0;
    value = Math.abs(value) + '';
    var pointValue = '';

    if (value.indexOf('.') > -1) {
      var arrValue = value.split('.');
      value = arrValue[0];
      pointValue = '.' + arrValue[1];
    }

    point = value.length % 3;
    len = value.length;
    str = value.substring(0, point);

    while (point < len) {
      if (str != '') str += ',';
      str += value.substring(point, point + 3);
      point += 3;
    }

    if (pointValue != '') {
      str += pointValue;
    }

    if (isMinus) {
      str = '-' + str;
    }
  } else {
    str = value;
  }

  return str;
}; // 오늘 날짜 yyyy-MM-dd


var getToday = function getToday() {
  var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}; // 오늘 날짜 yyyy-MM


var getThisMonth = function getThisMonth() {
  var d = new Date(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  return [year, month].join('-');
}; // 오늘 월의 1일 yyyy-MM-dd


var getFirstDay = function getFirstDay() {
  var d = new Date();
  var month = '' + (d.getMonth() + 1);
  var day = '01';
  var year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  return [year, month, day].join('-');
}; // 오늘 부터 -month 월 전 날짜 yyyy-MM-dd


var getDate = function getDate(month) {
  var d = new Date();
  var monthOfYear = d.getMonth();
  d.setMonth(monthOfYear - month);
  var month = '' + (d.getMonth() + 1);
  var day = '' + d.getDate();
  var year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}; // trgtDate : 지정 날짜(yyyyMMdd 또는 yyyy-MM-dd)에서
// plusDay : 지정일 계산한 날짜를
// return : yyyy-MM-dd 형태로 리턴
// 합당하지 않은 파람시 '' 리턴


var getAddDay = function getAddDay(trgtDate, plusDay) {
  if (trgtDate == null) return '';

  if (trgtDate.indexOf('-') > -1) {
    var regexp = /[^0-9]/g;
    trgtDate = trgtDate.replace(regexp, '');
  }

  if (trgtDate.length != 8) return '';
  var newDate = new Date();
  var trgtDateTime = new Date(trgtDate.substr(0, 4), trgtDate.substr(4, 2) - 1, trgtDate.substr(6, 2)).getTime();
  var processTime = trgtDateTime + parseInt(plusDay) * 24 * 60 * 60 * 1000;
  newDate.setTime(processTime);
  var year = newDate.getFullYear();
  var month = newDate.getMonth() + 1;
  var day = newDate.getDate();
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  return [year, month, day].join('-');
}; // aDate : 날짜(yyyyMMdd 또는 yyyy-MM-dd)에서
// bDate : 날짜(yyyyMMdd 또는 yyyy-MM-dd)를 뺀 결과를
// return : ms(밀리초 : day * 24 * 60 * 60 * 1000)로 리턴
// 합당하지 않은 파람시 -1 리턴
// ex) '2019-09-09' - '2019-08-09' = 2678400000 (31일)
// ex) '2019-08-09' - '2019-09-09' = -2678400000 (-31일)


var getDiffDate = function getDiffDate(aDate, bDate) {
  console.log('getDiffDate -> aDate', aDate);
  console.log('getDiffDate -> bDate', bDate);
  if (aDate == null || bDate == null) return -1;

  if (aDate.indexOf('-') > -1) {
    var regexp = /[^0-9]/g;
    aDate = aDate.replace(regexp, '');
  }

  if (bDate.indexOf('-') > -1) {
    var regexp = /[^0-9]/g;
    bDate = bDate.replace(regexp, '');
  }

  if (aDate.length != 8 || bDate.length != 8) return -1;
  var aDateTime = new Date(aDate.substr(0, 4), aDate.substr(4, 2) - 1, aDate.substr(6, 2)).getTime();
  var bDateTime = new Date(bDate.substr(0, 4), bDate.substr(4, 2) - 1, bDate.substr(6, 2)).getTime();
  var result = aDateTime - bDateTime;
  return result;
}; // 랜덤 숫자 생성
// 날짜 yyyy-MM-dd 형식 변화


function formatDate(date) {
  if (date != undefined && date != null) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  } else {
    return '';
  }
} // 랜덤 숫자 생성


var generateRandom = function generateRandom(min, max) {
  var ranNum = Math.floor(Math.random() * (max - min + 1)) + min;
  return ranNum;
}; // 가운데 정렬 팝업


var openPopup = function openPopup(obj, popName, width, height, scrollYn, addX, addY) {
  var popupX = window.screen.width / 2 - width / 2 + (addX || 0);
  var popupY = window.screen.height / 2 - height / 2 - 50 + (addY || 0);
  var options = 'status=no, width=' + width + ', height=' + height + ', left=' + popupX + ', top=' + popupY;

  if (scrollYn == 'Y') {
    options += ', scrollbars=yes';
  } else {
    options += ', scrollbars=auto';
  }

  window.open(obj.href, popName, options);
}; // ie 여부


var isIe = function isIe() {
  var rtn = false;
  var agent = navigator.userAgent.toLowerCase();

  if (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1 || agent.indexOf('msie') != -1) {
    rtn = true;
  }

  return rtn;
}; //


var sleep = function sleep(delay) {
  var start = new Date().getTime();

  while (new Date().getTime() < start + delay) {
    ;
  }
}; //


var showLoading = function showLoading(name) {
  var html = '<div id="loading" name="loading" class="' + name + '"><div class="car"></div></div>';
  $('body').append(html);
  $('.' + name).show();
}; //


var hideLoading = function hideLoading(name) {
  $('.' + name).hide();
  $('.' + name).remove();
}; // object 배열에서 속성값 배열 추출


function getFields(input, field) {
  var output = [];

  for (var i = 0; i < input.length; ++i) {
    output.push(input[i][field]);
  }

  return output;
} // enter 제거


function replaceSpecialChar(value) {
  return (value + '').replace(/(\r\n)|(\n)|(\t)|(\s)|(\\p{Z})|(:)|(;)/gi, '');
}

function phoneFomatter(num, type) {
  var formatNum = '';

  try {
    if (num.length == 11) {
      if (type == 0) {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else if (num.length == 8) {
      formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else {
      if (num.indexOf('02') == 0) {
        if (type == 0) {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
        } else {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        }
      } else {
        if (type == 0) {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
        } else {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
      }
    }
  } catch (error) {
    return num;
  }

  return formatNum;
}

function sumDig(n) {
  var a = 0;

  while (n > 0) {
    a = a + n % 10;
    n = parseInt(n / 10, 10);
  }

  return a;
}

function validateIMEI(imei) {
  // Converting the number into
  // String for finding length
  var s = imei.toString();
  var len = s.length;

  if (len !== 15) {
    return false;
  }

  if (imei === '000000000000000') {
    return true;
  }

  var sum = 0;

  for (var i = len; i >= 1; i--) {
    var d = imei % 10; // Doubling every alternate digit

    if (i % 2 === 0) {
      d = 2 * d;
    } // Finding sum of the digits


    sum += sumDig(d);
    imei = parseInt(imei / 10, 10);
  }

  return sum % 10 === 0;
}
/**
 * 계좌 마스킹 - 뒤에서 네자리 숫자를 제외한 전체
 * @param account
 * @returns {string|*}
 */


function maskingAccount(account) {
  var result = '';

  if (!account || account.length < 4) {
    return result;
  }

  var asterisk = '';

  for (var i = 0; i < account.length - 4; i++) {
    asterisk += '*';
  }

  var pattern = /[0-9\\-]+(?=\d{4}$)/;

  if (pattern.test(account)) {
    result = account.replace(pattern, asterisk);
  } else {
    result = '**********';
  }

  return result;
}
/**
 * 핸드폰 번호 마스킹 - 식별번호, 뒤 4자리 제외한 전체
 * @param hp
 * @returns {string|*}
 */


function maskingPhone(hp) {
  var pattern = /^(\d{3})-?(\d{3,4})-?(\d{4})$/;
  var result = '';

  if (!hp) {
    return result;
  }

  if (pattern.test(hp)) {
    result = hp.replace(pattern, '$1-$2-****');
  } else {
    result = '***-****-****';
  }

  return result;
}
/**
 * native 핸드폰 번호 마스킹 - 식별번호, 뒤 4자리 제외한 전체
 * @param hp
 * @returns {string|*}
 */


function maskingNativePhone(hp) {
  var pattern = /^(\d{3})-?(\d{3,4})-?(\d{4})$/;
  var result = '';

  if (!hp) {
    return result;
  }

  if (pattern.test(hp)) {
    result = hp.replace(pattern, '$1$2****');
  } else {
    result = '***-****-****';
  }

  return result;
}
/**
 * 이름 마스킹 - 이름 중간(첫 글자와 마지막 글자 제외)
 * @param strName
 * @returns {string|*}
 */


function maskingName(strName) {
  var result = '';

  if (!strName) {
    return result;
  }

  if (strName.length > 2) {
    var originName = strName.split('');
    originName.forEach(function (name, i) {
      if (i === 0 || i === originName.length - 1) return;
      originName[i] = '*';
    });
    var joinName = originName.join();
    result = joinName.replace(/,/g, '');
  } else {
    var pattern = /.$/;
    result = strName.replace(pattern, '*');
  }

  return result;
}

function bizNoFormatter(num, type) {
  var formatNum = '';

  try {
    if (num.length == 10) {
      if (type == 0) {
        formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-*****');
      } else {
        formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
      }
    }
  } catch (e) {
    formatNum = num;
    console.log(e);
  }

  return formatNum;
}

export default {
  initObject: initObject,
  numberComma: numberComma,
  getToday: getToday,
  getThisMonth: getThisMonth,
  getFirstDay: getFirstDay,
  getDate: getDate,
  getAddDay: getAddDay,
  getDiffDate: getDiffDate,
  formatDate: formatDate,
  generateRandom: generateRandom,
  openPopup: openPopup,
  isIe: isIe,
  sleep: sleep,
  showLoading: showLoading,
  hideLoading: hideLoading,
  getFields: getFields,
  replaceSpecialChar: replaceSpecialChar,
  phoneFomatter: phoneFomatter,
  sumDig: sumDig,
  validateIMEI: validateIMEI,
  maskingAccount: maskingAccount,
  maskingPhone: maskingPhone,
  maskingNativePhone: maskingNativePhone,
  maskingName: maskingName,
  bizNoFormatter: bizNoFormatter
};