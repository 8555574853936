import _toConsumableArray from "C:/del-cert/del-cert/frontend/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import Vue from 'vue';
import Router from 'vue-router';
import common from './common/index'; // import purchase from './purchase/index'
//import notice from './notice/index'

import delCert from './delCert/index';
Vue.use(Router);
export default new Router({
  base: '/',
  routes: [].concat(_toConsumableArray(common), _toConsumableArray(delCert))
});