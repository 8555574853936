import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
export default {
  function: function _function() {
    $('.header_sh_input').on('focusin', function () {
      $('.sh_result').addClass('active');
      $(this).parent().siblings('li').find('ul').removeClass('active');
    }).on('focusout', function () {
      $('.sh_result').removeClass('active');
    });
    var $utilLi = $('.util > li:not(.search,.logout)');
    $utilLi.on('click', function () {
      $(this).find('ul').toggleClass('active');
      $(this).siblings('li').find('ul').removeClass('active');
    });
    $(window).resize(function () {
      var $winHeight = $(window).height();

      if ($winHeight < 937) {
        $('footer .footer').addClass('active');
      } else if ($conHeight > 850) {
        $('footer .footer').addClass('active');
      } else {
        $('footer .footer').removeClass('active');
      }
    });
    var $conHeight = $('.container').outerHeight();

    if ($conHeight > 850) {
      $('footer .footer').addClass('active');
    } else {
      $('footer .footer').removeClass('active');
    } // 숫자애니메이션


    $('.counter').counterUp({
      delay: 10,
      time: 1000
    });
  }
};