'use strict';

import _objectSpread from "C:/del-cert/del-cert/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
var state = {
  menuList: [],
  autoMenuList: [],
  navigation: {},
  middleMenuList: [],
  navigationMiddleList: [],
  navigationSmallList: [],
  rootMenuName: '',
  lowMenuName: '',
  changeActive: 0,
  noticeCount: 0
};
var getters = {
  getMenuList: function getMenuList(state) {
    return state.menuList;
  },
  getAutoMenuList: function getAutoMenuList(state) {
    return state.autoMenuList;
  },
  getNavigation: function getNavigation(state) {
    return state.navigation;
  },
  getMiddleMenuList: function getMiddleMenuList(state) {
    return state.middleMenuList;
  },
  getNavigationMiddleList: function getNavigationMiddleList(state) {
    return state.navigationMiddleList;
  },
  getNavigationSmallList: function getNavigationSmallList(state) {
    return state.navigationSmallList;
  },
  getRootMenuName: function getRootMenuName(state) {
    return state.rootMenuName;
  },
  getLowMenuName: function getLowMenuName(state) {
    return state.lowMenuName;
  },
  getChangeActive: function getChangeActive(state) {
    return state.changeActive;
  },
  getNoticeCount: function getNoticeCount(state) {
    return state.noticeCount;
  }
};
var mutations = {
  setMenuList: function setMenuList(state, _ref) {
    var menuList = _ref.menuList;
    state.menuList = menuList;
  },
  setAutoMenuList: function setAutoMenuList(state, _ref2) {
    var autoMenuList = _ref2.autoMenuList;
    state.autoMenuList = autoMenuList;
  },
  setNavigation: function setNavigation(state, _ref3) {
    var navigation = _ref3.navigation;
    state.navigation = navigation;
  },
  setMiddleMenuList: function setMiddleMenuList(state, _ref4) {
    var middleMenuList = _ref4.middleMenuList;
    state.middleMenuList = middleMenuList;
  },
  setNavigationMiddleList: function setNavigationMiddleList(state, _ref5) {
    var navigationMiddleList = _ref5.navigationMiddleList;
    state.navigationMiddleList = navigationMiddleList;
  },
  setNavigationSmallList: function setNavigationSmallList(state, _ref6) {
    var navigationSmallList = _ref6.navigationSmallList;
    state.navigationSmallList = navigationSmallList;
  },
  setRootMenuName: function setRootMenuName(state, _ref7) {
    var rootMenuName = _ref7.rootMenuName;
    state.rootMenuName = rootMenuName;
  },
  setLowMenuName: function setLowMenuName(state, _ref8) {
    var lowMenuName = _ref8.lowMenuName;
    state.lowMenuName = lowMenuName;
  },
  setChangeActive: function setChangeActive(state, count) {
    state.changeActive = count;
  },
  setNoticeCount: function setNoticeCount(state, noticeCount) {
    state.noticeCount = noticeCount;
  }
};
var actions = {
  setMenus: function setMenus(_ref9, menus) {
    var commit = _ref9.commit;
    commit('setMenuList', {
      menuList: menus
    });
  },
  changeActive: function changeActive(_ref10, params) {
    var commit = _ref10.commit;
    commit('setChangeActive', params);
  },
  // autoMenu ({ commit, state }, params) {
  //   return new Promise((resolve, reject) => {
  //     Vue.httpClient
  //       .post('/api/purchase', params)
  //       .then(response => {
  //         if (response.data.result) {
  //           commit('setAutoMenuList', {
  //             autoMenuList: response.data.result.automenu
  //           })
  //         }
  //         resolve()
  //       })
  //       .catch(e => reject(e))
  //   })
  // },
  noticeCount: function noticeCount(_ref11) {// return new Promise((resolve, reject) => {
    //   Vue.httpClient
    //     .get('/api/appmgmt/worknotice/getUserNoticeCount')
    //     .then(response => {
    //       commit('setNoticeCount', {
    //         noticeCount: response.data.result
    //       })
    //       resolve()
    //     })
    //     .catch(e => reject(e))
    // })

    var commit = _ref11.commit,
        state = _ref11.state;
  },
  navigation: function navigation(_ref12, params) {
    var commit = _ref12.commit,
        state = _ref12.state;
    return new Promise(function (resolve, reject) {
      var tempNavigation = {};
      var navigationA = [];
      tempNavigation.menuNm = "데이터삭제";
      tempNavigation.menuUrlAddr = "/vue/delCert/delHistoryList";
      tempNavigation.menuId = "0";
      navigationA.push(_objectSpread({}, tempNavigation));

      if (params.menuUri == "/vue/delCert/delHistoryList") {
        tempNavigation.menuNm = "삭제 이력";
        tempNavigation.menuUrlAddr = "/vue/delCert/delHistoryList";
        tempNavigation.menuId = "1";
        navigationA.push(_objectSpread({}, tempNavigation));
        commit('setNavigation', {
          navigation: navigationA //navigation: this.tempNavigation

        });
      }

      if (params.menuUri == "/vue/delCert/feeList") {
        tempNavigation.menuNm = "요금 내역";
        tempNavigation.menuUrlAddr = "/vue/delCert/feeList";
        tempNavigation.menuId = "2";
        navigationA.push(_objectSpread({}, tempNavigation));
        commit('setNavigation', {
          navigation: navigationA //navigation: this.tempNavigation

        });
      }

      if (params.menuUri == "/vue/delCert/userInfo") {
        tempNavigation.menuNm = "내정보";
        tempNavigation.menuUrlAddr = "/vue/delCert/userInfo";
        tempNavigation.menuId = "3";
        navigationA.push(_objectSpread({}, tempNavigation));
        commit('setNavigation', {
          navigation: navigationA //navigation: this.tempNavigation

        });
      }

      if (params.menuUri == "/vue/delCert/userManagementList") {
        tempNavigation.menuNm = "회원관리";
        tempNavigation.menuUrlAddr = "/vue/delCert/userManagementList";
        tempNavigation.menuId = "4";
        navigationA.push(_objectSpread({}, tempNavigation));
        commit('setNavigation', {
          navigation: navigationA //navigation: this.tempNavigation

        });
      } // if (params.menuUri == "/vue/delCert/feeManagementList"){
      //   tempNavigation.menuNm = "요금관리";
      //   tempNavigation.menuUrlAddr = "/vue/delCert/feeManagementList";
      //   tempNavigation.menuId = "5";
      //   navigationA.push({...tempNavigation});
      //   commit('setNavigation', {
      //     navigation : navigationA
      //     //navigation: this.tempNavigation
      //   })
      // }
      // if (params.menuUri == "/vue/delCert/feeDetail"){
      //   tempNavigation.menuNm = "요금상세";
      //   tempNavigation.menuUrlAddr = "/vue/delCert/feeDetail";
      //   tempNavigation.menuId = "6";
      //   navigationA.push({...tempNavigation});
      //   commit('setNavigation', {
      //     navigation : navigationA
      //     //navigation: this.tempNavigation
      //   })
      // }
      // if (params.menuUri == "/vue/delCert/feeRegist"){
      //   tempNavigation.menuNm = "요금등록";
      //   tempNavigation.menuUrlAddr = "/vue/delCert/feeRegist";
      //   tempNavigation.menuId = "7";
      //   navigationA.push({...tempNavigation});
      //   commit('setNavigation', {
      //     navigation : navigationA
      //     //navigation: this.tempNavigation
      //   })
      // }
      //window.$Alert.alert(params.menuUri)
      //      /vue/delCert/delHistoryList
      ///vue/delCert/feeList
      ///vue/delCert/userInfo
      //navigation:
      // if (params.menuUri == '/home' || params.menuUri == '/appmgmt/notice') { 
      // Vue.httpClient
      //   .post('/api/purchase', params)
      //   .then(response => {
      //     if (response.data.result.nav) {
      //       commit('setNavigation', {
      //         navigation: response.data.result.nav
      //       })
      //       for (var i in state.menuList) {
      //         if (state.menuList[i].menuId == state.navigation[0].menuId) {
      //           state.middleMenuList = state.menuList[i].menuList
      //           state.rootMenuName = state.menuList[i].menuNm
      //           state.lowMenuName = state.navigation[state.navigation.length - 1].menuNm
      //         }
      //       }
      //     }
      //     resolve()
      //   })
      //   .catch(e => reject(e))

    });
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};