//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import AppConfig from '@/config';
import Utils from '@/modules/utils';
import moment from 'moment';
import ExcelJS from 'exceljs';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';
import saveAs from 'file-saver';
import diskListPopup from './diskListPopup.vue';
import { DxExport, DxSelection, DxDataGrid, DxColumn, DxEditing, DxScrolling, DxSummary, DxLookup, DxPager, DxPaging, DxTotalItem, DxColumnFixing //컬럼 고정때필요
} from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { formatMessage } from 'devextreme/localization';
import FileUtils from '@/modules/file';
import DxSelectCodeList from '@/components/DxSelectCodeList';
import DxDateBox from "devextreme-vue/date-box";
import DataSource from 'devextreme/data/data_source';
import { exportDataGrid } from 'devextreme/excel_exporter';
import dayjs from 'dayjs';
export default {
  name: 'DesacList',
  components: {
    DxSelectBox: DxSelectBox,
    DxExport: DxExport,
    DxDateBox: DxDateBox,
    DxSelection: DxSelection,
    CustomStore: CustomStore,
    DxDataGrid: DxDataGrid,
    DxColumn: DxColumn,
    DxEditing: DxEditing,
    DxScrolling: DxScrolling,
    DxSummary: DxSummary,
    DxLookup: DxLookup,
    DxPager: DxPager,
    DxPaging: DxPaging,
    DxTotalItem: DxTotalItem,
    DxColumnFixing: DxColumnFixing,
    DxTextBox: DxTextBox,
    diskListPopup: diskListPopup
  },
  data: function data() {
    return {
      pageSizes: [20, 50, 100],
      displayMode: "full",
      searchData: {
        adminYn: "",
        userId: "",
        osVersion: "",
        erasureStatus: "",
        searchType: "",
        searchWord: "",
        startDelDateByPeriod: moment(new Date()).add(-1, "month").format('YYYY-MM-DD'),
        endDelDateByPeriod: moment().format('YYYY-MM-DD'),
        startComDateByPeriod: null,
        endComDateByPeriod: null
      },
      datasource: new CustomStore({
        key: 'sersNum',
        load: function load(loadOptions) {
          if (this.searchData.startDelDateByPeriod != null && this.searchData.endDelDateByPeriod != null) {
            if (this.searchData.startDelDateByPeriod != null) {
              this.searchData.startDelDateByPeriod = moment(moment(this.searchData.startDelDateByPeriod).format('YYYY-MM-DD') + ' 00:00:00').format('YYYY-MM-DD HH:mm:ss');
            }

            if (this.searchData.endDelDateByPeriod != null) {
              this.searchData.endDelDateByPeriod = moment(moment(this.searchData.endDelDateByPeriod).format('YYYY-MM-DD') + ' 23:59:59').format('YYYY-MM-DD HH:mm:ss');
            }
          } else {
            this.searchData.startDelDateByPeriod = null;
            this.searchData.endDelDateByPeriod = null;
          }

          if (this.searchData.startComDateByPeriod != null && this.searchData.endComDateByPeriod != null) {
            if (this.searchData.startComDateByPeriod != null) {
              this.searchData.startComDateByPeriod = moment(moment(this.searchData.startComDateByPeriod).format('YYYY-MM-DD') + ' 00:00:00').format('YYYY-MM-DD HH:mm:ss');
            }

            if (this.searchData.endComDateByPeriod != null) {
              this.searchData.endComDateByPeriod = moment(moment(this.searchData.endComDateByPeriod).format('YYYY-MM-DD') + ' 23:59:59').format('YYYY-MM-DD HH:mm:ss');
            }
          } else {
            this.searchData.startComDateByPeriod = null;
            this.searchData.endComDateByPeriod = null;
          }

          loadOptions.searchData = this.searchData;
          var url;

          if (loadOptions.isLoadingAll) {
            url = '/erasure/getErasureInfoAllList'; // }else if(this.searchData.searchWord){
            //     url='/erasure/searchErasureInfoList'
          } else {
            url = '/erasure/getErasureInfoList';
          }

          return Vue.httpClient.post(url, loadOptions).then(function (response) {
            if (response.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {
              return {
                data: response.data.result.items,
                totalCount: response.data.result.totalCount
              };
            }
          });
        }
      }),
      osList: [{
        cd: "",
        nm: "전체"
      }, {
        cd: 'android',
        nm: 'Android '
      }, {
        cd: 'ios',
        nm: 'iOS'
      }, {
        cd: 'pc',
        nm: 'PC'
      }],
      erasureStatus: [{
        cd: "",
        nm: "전체"
      }, {
        cd: 'start',
        nm: '삭제시작'
      }, {
        cd: 'cancel',
        nm: '삭제취소'
      }, {
        cd: 'end',
        nm: '삭제종료'
      }, {
        cd: 'finish',
        nm: '공장초기화완료'
      }],
      searchKeyWord: [{
        cd: 'mngmntNum',
        nm: '관리번호'
      }, {
        cd: 'modelNum',
        nm: '모델번호'
      }, {
        cd: 'imei',
        nm: 'IMEI'
      }, {
        cd: 'userId',
        nm: '아이디'
      }, {
        cd: 'comNm',
        nm: '회사명'
      }],
      //fileUpload
      //excel upload 용 시작
      headers: {}
    };
  },
  computed: {
    gridInstance: function gridInstance() {
      return this.$refs.gridContainer['instance'];
    }
  },
  methods: {
    formatMessage: formatMessage,
    onToolbarPreparing: function onToolbarPreparing(e) {
      e.toolbarOptions.items = [];
    },
    search: function search() {
      this.datasource.searchData = this.searchData;
      this.$refs['grid'].instance.refresh();
    },
    //엑셀다운로드
    onDownload: function onDownload() {
      var gridInstance = this.$refs['grid'].instance;
      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet('DelHistoryList');
      exportDataGrid({
        component: gridInstance,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], {
            type: 'application/octet-stream'
          }), "\uC0AD\uC81C\uBAA9\uB85D_".concat(moment(new Date()).format('YYYYMMDD'), ".xlsx"));
        });
      });
    },
    chgStartDelDateByPeriod: function chgStartDelDateByPeriod(e) {
      if (e.value != null) {
        this.searchData.startDelDateByPeriod = moment(moment(e.value).format('YYYY-MM-DD') + ' 00:00:00').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.searchData.startDelDateByPeriod = null;
      }
    },
    chgEndDelDateByPeriod: function chgEndDelDateByPeriod(e) {
      if (e.value != null) {
        this.searchData.endDelDateByPeriod = moment(moment(e.value).format('YYYY-MM-DD') + ' 23:59:59').format('YYYY-MM-DD HH:mm:ss');
      } else {
        //this.searchData.mesgFwdiStDt = null
        this.searchData.endDelDateByPeriod = null;
      }
    },
    chgStartComDateByPeriod: function chgStartComDateByPeriod(e) {
      if (e.value != null) {
        this.searchData.startComDateByPeriod = moment(moment(e.value).format('YYYY-MM-DD') + ' 00:00:00').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.searchData.startComDateByPeriod = null;
      }
    },
    chgEndComDateByPeriod: function chgEndComDateByPeriod(e) {
      if (e.value != null) {
        this.searchData.endComDateByPeriod = moment(moment(e.value).format('YYYY-MM-DD') + ' 23:59:59').format('YYYY-MM-DD HH:mm:ss');
      } else {
        //this.searchData.mesgFwdiStDt = null
        this.searchData.endComDateByPeriod = null;
      }
    },
    handleValueChangedBySearchType: function handleValueChangedBySearchType(e) {
      this.searchData.searchWord = null;
    },
    reset: function reset() {
      this.searchData.osVersion = "";
      this.searchData.erasureStatus = "";
      this.searchData.searchType = "";
      this.searchData.searchWord = "";
      this.searchData.startDelDateByPeriod = moment(new Date()).add(-1, "month").format('YYYY-MM-DD');
      this.searchData.endDelDateByPeriod = moment().format('YYYY-MM-DD');
      this.searchData.startComDateByPeriod = null;
      this.searchData.endComDateByPeriod = null; //그리드 초기화

      this.datasource.searchData = this.searchData;
      this.$refs['grid'].instance.refresh();
    },
    urlOnclick: function urlOnclick(e) {
      var linkUrl = "";

      if (e.columnIndex === 12 && e.data.erasureStatus === 'F') {
        if (e.data.os === 'PC') {
          linkUrl = 'https://certification.mintit.co.kr/erasure_pc_pdf/' + e.data.ordNumber + ".pdf";
        } else {
          linkUrl = 'https://certification.mintit.co.kr/erasure_pdf/' + e.data.ordNumber + ".pdf";
        }

        window.open(linkUrl);
      } else if (e.columnIndex === 13 && e.data.listAuth) {
        this.$modal.show('diskListPopup', {
          ordNumber: e.data.ordNumber,
          memId: e.data.memId
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    var userTypeCd = Vue.$sessionStorage.get('userTypeCd');

    if (userTypeCd == '05') {
      this.adminYn = true;
    } else {
      this.adminYn = false;
    }

    this.searchData.adminYn = this.adminYn;
    this.searchData.userId = window.$store.getters['Login/getUserId'];
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.datasource.searchData = this.searchData;
    });
  }
};