import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
var locales = [{
  Name: 'KOR',
  Value: 'ko',
  Label: 'KOR'
}, {
  Name: 'ENG',
  Value: 'en',
  Label: 'ENG'
}];
export default {
  setLocaleName: function setLocaleName(name) {
    sessionStorage.setItem('localeName', name);
  },
  getLocaleName: function getLocaleName() {
    var name = sessionStorage.getItem('localeName');
    return name != null ? name : 'KOR';
  },
  getLocales: function getLocales() {
    return locales;
  },
  getLocale: function getLocale() {
    var locale = sessionStorage.getItem('locale');
    return locale != null ? locale : 'ko';
  },
  setLocale: function setLocale(locale) {
    sessionStorage.setItem('locale', locale);
  },
  formatCurrency: function formatCurrency() {
    var locale = sessionStorage.getItem('locale');

    if (locale === 'ko') {
      return '#,##0 원';
    } else if (locale === 'en') {
      return '$ #,##0.';
    } else if (locale === 'jp') {
      return '¥ #,##0.';
    } else {
      return '#,##0';
    }
  },
  formatDate: function formatDate() {
    var locale = sessionStorage.getItem('locale');

    if (locale === 'ko') {
      return 'yyyy-MM-dd HH:mm:SS';
    } else if (locale === 'en') {
      return 'MM/dd/yyyy';
    } else {
      return 'yyyy-MM-dd';
    }
  },
  auiFormatDate: function auiFormatDate() {
    var locale = sessionStorage.getItem('locale');

    if (locale === 'ko') {
      return 'yyyy-mm-dd HH:MM:ss';
    } else if (locale === 'en') {
      return 'mm/dd/yyyy HH:MM:ss';
    } else {
      return 'yyyy-mm-dd HH:MM:ss';
    }
  },
  auiSpsFormatDate: function auiSpsFormatDate() {
    var locale = sessionStorage.getItem('locale');

    if (locale === 'ko') {
      return 'yyyy-mm-dd HH:MM:ss';
    } else if (locale === 'en') {
      return 'mm/dd/yyyy HH:MM:ss';
    } else {
      return 'yyyy-mm-dd HH:MM:ss';
    }
  },

  /**
   * AUI Grid 다국어 통화 표시 label function
   * value에 숫자값이 있을 경우에만 처리
   * @param {Number, String} value
   * @returns 그리드상에 표시될 값
   */
  auiCurrencyLabelFunction: function auiCurrencyLabelFunction(r, c, value, hT, item) {
    if (value === '' || value === null) return '';
    if (Number.isNaN(Number(value))) return value;
    var locale = sessionStorage.getItem('locale');
    var prefix = '';
    var postfix = ' 원';

    if (locale === 'en') {
      prefix = '$ ';
      postfix = '.';
    } else if (locale === 'jp') {
      prefix = '¥ ';
      postfix = '.';
    }

    return prefix + window.AUIGrid.formatNumber(value, '#,##0') + postfix;
  }
};