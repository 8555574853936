//
//
//
//
//
//
//
//
//
//
import { TweenMax } from "gsap/TweenMax";
export default {
  name: 'footerView',
  mounted: function mounted() {
    var $winHeight = $(window).height();
    var $conHeight = $('.container').height();

    if ($winHeight < 937) {
      $('footer .footer').addClass('active');
    }

    if ($conHeight > 820) {
      $('footer .footer').addClass('active');
    }

    $(window).resize(function () {
      var $winHeight = $(window).height();
      var $conHeight = $('.container').outerHeight();

      if ($winHeight < 930) {
        $('footer .footer').addClass('active');
      } else if ($conHeight > 820) {} else {
        $('footer .footer').removeClass('active');
      }
    });
  },
  methods: {
    goTop: function goTop() {
      TweenMax.to($('body, html'), 0.5, {
        scrollTop: 0,
        ease: "Cubic.easeOut"
      });
    }
  }
};