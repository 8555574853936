var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "diskListPopup",
            width: 940,
            height: 560,
            clickToClose: false,
          },
          on: { "before-open": _vm.beforeOpen, opened: _vm.opened },
        },
        [
          _c("div", { staticClass: "popup", attrs: { id: "pop_up" } }, [
            _c("div", { staticClass: "pop_tit" }, [
              _c("span", [_vm._v(_vm._s(_vm.formatMessage("diskList")))]),
              _c(
                "a",
                {
                  staticClass: "pop_close",
                  attrs: { href: "javascript:void(0);", title: "close" },
                  on: { click: _vm.closePopup },
                },
                [_vm._v("close")]
              ),
            ]),
            _c("div", { staticClass: "pop_con_wrap" }, [
              _c("div", { staticClass: "pop_con_bg" }, [
                _c("div", { staticClass: "tbl" }, [
                  _c(
                    "div",
                    { staticClass: "grid_area" },
                    [
                      _c(
                        "DxDataGrid",
                        {
                          ref: "grid",
                          attrs: {
                            "data-source": this.diskList,
                            "show-borders": true,
                            "remote-operations": true,
                            "column-auto-width": true,
                            selection: { mode: "single" },
                            "allow-column-resizing": true,
                            "allow-column-reordering": true,
                            height: 400,
                          },
                        },
                        [
                          _c("DxColumn", {
                            attrs: {
                              "data-field": "diskDrive",
                              "data-type": "string",
                              width: 150,
                              caption: _vm.formatMessage("diskDrive"),
                            },
                          }),
                          _c("DxColumn", {
                            attrs: {
                              "data-field": "diskSerial",
                              width: 150,
                              caption: _vm.formatMessage("diskSerial"),
                            },
                          }),
                          _c("DxColumn", {
                            attrs: {
                              "data-field": "diskSize",
                              width: 150,
                              caption: _vm.formatMessage("diskSize"),
                            },
                          }),
                          _c("DxColumn", {
                            attrs: {
                              "data-field": "diskType",
                              width: 150,
                              caption: _vm.formatMessage("diskType"),
                            },
                          }),
                          _c("DxColumn", {
                            attrs: {
                              "data-field": "method",
                              width: 150,
                              caption: _vm.formatMessage("diskMethod"),
                            },
                          }),
                          _c("DxColumn", {
                            attrs: {
                              "data-field": "status",
                              width: 150,
                              caption: _vm.formatMessage("diskStatus"),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "pop_footer_wrap" }, [
              _c("div", { staticClass: "pop_btn_area" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn_type_01 delete",
                    on: { click: _vm.closePopup },
                  },
                  [_vm._v(_vm._s(_vm.formatMessage("close")))]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }