var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "sub_content" },
    [
      _c("div", { staticClass: "search_area" }, [
        _c("table", { staticClass: "sh_table" }, [
          _c("caption", [_vm._v(" 조회영역 ")]),
          _c("h2", [_vm._v("요금 내역")]),
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v("삭제일")]),
              _c("td", [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("DxDateBox", {
                      staticClass: "date mar_l5",
                      attrs: {
                        value: _vm.searchData.startErrorDateByPeriod,
                        max: _vm.searchData.endErrorDateByPeriod,
                        "display-format": "yyyy-MM-dd",
                        "styling-mode": "outlined",
                        placeholder: "from",
                      },
                      on: {
                        "value-Changed":
                          _vm.handleValueChangedByStartErrorDateByPeriod,
                      },
                    }),
                    _c("DxDateBox", {
                      staticClass: "date mar_l5",
                      attrs: {
                        value: _vm.searchData.endErrorDateByPeriod,
                        min: _vm.searchData.startErrorDateByPeriod,
                        "display-format": "yyyy-MM-dd",
                        "styling-mode": "outlined",
                        placeholder: "to",
                      },
                      on: {
                        "value-Changed":
                          _vm.handleValueChangedByEndErrorDateByPeriod,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "td",
                { staticClass: "taR  pad_t5 pad_b5", attrs: { colspan: "8" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn bg_blue",
                      on: { click: _vm.handleSearch },
                    },
                    [_vm._v("조회")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn bg_green",
                      on: { click: _vm.onExcelDownload },
                    },
                    [_vm._v(" 다운로드 ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn bg_gray",
                      on: { click: _vm.handleErrorInput },
                    },
                    [_vm._v("초기화")]
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c("AUIGrid", {
            ref: "gridByAtmError",
            attrs: { autoResize: false },
          }),
          _c("paging-navigation", {
            attrs: {
              "paging-data": {
                totalRowCount: _vm.pagingData.totalRowCount,
                rowCount: _vm.pagingData.rowCount,
                pageButtonCount: 10,
              },
              reset: _vm.pagingData.reset,
              "prop-row-counts": _vm.pagingData.propRowCounts,
            },
            on: { "paging-load": _vm.handleLoad },
          }),
        ],
        1
      ),
      _c("excel-paging-download", {
        attrs: {
          "file-name": _vm.xlsxFileName,
          "column-layout": _vm.columnLayout,
          "auigrid-props": _vm.auigridProps,
          "total-row-count": _vm.pagingData.totalRowCount,
          "prop-row-count-flag": 10000,
          "paging-url": "" + _vm.prefixUrl,
          params: function () {
            var params = Object.assign(
              {},
              _vm.searchPagingData,
              _vm.cachePagingData
            )

            return params
          },
          "export-xlsx": _vm.exportXlsx,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }