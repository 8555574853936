var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DxValidationGroup", { ref: "validationGroup" }, [
        _c("div", { staticClass: "pop_con_wrap" }, [
          _c(
            "div",
            {
              staticClass: "pop_con mscroll",
              style: [_vm.isSelf ? { height: "280px" } : { height: "500px" }],
            },
            [
              _c("div", { staticClass: "pop_con_bg" }, [
                _c("div", { staticClass: "tbl" }, [
                  _c("div", { staticClass: "board_list type01" }, [
                    _c("table", { staticClass: "board_table" }, [
                      _c("caption", { staticClass: "hide" }, [
                        _vm._v("사용자 정보"),
                      ]),
                      _c("colgroup", [
                        _c("col", { staticStyle: { width: "25%" } }),
                        _c("col", { staticStyle: { width: "75%" } }),
                      ]),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _c("span", {}),
                            _vm._v(_vm._s(_vm.userTypeCd)),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "dxSelectCodeList",
                                {
                                  attrs: {
                                    callVue: _vm.startVue,
                                    cmnsCdGrpId: "USER_TYPE_CD2",
                                    allYn: "N",
                                    "read-only": true,
                                  },
                                  model: {
                                    value: _vm.userInfo.userTypeCd,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userInfo, "userTypeCd", $$v)
                                    },
                                    expression: "userInfo.userTypeCd",
                                  },
                                },
                                [
                                  _c(
                                    "DxValidator",
                                    [
                                      _c("DxRequiredRule", {
                                        attrs: { message: _vm.userTypeMsg },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _c("span", { staticClass: "point" }),
                            _vm._v(_vm._s(_vm.userId)),
                          ]),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("DxTextBox", {
                                  attrs: { "read-only": true },
                                  model: {
                                    value: _vm.userInfo.userId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userInfo, "userId", $$v)
                                    },
                                    expression: "userInfo.userId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        !_vm.adminYn
                          ? _c("tr", [
                              _c("th", [
                                _c("span", {
                                  staticClass: "icon_require point",
                                }),
                                _vm._v(_vm._s(_vm.password)),
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "DxTextBox",
                                    {
                                      attrs: {
                                        name: "비밀번호",
                                        placeholder:
                                          _vm.formatMessage("placeholder"),
                                        mode: "password",
                                      },
                                      model: {
                                        value: _vm.userInfo.pwd,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.userInfo, "pwd", $$v)
                                        },
                                        expression: "userInfo.pwd",
                                      },
                                    },
                                    [
                                      _c(
                                        "DxValidator",
                                        [
                                          _c("DxRequiredRule", {
                                            attrs: { message: _vm.pwdMsg },
                                          }),
                                          _c("DxPatternRule", {
                                            attrs: {
                                              pattern: _vm.pwdPattern,
                                              message:
                                                _vm.formatMessage("pwdPattern"),
                                            },
                                          }),
                                          _c("DxPatternRule", {
                                            attrs: {
                                              pattern: _vm.pwdPattern2,
                                              message:
                                                _vm.formatMessage(
                                                  "pwdPattern2"
                                                ),
                                            },
                                          }),
                                          _c("DxStringLengthRule", {
                                            attrs: {
                                              min: 8,
                                              max: 20,
                                              message: _vm.formatMessage(
                                                "stringLen",
                                                8,
                                                20
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        !_vm.adminYn
                          ? _c("tr", [
                              _c("th", [
                                _c("span", {
                                  staticClass: "icon_require point",
                                }),
                                _vm._v(_vm._s(_vm.confirmationPassword)),
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "DxTextBox",
                                      {
                                        attrs: {
                                          name: "확인 비밀번호",
                                          placeholder:
                                            _vm.formatMessage("placeholder"),
                                          mode: "password",
                                        },
                                        model: {
                                          value: _vm.userInfo.repwd,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.userInfo, "repwd", $$v)
                                          },
                                          expression: "userInfo.repwd",
                                        },
                                      },
                                      [
                                        _c(
                                          "DxValidator",
                                          [
                                            _c("DxRequiredRule", {
                                              attrs: { message: _vm.rePwdMsg },
                                            }),
                                            _c("DxCompareRule", {
                                              attrs: {
                                                "comparison-target":
                                                  _vm.passwordComparison,
                                                message: _vm.checkRePwdMsg,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.adminYn
                          ? _c("tr", [
                              _c("th", [
                                _c("span", {}),
                                _vm._v(_vm._s(_vm.passwordInit)),
                              ]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn bg_blue",
                                    on: { click: _vm.resetPw },
                                  },
                                  [_vm._v("초기화")]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("tr", [
                          _c("th", [_c("span", {}), _vm._v(_vm._s(_vm.comNm))]),
                          _c(
                            "td",
                            [
                              _c(
                                "DxTextBox",
                                {
                                  attrs: {
                                    placeholder:
                                      _vm.formatMessage("placeholder"),
                                    "read-only": true,
                                  },
                                  model: {
                                    value: _vm.userInfo.comNm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userInfo, "comNm", $$v)
                                    },
                                    expression: "userInfo.comNm",
                                  },
                                },
                                [
                                  _c(
                                    "DxValidator",
                                    [
                                      _c("DxRequiredRule", {
                                        attrs: {
                                          message:
                                            _vm.formatMessage("comNmMsg"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _c("span", {}),
                            _vm._v(_vm._s(_vm.rstvNm)),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "DxTextBox",
                                {
                                  attrs: {
                                    placeholder:
                                      _vm.formatMessage("placeholder"),
                                    "read-only": true,
                                  },
                                  model: {
                                    value: _vm.userInfo.rstvNm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userInfo, "rstvNm", $$v)
                                    },
                                    expression: "userInfo.rstvNm",
                                  },
                                },
                                [
                                  _c(
                                    "DxValidator",
                                    [
                                      _c("DxRequiredRule", {
                                        attrs: {
                                          message:
                                            _vm.formatMessage("rstvNmMsg"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _c("span", {}),
                            _vm._v(_vm._s(_vm.rstvNmTelNum)),
                          ]),
                          _c(
                            "td",
                            [
                              _c("DxTextBox", {
                                attrs: {
                                  placeholder: _vm.formatMessage("placeholder"),
                                  "read-only": true,
                                },
                                model: {
                                  value: _vm.userInfo.rstvNmTelNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userInfo, "rstvNmTelNum", $$v)
                                  },
                                  expression: "userInfo.rstvNmTelNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _c("span", {}),
                            _vm._v(_vm._s(_vm.bsnpRegNum)),
                          ]),
                          _c(
                            "td",
                            [
                              _c("DxTextBox", {
                                attrs: {
                                  mask: "000-00-00000",
                                  placeholder: _vm.formatMessage("placeholder"),
                                  "read-only": true,
                                },
                                model: {
                                  value: _vm.userInfo.bsnpRegNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userInfo, "bsnpRegNum", $$v)
                                  },
                                  expression: "userInfo.bsnpRegNum",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _c("span", { staticClass: "icon_require point" }),
                            _vm._v(_vm._s(_vm.psicNm)),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "DxTextBox",
                                {
                                  attrs: {
                                    placeholder:
                                      _vm.formatMessage("placeholder"),
                                  },
                                  model: {
                                    value: _vm.userInfo.userNm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userInfo, "userNm", $$v)
                                    },
                                    expression: "userInfo.userNm",
                                  },
                                },
                                [
                                  _c(
                                    "DxValidator",
                                    [
                                      _c("DxRequiredRule", {
                                        attrs: {
                                          message:
                                            _vm.formatMessage("psicNmMsg"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _c("span", { staticClass: "icon_require point" }),
                            _vm._v(_vm._s(_vm.psicTelNum)),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "DxTextBox",
                                {
                                  attrs: {
                                    "mask-rules": _vm.rules,
                                    mask: "000-0000-0000",
                                    placeholder:
                                      _vm.formatMessage("placeholder"),
                                  },
                                  model: {
                                    value: _vm.userInfo.psicTelNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userInfo, "psicTelNum", $$v)
                                    },
                                    expression: "userInfo.psicTelNum",
                                  },
                                },
                                [
                                  _c(
                                    "DxValidator",
                                    [
                                      _c("DxRequiredRule", {
                                        attrs: {
                                          message:
                                            _vm.formatMessage("mobileNumMsg"),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _c("span", { staticClass: "icon_require point" }),
                            _vm._v(_vm._s(_vm.email)),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "DxTextBox",
                                {
                                  attrs: {
                                    placeholder:
                                      _vm.formatMessage("placeholder"),
                                  },
                                  model: {
                                    value: _vm.userInfo.emlAddr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.userInfo, "emlAddr", $$v)
                                    },
                                    expression: "userInfo.emlAddr",
                                  },
                                },
                                [
                                  _c(
                                    "DxValidator",
                                    [
                                      _c("DxEmailRule", {
                                        attrs: { message: _vm.emailRuleMsg },
                                      }),
                                      _c("DxRequiredRule", {
                                        attrs: { message: _vm.emailMsg },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _c("span", { staticClass: "icon_require point" }),
                            _vm._v(_vm._s(_vm.bizReg)),
                          ]),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "line blue",
                                attrs: { href: "javascript:void(0)" },
                                on: { click: _vm.viewBizReg },
                              },
                              [
                                _c("p", [
                                  _vm._v(_vm._s(this.userInfo.fileName)),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]),
        _c("div", { staticClass: "btn_area" }, [
          _c("tr", [
            _c("td", { staticClass: "taR  pad_t5 pad_b5" }, [
              _c(
                "button",
                { staticClass: "btn bg_blue", on: { click: _vm.userUpdate } },
                [_vm._v(_vm._s(_vm.update))]
              ),
              _vm.adminYn
                ? _c(
                    "button",
                    {
                      staticClass: "btn bg_gray",
                      on: { click: _vm.handleHistoryBack },
                    },
                    [_c("i", { staticClass: "xi-apps" })]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _c("imgInfoPopup"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }