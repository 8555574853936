var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "main-contents" }, [
    _c("article", { staticClass: "main-top" }, [
      _c("section", { staticClass: "main-banner" }, [
        _vm._m(0),
        _c("p", { staticClass: "m-date" }, [_vm._v(_vm._s(_vm.date))]),
        _c("p", { staticClass: "m-day" }, [_vm._v(_vm._s(_vm.today))]),
        _c("div", { staticClass: "banner-img" }),
      ]),
      _vm._m(1),
    ]),
    _c("article", { staticClass: "main-chart" }, [
      _c("section", [
        _c("div", { staticClass: "main-box-wrap" }, [
          _vm._m(2),
          _c(
            "div",
            { staticClass: "m-chart" },
            [
              _c(
                "DxChart",
                {
                  attrs: {
                    "customize-point": _vm.customizePoint,
                    id: "chart",
                    "data-source": _vm.dataSource,
                  },
                },
                [
                  _c("DxCommonSeriesSettings", {
                    attrs: { "argument-field": "country", type: "stackedbar" },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "hydro",
                      name: "Hydro-electric",
                      color: "#ff7c7c",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "oil",
                      name: "Oil",
                      color: "#8AFCA5",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "gas",
                      name: "Natural gas",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "coal",
                      name: "Coal",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "nuclear",
                      name: "Nuclear",
                      barWidth: 20,
                    },
                  }),
                  _c("DxLegend", {
                    attrs: {
                      "vertical-alignment": "top",
                      "horizontal-alignment": "center",
                      "item-text-position": "right",
                    },
                  }),
                  _c("DxExport", { attrs: { enabled: true } }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("section", [
        _c("div", { staticClass: "main-box-wrap" }, [
          _vm._m(3),
          _c(
            "div",
            { staticClass: "m-chart" },
            [
              _c(
                "DxChart",
                {
                  attrs: {
                    "customize-point": _vm.customizePoint,
                    id: "chart",
                    "data-source": _vm.dataSource,
                  },
                },
                [
                  _c("DxCommonSeriesSettings", {
                    attrs: {
                      "argument-field": "country",
                      type: "fullstackedbar",
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "hydro",
                      name: "Hydro-electric",
                      color: "#ff7c7c",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "oil",
                      name: "Oil",
                      color: "#8AFCA5",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "gas",
                      name: "Natural gas",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "coal",
                      name: "Coal",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "nuclear",
                      name: "Nuclear",
                      barWidth: 20,
                    },
                  }),
                  _c("DxLegend", {
                    attrs: {
                      "vertical-alignment": "top",
                      "horizontal-alignment": "center",
                      "item-text-position": "right",
                    },
                  }),
                  _c("DxExport", { attrs: { enabled: true } }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("section", [
        _c("div", { staticClass: "main-box-wrap" }, [
          _vm._m(4),
          _c(
            "div",
            { staticClass: "m-chart" },
            [
              _c(
                "DxChart",
                {
                  attrs: {
                    "customize-point": _vm.customizePoint,
                    id: "chart",
                    "data-source": _vm.dataSource,
                  },
                },
                [
                  _c("DxCommonSeriesSettings", {
                    attrs: {
                      "argument-field": "country",
                      type: "fullstackedbar",
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "hydro",
                      name: "Hydro-electric",
                      color: "#ff7c7c",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "oil",
                      name: "Oil",
                      color: "#8AFCA5",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "gas",
                      name: "Natural gas",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "coal",
                      name: "Coal",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "nuclear",
                      name: "Nuclear",
                      barWidth: 20,
                    },
                  }),
                  _c("DxLegend", {
                    attrs: {
                      "vertical-alignment": "top",
                      "horizontal-alignment": "center",
                      "item-text-position": "right",
                    },
                  }),
                  _c("DxExport", { attrs: { enabled: true } }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("section", [
        _c("div", { staticClass: "main-box-wrap" }, [
          _vm._m(5),
          _c(
            "div",
            { staticClass: "m-chart" },
            [
              _c(
                "DxChart",
                {
                  attrs: {
                    "customize-point": _vm.customizePoint,
                    id: "chart",
                    "data-source": _vm.dataSource,
                  },
                },
                [
                  _c("DxCommonSeriesSettings", {
                    attrs: {
                      "argument-field": "country",
                      type: "fullstackedbar",
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "hydro",
                      name: "Hydro-electric",
                      color: "#ff7c7c",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "oil",
                      name: "Oil",
                      color: "#8AFCA5",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "gas",
                      name: "Natural gas",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "coal",
                      name: "Coal",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      "value-field": "nuclear",
                      name: "Nuclear",
                      barWidth: 20,
                    },
                  }),
                  _c("DxSeries", {
                    attrs: {
                      axis: "total",
                      type: "line",
                      "value-field": "total",
                      name: "Total",
                      color: "#008fd8",
                    },
                  }),
                  _c("DxLegend", {
                    attrs: {
                      "vertical-alignment": "top",
                      "horizontal-alignment": "center",
                      "item-text-position": "right",
                    },
                  }),
                  _c(
                    "DxValueAxis",
                    [_c("DxGrid", { attrs: { visible: true } })],
                    1
                  ),
                  _c(
                    "DxValueAxis",
                    { attrs: { name: "total", position: "right" } },
                    [_c("DxGrid", { attrs: { visible: true } })],
                    1
                  ),
                  _c("DxTooltip", {
                    attrs: {
                      enabled: true,
                      "customize-tooltip": _vm.customizeTooltip,
                      format: "millions",
                    },
                  }),
                  _c("DxExport", { attrs: { enabled: true } }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _c("img", {
        attrs: { src: require("../assets/images/m-logo.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "main-counter" }, [
      _c("div", { staticClass: "main-box-wrap" }, [
        _c("h2", { staticClass: "main-bar" }, [
          _vm._v("전체 매입요약 "),
          _c("span", [
            _vm._v("기준년월 2021.12 기준년월로부터 과거12개월 추이 조회"),
          ]),
        ]),
        _c("div", { staticClass: "main-box-item" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-year" }, [_vm._v("2020년")]),
            _c("div", { staticClass: "item-num ico-01" }, [
              _c("p", [_vm._v("1월 ~ 12월 합산기준")]),
              _c("span", { staticClass: "num" }, [_vm._v("317,603")]),
              _c("span", { staticClass: "txt" }, [_vm._v("대")]),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-year" }, [_vm._v("2021년")]),
            _c("div", { staticClass: "item-num ico-02" }, [
              _c("p", [_vm._v("1월 ~ 12월 합산기준")]),
              _c("span", { staticClass: "num" }, [_vm._v("877,629")]),
              _c("span", { staticClass: "txt" }, [_vm._v("대")]),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-year" }, [_vm._v("전년대비")]),
            _c("div", { staticClass: "item-num ico-03" }, [
              _c("p", [_vm._v("1월 ~ 12월 합산기준")]),
              _c("span", { staticClass: "num" }, [_vm._v("660,026")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "main-bar" }, [
      _vm._v("채널 그룹별 매입량 "),
      _c("span", [_vm._v("K = 1,000 단위 : 대")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "main-bar" }, [
      _vm._v("판정등급별 매입비중 "),
      _c("span", [_vm._v("K = 1,000 단위 : 대")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "main-bar" }, [
      _vm._v("월별 평균 매입가 "),
      _c("span", [_vm._v("단위 : 천원")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "main-bar" }, [
      _vm._v("기부하기 매입 현황 "),
      _c("span", [_vm._v("K = 1,000 단위 : 대")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }