import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PagingNavigation',
  props: ['pagingData', 'reset', 'propRowCounts'],
  data: function data() {
    return {
      currentPage: 1,
      pageButtonCount: 5,
      rowCount: 20,
      totalRowCount: 0
    };
  },
  watch: {
    pagingData: function pagingData() {
      this.totalRowCount = this.pagingData.totalRowCount;
      this.pageButtonCount = this.pagingData.pageButtonCount;
    },
    reset: function reset() {
      this.currentPage = 1;
      this.moveToPage(this.currentPage, true);
    }
  },
  computed: {
    rowCounts: function rowCounts() {
      return this.propRowCounts || [20, 50, 100, 200, 500, 1000];
    },
    pagingItems: function pagingItems() {
      var items = [];

      for (var i = this.start; i <= this.end; i++) {
        items.push(i);

        if (i >= this.totalPage) {
          break;
        }
      }

      return items;
    },
    start: function start() {
      return this.prevPage + 1;
    },
    end: function end() {
      return this.pageButtonCount + this.prevPage;
    },
    totalPage: function totalPage() {
      return Math.ceil(this.totalRowCount / this.rowCount);
    },
    prevPage: function prevPage() {
      return Math.max(0, parseInt((this.currentPage - 1) / this.pageButtonCount) * this.pageButtonCount);
    },
    nextPage: function nextPage() {
      return Math.min(parseInt((this.currentPage - 1) / this.pageButtonCount) * this.pageButtonCount + this.pageButtonCount + 1, this.totalPage);
    }
  },
  methods: {
    handleClick: function handleClick(rowCount) {
      this.rowCount = rowCount;
      this.currentPage = 1;
      this.moveToPage(this.currentPage, false);
    },
    moveToPage: function moveToPage(goPage, cache) {
      this.currentPage = Math.max(1, goPage);
      this.$emit('paging-load', {
        cache: cache,
        skip: (this.currentPage - 1) * this.rowCount,
        take: this.rowCount
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    var rowCount = this.rowCounts.find(function (c) {
      return c === _this.pagingData.rowCount;
    });
    this.rowCount = rowCount || this.pagingData.rowCount;
  }
};