import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import router from '@/router';
import AppConfig from '@/config';
import Vue from 'vue';
import { DxDataGrid, DxColumn, DxEditing, DxScrolling, DxSummary, DxLookup, DxPager, DxPaging, DxTotalItem } from 'devextreme-vue/data-grid';
import DxTreeView from "devextreme-vue/ui/tree-view";
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
export default {
  name: 'sideMenu',
  components: {
    DxTreeView: DxTreeView,
    DxDataGrid: DxDataGrid,
    DxColumn: DxColumn,
    DxEditing: DxEditing,
    DxScrolling: DxScrolling,
    DxSummary: DxSummary,
    DxLookup: DxLookup,
    DxPager: DxPager,
    DxPaging: DxPaging,
    DxTotalItem: DxTotalItem
  },
  data: function data() {
    return {
      menuList: null,
      adminYn: false,
      items: [{
        menuNm: "삭제 이력",
        menuUrlAddr: "/vue/delCert/delHistoryList",
        menuId: "1"
      }, {
        menuNm: "요금 내역",
        menuUrlAddr: "/vue/delCert/feeList",
        menuId: "2"
      }],
      nodeE: []
    };
  },
  // mounted: function () {
  // },
  computed: {},
  methods: {
    handleItemClick: function handleItemClick(e) {
      var _this = this;

      //$("#sideMenu [aria-level='1']").removeClass('dx-treeview-node-is-leaf');
      if (e.node.itemData.menuUrlAddr != null && e.node.itemData.menuUrlAddr != undefined && e.node.itemData.menuUrlAddr != "") {
        this.$emit('select-low-menu', e.node.itemData.menuNm);
        var userTypeCd = Vue.$sessionStorage.get('userTypeCd');

        if (userTypeCd == "05" && e.node.itemData.menuNm == "요금 내역") {
          this.$router.push({
            path: "/vue/delCert/feeMainList"
          }).catch(function () {});
        } else if (e.node.itemData.menuNm == "삭제 APP 다운로드") {
          var linkUrl = 'https://play.google.com/store/apps/details?id=com.skn.mintit';
          window.open(linkUrl);
        } else {
          this.$router.push({
            path: e.node.itemData.menuUrlAddr
          }).catch(function () {});
        }
      } else {
        if (e.node.parent == null) {
          this.nodeE.forEach(function (i) {
            _this.$refs['treeViewRef'].instance.collapseItem(i);
          });
          this.nodeE = [];
        } //        this.$refs['treeViewRef'].instance.expandItem(e.itemElement)

      }
    },
    onItemExpanded: function onItemExpanded(e) {
      this.nodeE.push(e.itemElement);
    },
    open: function open(val) {
      // init
      this.$refs['treeViewRef'].instance.collapseAll();
      this.items = val;
      this.$nextTick(function () {
        this.items = [];
        this.nodeE = [];
      });
    },
    goMenu: function goMenu(menuId) {
      var _this2 = this;

      var curName = this.$router.history.current.name;
      var curPath = this.$router.history.current.path;

      if (curName != menuId) {
        window.$store.dispatch('LatestMenu/deleteLatestMenu', {
          name: menuId
        }).then(function (response) {
          _this2.$router.push({
            name: menuId,
            params: {
              refresh: true
            }
          });
        });
      } else {
        this.$router.push(curPath + '?' + this.pageIndex++);
      }
    }
  },
  beforeMount: function beforeMount() {
    var userTypeCd = Vue.$sessionStorage.get('userTypeCd');

    if (userTypeCd == '05') {
      this.adminYn = true;
      this.items.push({
        menuNm: "회원관리",
        menuUrlAddr: "/vue/delCert/userManagementList",
        menuId: "3"
      }); //  this.adminYn = true;
      //  this.items.push({
      //    menuNm : "요금관리",
      //    menuUrlAddr : "/vue/delCert/feeManagementList",
      //    menuId : "4"
      //  });
    } else {
      this.adminYn = false;
      this.items.push({
        menuNm: "내정보",
        menuUrlAddr: "/vue/delCert/userInfo",
        menuId: "3"
      });
      this.items.push({
        menuNm: "삭제 APP 다운로드",
        menuUrlAddr: "/vue/delCert/desacList3",
        menuId: "4"
      });
    }
  }
};