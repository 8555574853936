import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import AppConfig from '@/config';
import Utils from '@/modules/utils';
import moment from 'moment';
import { DxGrid, DxArgumentAxis, DxLabel, DxChart, DxSeries, DxCommonSeriesSettings, DxValueAxis, DxTitle, DxLegend, DxExport, DxTooltip } from 'devextreme-vue/chart';
export default {
  name: 'Home',
  components: {
    DxGrid: DxGrid,
    DxArgumentAxis: DxArgumentAxis,
    DxLabel: DxLabel,
    DxChart: DxChart,
    DxSeries: DxSeries,
    DxCommonSeriesSettings: DxCommonSeriesSettings,
    DxValueAxis: DxValueAxis,
    DxTitle: DxTitle,
    DxLegend: DxLegend,
    DxExport: DxExport,
    DxTooltip: DxTooltip
  },
  data: function data() {
    return {
      today: "",
      date: null,
      dataSource: [{
        country: 'USA',
        hydro: 59.8,
        oil: 937.6,
        gas: 582,
        coal: 564.3,
        nuclear: 187.9,
        total: 10000
      }, {
        country: 'China',
        hydro: 74.2,
        oil: 308.6,
        gas: 35.1,
        coal: 956.9,
        nuclear: 11.3,
        total: 11000
      }, {
        country: 'Russia',
        hydro: 40,
        oil: 128.5,
        gas: 361.8,
        coal: 105,
        nuclear: 32.4,
        total: 13000
      }, {
        country: 'Japan',
        hydro: 22.6,
        oil: 241.5,
        gas: 64.9,
        coal: 120.8,
        nuclear: 64.8,
        total: 9000
      }, {
        country: 'India',
        hydro: 19,
        oil: 119.3,
        gas: 28.9,
        coal: 204.8,
        nuclear: 3.8,
        total: 11000
      }, {
        country: 'Germany',
        hydro: 6.1,
        oil: 123.6,
        gas: 77.3,
        coal: 85.7,
        nuclear: 37.8,
        total: 20000
      }]
    };
  },
  methods: {
    customizeTooltip: function customizeTooltip(pointInfo) {
      return {
        text: "".concat(pointInfo.seriesName, "<br/>").concat(pointInfo.value)
      };
    },
    customizePoint: function customizePoint(info) {}
  },
  mounted: function mounted() {
    var day = new Date();
    var WEEKDAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.today = WEEKDAY[day.getDay()];
    this.date = moment(day).format('YYYY.MM.DD');
  }
};