import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import router from '@/router';
import AppConfig from '@/config';
import { EventBus } from '@/modules/event-bus';
import DxSelectBox from 'devextreme-vue/select-box';
import localedata from '../locale/bos-locale';
import bosdictko from '../locale/bos-dict-ko';
import bosdicten from '../locale/bos-dict-en';
import appmgmtko from '../locale/appmgmt/appmgmt-dict-ko';
import appmgmten from '../locale/appmgmt/appmgmt-dict-en';
import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import SideMenu from '@/components/SideMenu';
export default {
  name: 'topMenu',
  components: {
    DxSelectBox: DxSelectBox,
    SideMenu: SideMenu
  },
  created: function created() {
    this.locale = localedata.getLocale(), this.localeName = localedata.getLocaleName(), this.initMessages();
    locale(this.locale);
  },
  data: function data() {
    return {
      topMenuTitle: '',
      menuTitle: '삭제 이력',
      locale: null,
      localeName: '',
      autoMenuInput: '',
      locales: localedata.getLocales(),
      selectBoxInputAttr: {
        id: 'selectInput'
      },
      menuList: null,
      autoMenuList: null,
      navigationBigList: [],
      pageIndex: 0,
      notice: false
    };
  },
  watch: {
    // noticeCount (val){
    //   if(val.noticeCount > 0){
    //     this.notice = true
    //   }else{
    //     this.notice = false
    //   }
    // },
    getMenuTitle: function getMenuTitle(val) {
      this.menuTitle = val;
    }
  },
  mounted: function mounted() {
    // var params = {};
    // params.reqmethod = 'GET';
    // params.requri = '/afliSite/menu/list/' + window.$store.getters['Login/getUserId'];
    // // 메뉴 목록
    // this.menuList = window.$store.getters['TopMenu/getMenuList'];
    // params = {};
    // params.requri = "/menu/list/autoMenu/" + window.$store.getters['Login/getUserId'];
    // params.reqmethod = "GET"
    // window.$store
    //   .dispatch('TopMenu/autoMenu', params)
    //   .then(() => {
    //     this.autoMenuList = window.$store.getters['TopMenu/getAutoMenuList'];
    //   })
    this.$nextTick(function () {
      // 네비게이션 스크롤 처리
      $(window).on('scroll', function (e) {
        var sc_postion = $(this).scrollTop();
        $('.location,.latest_menu').toggleClass('on', sc_postion > 136);
      }); // sideMemu event

      var $lnbClose = $('.lnb_wrap > .close'),
          $lnbMenu = $('.lnb_wrap .lnb_menu'),
          $cnt = $('.container .contents');
      $('.side').addClass('active');
      $('.lnb_wrap .close').addClass('active');
      $lnbMenu.addClass('active');
      $cnt.removeClass('active');
      $lnbClose.on('click', function () {
        $('.side').addClass('active');
        $('.lnb_wrap .close').addClass('active');
        $lnbMenu.addClass('active');
        $cnt.removeClass('active');
      });
      $lnbMenu.on('click', function () {
        $(this).addClass('active');
        $(this).removeClass('active');
        $('.side').removeClass('active');
        $('.lnb_wrap .close').removeClass('active');
        $cnt.addClass('active');
      });
      var $utilLi = $('.util > li:not(.search,.logout)');
      $utilLi.on('click', function () {
        $(this).find('ul').toggleClass('active');
        $(this).siblings('li').find('ul').removeClass('active');
      });
    });
  },
  beforeUpdate: function beforeUpdate() {
    this.$nextTick(function () {
      // this.menuTitle = window.$store.getters['TopMenu/getLowMenuName']
      // console.log(this.menuTitle)
      this.topMenuTitle = window.$store.getters['TopMenu/getRootMenuName'];
    });
  },
  computed: {
    // noticeCount () {
    //   return window.$store.getters['TopMenu/getNoticeCount']
    // },
    getMenuTitle: function getMenuTitle() {
      //window.$Alert.confirm(this.$route.name)
      //return window.$store.getters['TopMenu/getLowMenuName']
      return this.$route.meta.desc;
    },
    cachedComponentList: function cachedComponentList() {
      return window.$store.getters['LatestMenu/getCachedComponentList'];
    },
    filterList: function filterList() {
      var str = this.autoMenuInput;
      var reg = /[^가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9|\s]/.test(str);
      var count = 0;
      var nullCount = 0;

      if (reg === false && str !== '' && str !== ' ') {
        return this.autoMenuList.filter(function (el) {
          count++;

          if (el.menuNm.match(str) == null) {
            nullCount++;
          }

          var matchCount = count - nullCount;

          if (matchCount > 0) {
            $('.sh_result').addClass('active');
            $('.header_sh_input').parent().siblings('li').find('ul').removeClass('active');
          } else {
            $('.sh_result').removeClass('active');
          }

          return el.menuNm.match(str);
        });
      } else {
        $('.sh_result').removeClass('active');
      }
    },
    // 사용자 이름
    userNm: function userNm() {
      return window.$store.getters['Login/getUserNm'];
    },
    // login id
    userId: function userId() {
      return window.$store.getters['Login/getUserId'];
    },
    // navigation
    navigation: function navigation() {
      return window.$store.getters['TopMenu/getNavigation'];
    },
    middleMenuList: function middleMenuList() {
      return window.$store.getters['TopMenu/getMiddleMenuList'];
    },
    navigationMiddleList: function navigationMiddleList() {
      var middleList = window.$store.getters['TopMenu/getNavigationMiddleList'];
      return middleList;
    },
    navigationSmallList: function navigationSmallList() {
      return window.$store.getters['TopMenu/getNavigationSmallList'];
    },
    // 최근 메뉴
    latestMenuList: function latestMenuList() {
      return window.$store.getters['LatestMenu/getLatestMenuList'];
    },
    // 현재 페이지 tab index
    current: function current() {
      return window.$store.getters['LatestMenu/getCurrent'];
    }
  },
  methods: {
    complete: function complete(e) {
      if (e.startVue == 'manage') {
        EventBus.$emit('event-complete');
      }
    },
    logout: function logout() {
      window.$store.dispatch('Login/logout').then(function () {
        router.push('/');
      });
    },
    moveLatestMenu: function moveLatestMenu(type) {
      window.$store.dispatch('LatestMenu/moveLatestMenu', {
        type: type
      });
    },
    selectTab: function selectTab(latestMenu) {
      $('.side').addClass('active');
      $('.lnb_wrap .close').addClass('active');
      $('.lnb_wrap .lnb_menu').addClass('active');
      $('.container .contents').removeClass('active');
      this.menuList.forEach(function (menu) {
        $('#topMenu_' + menu.menuId).removeClass('active');
      }); // this.$refs.SideMenu.open([{}])

      this.autoMenuInput = '';
      this.menuTitle = window.$store.commit('TopMenu/setLowMenuName', {
        lowMenuName: latestMenu.desc
      });
      this.menuTitle = latestMenu.desc;
      this.$router.push({
        name: latestMenu.name
      });
    },
    deleteTab: function deleteTab(goToName) {
      window.$store.dispatch('LatestMenu/deleteLatestMenu', {
        name: goToName
      });
    },
    activeNavi: function activeNavi(e) {
      $('#' + e.target.id).toggleClass('active').siblings().removeClass('active');
    },
    viewSubMenu: function viewSubMenu(e) {
      $('#' + e.target.id).parent().find('.depth').hide();
      $('#' + e.target.id).find('.depth').show();
    },
    hideSubMenu: function hideSubMenu() {
      $('.gnb_menu >li > div').hide();
    },
    openSitemap: function openSitemap() {
      $('#div_sitemap').fadeIn();
    },
    closeSitemap: function closeSitemap() {
      $('#div_sitemap').fadeOut();
    },
    openPage: function openPage() {
      window.open('#' + this.$router.history.current.fullPath, '_blank');
    },
    goMenu: function goMenu(menuUrlAddr) {
      this.$router.push({
        path: menuUrlAddr
      });
    },
    open: function open(val) {
      var _this = this;

      $('.lnb_wrap .lnb_menu').addClass('active');
      $('.lnb_wrap .lnb_menu').removeClass('active');
      $('.side').removeClass('active');
      $('.lnb_wrap .close').removeClass('active');
      $('.container .contents').addClass('active');
      this.menuList.forEach(function (menu) {
        $('#topMenu_' + menu.menuId).removeClass('active');
      });
      $('#topMenu_' + val.menuId).addClass('active');
      window.$store.commit('TopMenu/setRootMenuName', {
        rootMenuName: val.menuNm
      });
      window.$store.commit('TopMenu/setMiddleMenuList', {
        middleMenuList: val.menuList
      });
      this.topMenuTitle = val.menuNm; // this.$refs.SideMenu.open(val.menuList)

      var menu = val.menuList[0];

      if (menu.menuUrlAddr != null && menu.menuUrlAddr != '') {
        this.menuTitle = window.$store.commit('TopMenu/setLowMenuName', {
          lowMenuName: menu.menuNm
        });
        this.menuTitle = menu.menuNm;
        this.$router.push({
          path: menu.menuUrlAddr
        });
        return;
      }

      var address = '';
      menu.items.forEach(function (item) {
        if (item.menuUrlAddr != null && item.menuUrlAddr != '') {
          if (address == '') {
            address = item.menuUrlAddr;
            _this.menuTitle = window.$store.commit('TopMenu/setLowMenuName', {
              lowMenuName: item.menuNm
            });
            _this.menuTitle = item.menuNm;
          }
        }
      });
      this.$router.push({
        path: address
      });
    },
    selectionLowMenu: function selectionLowMenu(e) {
      window.$store.dispatch('TopMenu/changeActive', window.$store.getters['TopMenu/getChangeActive'] + 1);
      this.menuTitle = window.$store.commit('TopMenu/setLowMenuName', {
        lowMenuName: e
      });
      this.menuTitle = e;
    },
    sideMemuMount: function sideMemuMount() {
      window.$store.dispatch('TopMenu/changeActive', window.$store.getters['TopMenu/getChangeActive'] + 1); // this.$refs.SideMenu.open(window.$store.getters['TopMenu/getMiddleMenuList'])
    },
    closeSideMemu: function closeSideMemu() {
      window.$store.dispatch('TopMenu/changeActive', window.$store.getters['TopMenu/getChangeActive'] + 1);
    },
    menuInput: function menuInput(e) {
      this.autoMenuInput = e.target.value;
    },
    initMessages: function initMessages() {
      loadMessages(bosdictko.getDictionary());
      loadMessages(bosdicten.getDictionary());
      loadMessages(appmgmtko.getDictionary());
      loadMessages(appmgmten.getDictionary());
    },
    changePwd: function changePwd() {
      this.$modal.show('changePasswordPopup', {
        userId: this.userId
      });
    },
    changeUserInfo: function changeUserInfo() {
      this.$modal.show('userManagePopup', {
        isUpdate: true,
        userId: this.userId,
        startVue: 'manage',
        isSelf: true
      });
    },
    changeLocale: function changeLocale(e) {
      var params = {
        locale: e.Value
      };
      window.$http.get('/api/appmgmt/common/changeLocale', {
        params: params
      }).then(function (response) {
        if (response.data.status == AppConfig.HTTP_RESPONSE_SUCCESS) {}
      });
      localedata.setLocale(e.Value);
      localedata.setLocaleName(e.Label);
      document.location.reload();
    },
    appAndDown: function appAndDown() {
      var linkUrl = 'https://down.qcheck.kr/mintiteraser/eraser.apk';
      window.open(linkUrl);
    },
    appIosDown: function appIosDown() {
      var linkUrl = 'https://down.qcheck.kr/mintiteraser/eraser.mobileconfig';
      window.open(linkUrl);
    },
    formatMessage: formatMessage
  }
};