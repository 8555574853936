/**
 * Create : 2022-01-06 yousang
 * Comment
 * AUIGrid 검색 버튼 Renderer 추가
 * 기존 labelFunction에 html 하드코딩 후 넣었을 경우
 * click 핸들러로 전역함수를 매핑하였는데 이 부분에서 이슈 발생
 * => 이슈내용
 * => 컴포넌트 초기화시 컴포넌트 메소드를 window 전역함수에 등록
 * => click 핸들러로 해당 전역함수를 호출하는 방식...
 * => 화면 컴포넌트가 캐시되어있기 때문에 화면 전환시 전역함수가 꼬임
 * ==> 전역함수 안쓰고 columnData에 click 핸들러를 매핑하는 방식으로 변경
 * searchButtonClick (rowIndex, columnIndex) {
 *    handling...
 * }
 */

/* eslint-disable */

/*
 * AUIGrid 사용자 정의 렌더러
 */
AUIGrid.SearchButtonRenderer = AUIGrid.Class({
  /****************************************************************
   * 
   * Overriden Properties
   * 
   ****************************************************************/

  /* 생성될 HTML Tag 명 */
  tagName: "div",

  /* CSS 스타일 클래스 */
  cssClass: "aui-grid-renderer-base aui-grid-search-button-renderer",

  /* 행 아이템 */
  data: null,

  /* 렌더러의 칼럼 레이아웃 데이터 */
  columnData: null,

  /* 칼럼 인덱스 */
  columnIndex: -1,

  /* 행 인덱스 */
  rowIndex: -1,

  /* 헤더 텍스트 */
  headerText: "",

  /* 데이터 필드명 */
  dataField: "",

  /* 초기화 여부 */
  initialized: false,

  /****************************************************************
   * 
   * Private Properties
   * 
   * Rule : Private Properties 는 반드시 __ 로 시작하십시오
   * 즉, 사용자가 추가하는 속성, 메소드는 __ 로 시작하십시오.
   * 
   ****************************************************************/

  /* 사용자가 작성할 엘리먼트*/
  __childBox: null,
  __childDisplay: null,
  __childBtn: null,
  __childIcon: null,

  /****************************************************************
   * 
   * Overriden Methods
   * 
   ****************************************************************/

  /*
   * @Overriden public update
   * 
   * 그리드에 의해 호출되는 메소드이며 빈번히 호출됩니다.
   * 이 메소드에서 DOM 검색이나, jQuery 객체 생성 등은 자제하십시오.
   * DOM 검색이나 jQuery 객체는 initialize() 메소드에서 하십시오.
   */
  update: function update() {
    var data = this.data; // 행 전체 아이템

    if (!data) return;

    if (!this.initialized) {
      this.initialize();
    } else {
      this.__renderDisabled();
    } // 실제 element 에 값 출력


    this.__displayMyValues();
  },

  /*
   * @Overriden public destroy
   * 
   * 여기서 해제할 것 모두 해제 하십시오.
   * 메모리 누수를 유발하는 코드들을 모두 해제 하십시오.
   */
  destroy: function destroy(unload) {
    if (this.__childBtn) this.__childBtn.removeEventListener("click");
    this.__childBox = null;
    this.__childDisplay = null;
    this.__childBtn = null;
    this.__childIcon = null; // 필수 : 반드시 아래 코드는 추가 해야 합니다.

    this.$super.destroy(unload);
  },

  /*
   * @Overriden public initialize
   * 
   * 최초 1번만 실행되므로 설정할 사항이 있으면 여기서 설정하십시오.
   */
  initialize: function initialize() {
    if (this.initialized) return;
    var self = this;
    this.initialized = true; // 렌더러 자체 HTML Element(Div)

    var element = this.element;
    var box = this.__childBox = document.createElement("div");
    box.className = "grid_search_box";
    var display = this.__childDisplay = document.createElement("div");

    this.__setStyle(display, "height", "16px");

    var btn = this.__childBtn = document.createElement("button");
    btn.className = "sbtn template";
    btn.addEventListener("click", function (e) {
      var fn = self.columnData.searchButtonClick;
      if (fn && typeof fn === "function") fn(self.rowIndex, self.columnIndex);
    });
    var icon = this.__childIcon = document.createElement("i");
    icon.className = "xi-search xi-x";
    btn.appendChild(icon);
    box.appendChild(display);
    box.appendChild(btn);
    element.appendChild(box); // IE 메모리 누수 방지

    box = display = btn = icon = null;
  },

  /****************************************************************
   * 
   * Private Methods
   * 
   * Rule : Private Methods 는 반드시 __ 로 시작하십시오
   * 즉, 사용자가 추가하는 속성, 메소드는 __ 로 시작하십시오.
   * 
   ****************************************************************/

  /* disabled 처리 함수 */
  __renderDisabled: function __renderDisabled() {
    var data = this.data;
    var cObj = this.columnData.disabledCondition;
    if (!cObj) return;
    var dataField = cObj.dataField;
    var condition = cObj.condition;
    var value = cObj.value;
    if (!dataField) return;

    switch (condition) {
      case "neq":
        {
          if (data[dataField] !== value) {
            this.__childBtn.disabled = true;
          } else {
            this.__childBtn.disabled = false;
          }

          break;
        }

      case "eq":
        {
          if (data[dataField] === value) {
            this.__childBtn.disabled = true;
          } else {
            this.__childBtn.disabled = false;
          }

          break;
        }
    }
  },

  /* 값을 실제로 element 에 출력함 */
  __displayMyValues: function __displayMyValues() {
    var el;
    var data = this.data; // 행 전체 아이템

    var value = data[this.dataField] || ''; // 현재 칼럼 아이템

    el = this.__childDisplay;
    el.textContent != null ? el.textContent = value : el.innerText = value;
    el = null;
  },

  /* element (엘리먼트) 에 styles 을 설정합니다. */
  __setStyle: function __setStyle(element, name, value) {
    element.style[name] = value;
  }
}).extend(AUIGrid.RendererBase);