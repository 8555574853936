var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { wrap: !_vm.$route.meta.isPopup },
      attrs: { id: "wrapper" },
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("vue-headful", { attrs: { title: _vm.title } }),
      _vm.$route.meta.authRequired ? _c("topMenu") : _vm._e(),
      _c(
        "keep-alive",
        { attrs: { max: "0" } },
        [
          !_vm.$route.meta.authRequired
            ? _c("router-view", { key: _vm.$route.fullPath })
            : _vm._e(),
        ],
        1
      ),
      _vm.$route.meta.authRequired ? _c("footerView") : _vm._e(),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "loading_wrap", attrs: { id: "loading" } },
      [_c("div", { staticClass: "circle" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }