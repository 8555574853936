'use strict';

import axios from 'axios';
var defaultOptions = {
  timeout: 30000,
  crossDomain: true,
  withCredentials: false,
  debug: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: -1
  }
};

var HttpExecutorFactory = function HttpExecutorFactory() {
  return {
    create: function create(options) {
      var mergedOptions = _.merge(defaultOptions, options);

      var http = axios.create(mergedOptions);
      http.defaults.timeout = 290000; // 

      if (mergedOptions.debug === true) {
        http.interceptors.request.use(function (config) {
          // console.log(
          //   `[HTTP] Request was called...
          //   - url : %s,`,
          //   config.url
          // )
          return config;
        });
        http.interceptors.response.use(function (response) {
          // console.log(response.data)
          // console.log(
          //   `[HTTP] Response was recevied...
          //    - status : %s,
          //    - data: %o,
          //    - uri: %s`,
          //   response.status,
          //   response.data,
          //   response.request.responseURL
          // )
          return response;
        });
      }

      return http;
    }
  };
};

export default HttpExecutorFactory();