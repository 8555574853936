var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !!_vm.totalRowCount,
          expression: "!!totalRowCount",
        },
      ],
      staticClass: "pagination",
    },
    [
      _c(
        "div",
        _vm._l(_vm.rowCounts, function (item) {
          return _c(
            "a",
            {
              key: item,
              class: { active: _vm.rowCount === item },
              attrs: { href: "javascript:void(0);", role: "button" },
              on: {
                click: function ($event) {
                  return _vm.handleClick(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        0
      ),
      _c(
        "div",
        [
          _c("span", { staticClass: "pagination-text" }, [
            _vm._v(
              "Page " +
                _vm._s(_vm.currentPage) +
                " of " +
                _vm._s(_vm.totalPage) +
                " (" +
                _vm._s(_vm.totalRowCount) +
                " items)"
            ),
          ]),
          _c(
            "a",
            {
              staticClass: "radius-first-child",
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.moveToPage(1, false)
                },
              },
            },
            [_vm._v("❮❮")]
          ),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.moveToPage(_vm.prevPage, false)
                },
              },
            },
            [_vm._v("❮")]
          ),
          _vm._l(_vm.pagingItems, function (item, index) {
            return [
              _vm.currentPage === item
                ? _c(
                    "a",
                    {
                      key: index,
                      staticClass: "active",
                      attrs: { href: "javascript:void(0);" },
                    },
                    [_vm._v(_vm._s(item))]
                  )
                : _c(
                    "a",
                    {
                      key: index,
                      attrs: { href: "javascript:void(0);" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.moveToPage(item, false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item))]
                  ),
            ]
          }),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.moveToPage(_vm.nextPage, false)
                },
              },
            },
            [_vm._v("❯")]
          ),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0);" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.moveToPage(_vm.totalPage, false)
                },
              },
            },
            [_vm._v("❯❯")]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }