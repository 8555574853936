import _toConsumableArray from "C:/del-cert/del-cert/frontend/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import DxSelectBox from 'devextreme-vue/select-box';
export default {
  components: {
    DxSelectBox: DxSelectBox
  },
  name: 'DxSelectCodeList',
  props: {
    id: {
      type: String,
      default: 'selectCode'
    },
    allYn: {
      type: String,
      default: 'Y'
    },
    callVue: {
      type: String,
      default: 'manage'
    },
    inputClass: '',
    value: '',
    cmnsCdGrpId: '',
    disabled: false,
    readOnly: false,
    useCode: {
      type: String,
      default: ''
    },
    ignoreList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    refCode: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      url: '/api/appmgmt/common/code/list',
      codeList: [],
      originList: []
    };
  },
  computed: {
    selected: {
      get: function get() {
        return this.value;
      },
      set: function set() {}
    }
  },
  watch: {
    'ignoreList': 'setCodeList',
    'refCode': 'setCodeList',
    value: function value(v) {
      // 값 초기화시 '전체' 코드가 없을 경우 codeList의 첫번째 값으로 변경
      if (v === '') this.checkCurrentValue();
    }
  },
  mounted: function mounted() {
    var _this = this;

    var params = {
      cmnsCdGrpId: this.cmnsCdGrpId
    };

    if (this.callVue === 'login' || this.callVue === 'info' || this.callVue === 'userMange') {
      this.url = '/api/appmgmt/public/common/code/list';
    }

    Vue.httpClient.get(this.url, {
      params: params,
      data: {
        isLoadingHide: 'Y'
      }
    }).then(function (result) {
      _this.originList = result.data.result;

      _this.setCodeList();
    });
  },
  methods: {
    onChange: function onChange(e) {
      this.$emit('input', e.value);
      this.$emit('select-code-complete', e.value);
    },
    setCodeList: function setCodeList() {
      var ignoredList = this.getIgnoredList();
      var refList = this.getRefList(ignoredList);

      if (this.allYn !== 'N') {
        this.codeList = [{
          cmnsCd: '',
          cmnsCdNm: '전체'
        }].concat(_toConsumableArray(refList));
      } else {
        this.codeList = refList;
      }

      this.checkCurrentValue();
    },
    getRefList: function getRefList(list) {
      var _this2 = this;

      if (this.refCode) {
        return list.filter(function (data) {
          if (data.cmnsCd.charAt(0) === _this2.refCode.charAt(0)) return true; // if (data.rfer2GrpId === this.refCode) return true
        });
      } else {
        return list;
      }
    },
    getIgnoredList: function getIgnoredList() {
      var _this3 = this;

      if (this.ignoreList.length) {
        return this.originList.filter(function (originData) {
          var isIgnore = !!_this3.ignoreList.find(function (ignoreData) {
            return ignoreData === originData.cmnsCd;
          });
          if (!isIgnore) return true;
        });
      } else {
        return this.originList;
      }
    },
    checkCurrentValue: function checkCurrentValue() {
      var _this4 = this;

      // 현재값이 codeList에 없을 경우 첫번째 값으로 변경
      var selectedCode = this.codeList.find(function (item) {
        return item.cmnsCd === _this4.value;
      });

      if (!selectedCode && this.codeList[0]) {
        this.onChange({
          value: this.codeList[0].cmnsCd
        });
      }
    }
  }
};